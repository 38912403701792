import {
  Box,
  Flex,
  Button,
  Stack,
  Collapse,
  useColorModeValue,
  useBreakpointValue,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  HStack,
  Image,
  Avatar,
  AvatarBadge,
  Badge,
} from '@chakra-ui/react';
import { ChevronDownIcon, SearchIcon, BellIcon } from '@chakra-ui/icons';
import { FaGlobe, FaBars } from 'react-icons/fa';
import { useState, useEffect } from 'react';
import MarcomLogo from '../image/NewMarcomLogo.png';
import MarcomLogo1 from '../image/MarcomFull.png';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Notifications from '../Notifications/Notifications';
import Style from './Header.module.css';
import { IoMdNotificationsOutline } from 'react-icons/io';
import { LiaLanguageSolid } from 'react-icons/lia';

export default function Header() {
  const { isOpen, onToggle } = useDisclosure();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState({});
  const [selectedLanguage, setSelectedLanguage] = useState('English');
  const [notificationCount, setNotificationCount] = useState(1);
  const colorModeValue = useColorModeValue('gray.800', 'gray');
  const location = useLocation();
  const navigate = useNavigate();

  const [showNotifications, setShowNotifications] = useState(false);

  const handleNotificationsClick = () => {
    setShowNotifications(!showNotifications);
  };

  const handleViewAllClick = () => {
    setShowNotifications(false);
    navigate('/notifications');
  };
  const [userName, setUserName] = useState('');
  useEffect(() => {
    const handleUserDataChange = () => {
      const userData = JSON.parse(localStorage.getItem('userData'));
      if (userData) {
        const fullName = `${userData.first_name} ${userData.last_name}`;
        setUserName(fullName);
      }
    };

    // Initial set
    handleUserDataChange();

    // Listen for custom 'userDataChanged' event
    window.addEventListener('userDataChanged', handleUserDataChange);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('userDataChanged', handleUserDataChange);
    };
  }, []);
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('userData'));

    const userToken = localStorage.getItem('userToken');
    if (userToken) {
      setIsAuthenticated(true);
      setUser(userData);
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('userToken');
    setIsAuthenticated(false);
    navigate('/signin');
  };

  const languages = [
    { name: 'English', flag: 'https://flagcdn.com/w20/us.png' },
    { name: 'Hindi', flag: 'https://flagcdn.com/w20/in.png' },
    { name: 'Spanish', flag: 'https://flagcdn.com/w20/es.png' },
    { name: 'French', flag: 'https://flagcdn.com/w20/fr.png' },
  ];

  const handleLanguageSelect = (language) => {
    setSelectedLanguage(language);
  };

  const isDashboard = location.pathname !== '/';

  return (
    <Box position="sticky" top="0px" zIndex={'1'}>
      <Flex
        bg={useColorModeValue('white', 'gray.800')}
        color={useColorModeValue('gray.600', 'white')}
        minH={'50px'}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.200', 'gray.900')}
        align={'center'}
        // justify={"space-between"}
      >
        <Link to={'/'}>
          <Box
            as="a"
            display="inline-block"
            // transition="transform 0.3s ease-in-out"
            // _hover={{ transform: "scale(1.1)", filter: "brightness(1.2)" }}
          >
            <Flex>
              <Box
                as="img"
                src={MarcomLogo}
                alt="Marcom Logo"
                // boxSize={useBreakpointValue({ base: "50px", md: "90px" })}
                width={'150px'}
                height={'80px'}
                mb={'5px'}
              />

              {/* <Box
              as="img"
              src={MarcomLogo1}
              alt="Marcom Logo"
              boxSize={useBreakpointValue({
                base: "50px",
                sm: "70px",
                md: "90px",
              })}
              objectFit="contain"
              display="block"
              mx="auto"
            /> */}
            </Flex>
          </Box>
        </Link>
        {isDashboard && isAuthenticated && (
          <Box zIndex="dropdown" ml={'100px'}>
            <InputGroup size="lg" w={'400px'}>
              <Input placeholder="Search..." />
              <InputRightElement>
                <IconButton
                  aria-label="Search"
                  icon={<SearchIcon />}
                  variant="ghost"
                />
              </InputRightElement>
            </InputGroup>
          </Box>
        )}
        <Flex
          width={'90%'}
          display={{ base: 'none', md: 'flex' }}
          align="center"
          justifyContent={'end'}
        >
          {isDashboard && isAuthenticated && (
            <Stack direction={'row'} spacing={6}>
              {/* <Box zIndex="dropdown">
                <InputGroup size="lg">
                  <Input placeholder="Search..." />
                  <InputRightElement>
                    <IconButton
                      aria-label="Search"
                      icon={<SearchIcon />}
                      variant="ghost"
                    />
                  </InputRightElement>
                </InputGroup>
              </Box> */}

              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="Notifications"
                  icon={
                    <Box position="relative">
                      <IoMdNotificationsOutline size={22} color="#F52F2F" />
                      {notificationCount > 0 && (
                        <Badge
                          position="absolute"
                          top="-2.5"
                          right="-1"
                          fontSize="xs"
                          colorScheme="red"
                          borderRadius="full"
                          px={1}
                          py={0}
                        >
                          {notificationCount}
                        </Badge>
                      )}
                    </Box>
                  }
                  variant="ghost"
                  onClick={handleNotificationsClick}
                />
                {showNotifications && (
                  <MenuList>
                    <Notifications handleViewAllClick={handleViewAllClick} />
                  </MenuList>
                )}
              </Menu>

              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="Language"
                  icon={<LiaLanguageSolid size={22} color="#F52F2F" />}
                  variant="ghost"
                />
                <MenuList>
                  {languages.map((language) => (
                    <MenuItem
                      key={language.name}
                      onClick={() => handleLanguageSelect(language.name)}
                    >
                      <HStack>
                        <Image
                          src={language.flag}
                          alt={language.name}
                          boxSize="20px"
                        />
                        <Text className={Style?.textBottomMargin}>
                          {language.name}
                        </Text>
                      </HStack>
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>

              {/* <Text display="flex" alignItems="center">
                {selectedLanguage}
              </Text> */}

              <Menu>
                <MenuButton
                  mr={'2px'}
                  as={IconButton}
                  aria-label="Profile"
                  bg={'white'}
                  _hover={{ bg: 'white' }}
                  icon={
                    <Box
                      display={'flex'}
                      justifyContent={'center'}
                      alignItems={'center'}
                      bg={'white'}
                      w={'auto'}
                      h={'auto'}
                      gap={2}
                    >
                      <Avatar
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAALIAAACUCAMAAAAAoYNxAAAAMFBMVEXk5ueutLeqsLTN0dPn6erV2Nra3d6zuLvq7O3IzM7h4+Te4OK4vcDBxci9wsTFycsk986QAAAECklEQVR4nO2c25qjIAyAUcJBQH3/tx2YOtNOaxUIJbjLf7V790++yCEkZazT6XQ6nU6n0+l0Op3/AdCaSeGRlmkN1DrnGLGq8Y6bBLXREQBWLF5zeMT/d+YWmgw2gAzxHV4ZB7XKBp3BLDu2d2bWmDSwdS++fxKENyUNQp0YB2kn2nGG9dT3xqqpVTfAnYd4C/RC7XrDqkjh7+QwDSSHTDBuw9kkGQdn8m0l0djjiI1dsnHYVQiBKcN4GDlhasgcY+9s6JRj1+MXqMKs52zlicYZRLbxoIgOo8enzUPGiUIYRL6xd7YUzkt+XnjlmSAzJMbYO9c3hrNryJly/UUDcMZ+0aiujFjhNmpfq/SMNa5/q0o/dD6zVD5poBblG5V3QOB45crJHF0GOGDkNY3ZSTUrTrnu7cTmXKCeqXoJhLRKwBtUVeUCC4an5ipXSLnmAbSM8ii7clf+R5Sv9/nVXeSut5VccMO+4rEI8Peo2ofPvMLyE5WP+Ne7SF3xuqrxS0btqhymuLxR/UUbXeAaKgujXh2+GQme4LHFWoJXKYMqiQ8LQUkc+fBQ86x8J7oNY8d4JnmtvOIjms7P5pXsFTtbmUo4+/WB5tFvI69mS/SCfcPknI6I27hMurGiTIuATTam76NMrA9QrciPQFK3jrP0xizkc3QDZQONfTeiSwRzK22q4RkwphlYUTabvQD29Lwxrm2k8R2Qx2fRRr67P4AW7s16Nw5LQw3ijwCTkxuehx68L5fk3bRvAWYkn4eHURjlfQ39fncCaDBScM6FNHCFgSMGAX0j/JPa5wgIIbVS+ABPGz7SYbgLgjy13hNh+MzyyTm1s2YopdzMbTtJ4r+4n0/ucCMJU2mrsOSfYpg9m5b9Ya5d78GtXNJZg5bT4qJPcb/ayq2SJEeA8dxCvv8jVfUDB5gwi5hp/BNsbutlCJjJ4YRv1mFwsYq0NqtKTeC30ouscOg3yOr9i/WHx3HBTgUy4kl6EZ/LaWBTxH0pg/lTy4eWJdoD9vlIlQ4Yclk7ZBzKV2SiBmpR0lPpEkeBTtozZ1cy0MA+HOINUWyRLtT8dM64ljIu0REe6bwUSWhdosEl2rnEMC6U3aBPKVB/Rs4V1XfGTkJlOaPyWU/1jXHTU8ApjDFtitXW4xey+xRTfpKhMJn1fsC1taDIa+Oh+fR+yQhz2nNeeXIO/QXafTGk7yhU69sv6T8LYxC9ToWcE1+EyIM8JG+Cmtp3SA1zC0FODDPQ7XuPJISZ7nDxl4RuuhLzDCVw8V1IRQZdChD/0zut5EVKkaCJ9SLgYg90haveCKLPoKm/BPY5xtgSv20lyPE/VYIcDCjJEldYbGO33ohT1uvYDpFRDh0rrRBlzLbGlTbYsfsCcLs58obaI50AAAAASUVORK5CYII="
                        width={'40px'}
                        height={'40px'}
                        name="Abhinav Dubey"
                      >
                        <AvatarBadge boxSize="0.90em" bg="green.500" />
                      </Avatar>
                      <Text m={0}>{userName}</Text>
                    </Box>
                  }
                  variant="ghost"
                />

                <MenuList minW={150} minH={100}>
                  <Link to="/Profile">
                    <MenuItem>Profile</MenuItem>
                  </Link>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </MenuList>
              </Menu>
            </Stack>
          )}
          {!isAuthenticated && (
            <>
              <Link to="/signin">
                <Button
                  fontSize="sm"
                  mr={2}
                  fontWeight={600}
                  color={'white'}
                  bg={'red.500'}
                  _hover={{ bg: 'red.400' }}
                >
                  Sign In
                </Button>
              </Link>
              <Link to="/signup">
                <Button
                  fontSize="sm"
                  fontWeight={600}
                  color={'white'}
                  bg={'red.500'}
                  _hover={{ bg: 'red.400' }}
                >
                  Sign Up
                </Button>
              </Link>
            </>
          )}

          {location.pathname === '/' && isAuthenticated && (
            <Link to="/Dashboard">
              <Button
                fontSize="sm"
                ml={2}
                fontWeight={600}
                color={'white'}
                bg={'red.500'}
                _hover={{ bg: 'red.400' }}
              >
                Dashboard
              </Button>
            </Link>
          )}
        </Flex>

        <Flex display={{ base: 'flex', md: 'none' }} align="center">
          <IconButton
            aria-label="Open Menu"
            icon={<FaBars />}
            onClick={onToggle}
            variant="ghost"
            mr={2}
          />
        </Flex>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box>
  );
}

const MobileNav = () => {
  return (
    <Stack
      bg={useColorModeValue('white', 'gray.800')}
      p={4}
      display={{ md: 'none' }}
    >
      <MobileNavItem label="Products" children={[]} />
      <MobileNavItem label="Company" children={[]} />
      <MobileNavItem label="Customers" />
    </Stack>
  );
};

const MobileNavItem = ({ label }) => {
  return (
    <Stack spacing={4}>
      <Flex
        py={2}
        as={Link}
        href="#"
        justify={'space-between'}
        align={'center'}
        _hover={{
          textDecoration: 'none',
        }}
      >
        <Text
          fontWeight={600}
          color={useColorModeValue('gray.600', 'gray.200')}
        >
          {label}
        </Text>
      </Flex>
    </Stack>
  );
};
