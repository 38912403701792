import React, { useEffect, useState } from "react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
} from "@chakra-ui/react";
import {
  Box,
  Text,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Grid,
  Th,
  Td,
  TableContainer,
  Checkbox,
  useBreakpointValue,
  useDisclosure,
  Icon,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  IconButton,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  InputRightElement,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useToast,
  InputGroup,
  Flex,
} from "@chakra-ui/react";
import { CiUser } from "react-icons/ci";
import { Avatar, AvatarBadge, AvatarGroup } from "@chakra-ui/react";
import { DeleteIcon, EditIcon, HamburgerIcon } from "@chakra-ui/icons";
import { Link, useParams } from "react-router-dom";
import { RiExportLine, RiImportFill } from "react-icons/ri";
import { CiLink } from "react-icons/ci";
import { TbBrandCampaignmonitor } from "react-icons/tb";
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";
import { CiMail } from "react-icons/ci";
import Footer from "../Footer/Footer";
import { FiFilter } from "react-icons/fi";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";

// import { Avatar, AvatarBadge, AvatarGroup } from "@chakra-ui/react";
import {} from "@chakra-ui/react";
import { SearchIcon, CloseIcon } from "@chakra-ui/icons";
import { GoScreenFull } from "react-icons/go";
import { FaPlus } from "react-icons/fa";
import { RiExportFill } from "react-icons/ri";
import { IoFilter } from "react-icons/io5";
import { GrGroup } from "react-icons/gr";
import { FaLink } from "react-icons/fa6";
import endpoint from '../../Services/endpoint'
import {CallAPI} from '../../Services/service'
import { MdOutlineCampaign } from "react-icons/md";
import { CgBox } from "react-icons/cg";
<IoFilter />;




export default function Lead() {
  const {id, type} = useParams()
  const [selectedItems, setSelectedItems] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isDesktop = useBreakpointValue({ base: false, md: true });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [leads, setLeads] = useState([]);
  const [showLinkForm, setShowLinkForm] = useState(false);
  const [currentLeadId, setCurrentLeadId] = useState(null);
  const [newLink, setNewLink] = useState("");
  const [campaignDetails, setCampaignDetails] = useState([])

  const [filters, setFilters] = useState({
    name: "",
    phone: "",
    email: "",
    date: "",
    priority: "",
  });
  const [showAddForm, setShowAddForm] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [newLead, setNewLead] = useState({
    name: "",
    phone: "",
    email: "",
    date: "",
    priority: "",
  });

  const toast = useToast();
  const [formErrors, setFormErrors] = useState({
    name: "",
    email: "",
  });
  const validateForm = () => {
    let isValid = true;
    const errors = {};

    if (!newLead.name) {
      errors.name = "Name is required";
      isValid = false;
    }

    if (!newLead.email) {
      errors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(newLead.email)) {
      errors.email = "Email is invalid";
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleCheckboxChange = (id) => {
    const updatedLeads = leads.map((lead) =>
      lead.id === id ? { ...lead, isSelected: !lead.isSelected } : lead
    );
    setLeads(updatedLeads);
    setSelectedItems(updatedLeads.filter((lead) => lead.isSelected).length);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const importedLeads = JSON.parse(e.target.result);
        console.log("Imported Leads:", importedLeads);

        toast({
          title: "Import Data",
          description: "Do you want to add imported data to the leads list?",
          status: "info",
          duration: 9000,
          isClosable: true,
          position: "top",
          onCloseComplete: () => {
            const updatedLeads = [...leads, ...importedLeads];
            setLeads(updatedLeads);
            toast({
              title: "Leads imported successfully.",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
          },
        });
      } catch (error) {
        console.error("Error parsing JSON file:", error);
        toast({
          title: "Error",
          description: "Failed to parse JSON file.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    };
    reader.readAsText(file);
  };
  const handleExportLeads = () => {
    const selectedLeads = leads.filter((lead) => lead.isSelected);

    if (selectedLeads.length === 0) {
      toast({
        title: "No leads selected for export.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    const csvContent =
      "data:text/csv;charset=utf-8," +
      selectedLeads.map((lead) => Object.values(lead).join(",")).join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "selected_leads.csv");
    document.body.appendChild(link);
    link.click();

    toast({
      title: "Leads exported successfully.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredLeads = leads.filter((lead) => {
    return (
      (!searchTerm ||
        lead.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        lead.email.toLowerCase().includes(searchTerm.toLowerCase())) &&
      (!filters.name ||
        lead.name.toLowerCase().includes(filters.name.toLowerCase())) &&
      (!filters.phone || lead.phone.includes(filters.phone)) &&
      (!filters.email ||
        lead.email.toLowerCase().includes(filters.email.toLowerCase())) &&
      (!filters.date || lead.date === filters.date) &&
      (!filters.priority || lead.priority === filters.priority)
    );
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredLeads.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleAddLead = () => {
    setNewLead({
      id: null,
      name: "",
      phone: "",
      email: "",
      date: "",
      priority: "",
    });
    setIsEditing(false);
    setShowAddForm(true);
  };
  const handleSubmit = () => {
    if (validateForm()) {
      if (isEditing) {
        const updatedLeads = leads.map((lead) =>
          lead.id === newLead.id ? newLead : lead
        );
        setLeads(updatedLeads);
        toast({
          title: "Lead updated successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        const id = leads.length + 1;
        const updatedLeads = [...leads, { ...newLead, id }];
        setLeads(updatedLeads);
        toast({
          title: "Lead added successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }

      setNewLead({
        id: null,
        name: "",
        phone: "",
        email: "",
        date: "",
        priority: "",
      });
      setShowAddForm(false);
    } else {
      toast({
        title: "Please fill out all required fields correctly.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleEditLead = (lead) => {
    setNewLead(lead);
    setIsEditing(true);
    setShowAddForm(true);
  };

  const handleDeleteLead = (id) => {
    const updatedLeads = leads.filter((lead) => lead.id !== id);
    setLeads(updatedLeads);
    setSelectedItems(0);
    toast({
      title: "Lead deleted successfully.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  const handleDeleteAllLeads = () => {
    onClose();
    if (
      window.confirm("Are you sure you want to delete all leads permanently?")
    ) {
      setLeads([]);
      setSelectedItems(0);
      toast({
        title: "All leads deleted successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleAddLink = (id) => {
    const generatedLink = `https://example.com/leads/${id}`;
    setNewLink(generatedLink);
    setCurrentLeadId(id);
    setShowLinkForm(true);
  };

  const handleLinkSubmit = () => {
    const updatedLeads = leads.map((lead) => {
      if (lead.id === currentLeadId) {
        return { ...lead, link: newLink };
      }
      return lead;
    });
    setLeads(updatedLeads);
    setNewLink("");
    setShowLinkForm(false);
    toast({
      title: "Link added successfully.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  const handleSelectAll = () => {
    const allSelected = selectedItems === leads.length;
    const updatedLeads = leads.map((lead) => ({
      ...lead,
      isSelected: !allSelected,
    }));
    setLeads(updatedLeads);
    setSelectedItems(allSelected ? 0 : updatedLeads.length);
  };

  const handleUnselectAll = () => {
    const updatedLeads = leads.map((lead) => ({ ...lead, isSelected: false }));
    setLeads(updatedLeads);
    setSelectedItems(0);
  };

  const today = new Date();
  const formattedDate = today.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  const [showCard, setShowCard] = useState(null);

  const toggleCard = (id) => {
    if (showCard === id) {
      setShowCard(null);
    } else {
      setShowCard(id);
    }
  };

  const FindCampaignDetails = (id,cptype)=>{
   try {
     CallAPI(endpoint.campaignDetails,{
       id:id,
       type:cptype
     }).then((res)=>{
      if(res?.code===200){
        setCampaignDetails(res?.data)
      }
     })
   } catch (error) {
      console.log("Error",error)
   }
  }

  const showAllLeads=()=>{
    try {
      CallAPI(endpoint.findLeads)
      .then((res)=>{
        if(res?.code===200){
          setLeads(res?.data)
          console.log(res?.data)
        }
      })
    } catch (error) {
      console.log("Error>>",error)
    }
  }

  useEffect(()=>{
    showAllLeads()
    FindCampaignDetails(id, type)
  },[id])

  
  

  return (
    <Flex direction="column">
      <Box mt={-5} display="flex" ml={3} alignItems="center">
        <Text
          fontWeight="400"
          fontSize="16px"
          lineHeight="24px"
          letterSpacing="-0.11px"
          color="#8F9090"
          mr="2"
        >
          Campaign
        </Text>
        <Text>></Text>
        <Text
          fontWeight="500"
          fontSize="16px"
          lineHeight="24px"
          letterSpacing="-0.11px"
          color="#202020"
        >
          Webinar campaign
        </Text>
      </Box>
      <Box
        w={"100%"}
        p={2}
        pb={1}
        pt={-3}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        borderRadius="10px"
      >
        <Text fontSize={"24px"} ml={1} color={"#000000"} fontWeight={"700"}>
          Campaign Info
        </Text>
      </Box>

      <Flex
        flex="1"
        borderRadius={"10px"}
        direction={{ base: "column", md: "row" }}
      >
        <Box width={{ base: "100%", md: "70%" }}>
          <Box
            w={"100%"}
            p={1}
            ml={3}
            mr={3}
            mb={3}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            border={"#DEDEDE 1px solid"}
            borderRadius="10px"
          >
            <Box display="flex" alignItems="center" gap={5}>
              {/* <CiMail /> */}
              <Box
                bg="#c8d6fa"
                color="black"
                ml={3}
                fontSize="lg"
                height={"60px"}
                width={"60px"}
                fontWeight="bold"
                p="2"
                borderRadius="25"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Icon
                  as={CiMail} // Your icon component
                  w={8} // width of the icon (adjust size as needed)
                  h={8} // height of the icon (adjust size as needed)
                  color="#7A9DF5" // color of the icon
                />
              </Box>

              <Box display="flex" flexDirection="column" mt={5}>
                
                <Text fontWeight="bold">
                  {campaignDetails?.campaign_name} : {campaignDetails?.campaign_objective}
                </Text>

                {/* { (campaignDetails?.type==="facebook") &&  */}
                  <Text
                  fontWeight="400"
                  fontSize="14px"
                  mt="-20px"
                  lineHeight="21px"
                  letterSpacing="-0.11px"
                  color="#202020"
                  >
                    {campaignDetails?.description} {campaignDetails?.body_text} {campaignDetails?.tweet_text} {campaignDetails?.message_text}
                  </Text> 
                {/* } */}
              </Box>
            </Box>
          </Box>

          <Box
            w={"100%"}
            alignItems={"center"}
            ml={3}
            mb={3}
            mr={3}
            border={"#DEDEDE 1px solid"}
            borderRadius="10px"
          >
            <Text
              fontWeight="500"
              ml={4}
              mt={2}
              fontSize="18px"
              lineHeight="18px"
              mb={4}
            >
              Email Details
            </Text>
           
            <Grid
              templateColumns={{ base: "repeat(2, 1fr)", md: "repeat(5, 1fr)" }} // 2 columns on mobile, 5 columns on larger screens
              gap={4}
              width="100%"
              pb={2}
            >
              <Box
                textAlign="center"
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Text
                  fontWeight="400"
                  fontSize="14px"
                  lineHeight="14px"
                  color="#8E8E8E"
                >
                  Email Delivered
                </Text>
                <Box
                  bg="red.100"
                  color="black"
                  fontSize="lg"
                  height="60px"
                  width="60px"
                  fontWeight="bold"
                  p="2"
                  borderRadius="25"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  mt="-1"
                >
                  99
                </Box>
              </Box>

              <Box
                textAlign="center"
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Text
                  fontWeight="400"
                  fontSize="14px"
                  lineHeight="14px"
                  color="#8E8E8E"
                >
                  Email Reply
                </Text>
                <Box
                  bg="red.100"
                  color="black"
                  fontSize="lg"
                  height="60px"
                  width="60px"
                  fontWeight="bold"
                  p="2"
                  borderRadius="25"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  mt="-1"
                >
                  85
                </Box>
              </Box>

              <Box
                textAlign="center"
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Text
                  fontWeight="400"
                  fontSize="14px"
                  lineHeight="14px"
                  color="#8E8E8E"
                >
                  Email Opened
                </Text>
                <Box
                  bg="red.100"
                  color="black"
                  fontSize="lg"
                  height="60px"
                  width="60px"
                  fontWeight="bold"
                  p="2"
                  borderRadius="25"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  mt="-1"
                >
                  30
                </Box>
              </Box>

              <Box
                textAlign="center"
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Text
                  fontWeight="400"
                  fontSize="14px"
                  lineHeight="14px"
                  color="#8E8E8E"
                >
                  Email Bounced
                </Text>
                <Box
                  bg="red.100"
                  color="black"
                  fontSize="lg"
                  height="60px"
                  width="60px"
                  fontWeight="bold"
                  p="2"
                  borderRadius="25"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  mt="-1"
                >
                  10
                </Box>
              </Box>

              <Box
                textAlign="center"
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Text
                  fontWeight="400"
                  fontSize="14px"
                  marginLeft={{ base: "1", md: "0" }}
                  lineHeight="14px"
                  color="#8E8E8E"
                >
                  Email Spam Blocked
                </Text>
                <Box
                  bg="red.100"
                  color="black"
                  fontSize="lg"
                  height="60px"
                  width="60px"
                  fontWeight="bold"
                  p="2"
                  borderRadius="25"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  mt="-1"
                >
                  45
                </Box>
              </Box>
            </Grid>
          </Box>

          <Box
            ml={3}
            mr={3}
            mb={3}
            border="1px solid #ccc"
            borderRadius={"10px"}
            width={"100%"}
          >
            <Box>
              <Flex
                justify="space-between"
                align="center"
                pr={2}
                borderRadius="lg"
              >
                <Text
                  fontSize="16px"
                  fontWeight="500"
                  ml={4}
                  mt={2}
                  lineHeight="19.2px"
                  color="#000000"
                >
                  Cloud Foundation : Webinar Facebook leads
                </Text>

                <Box display="flex" alignItems="center">
                  <CiUser
                    style={{
                      marginRight: "10px",
                      width: "24px",
                      height: "24px",
                      marginTop: "-16px",
                    }}
                  />
                  <Text
                    mt={1}
                    fontSize="md"
                    color="gray.500"
                    mr={5}
                    whiteSpace="nowrap"
                  >
                    200 Leads
                  </Text>
                  <GoScreenFull
                    style={{
                      width: "24px",
                      height: "24px",
                      marginTop: "-16px",
                    }}
                  />
                </Box>
              </Flex>

              <Box
                w="100%"
                display="flex"
                flexDirection={{ base: "column", md: "row" }}
                flexWrap="wrap"
                justifyContent="space-between"
                // alignItems="center"
                pl={{base:"10px", md:"4px"}}
                pr={2}
                pb={0}
              >
                <InputGroup maxW="200px" mb={{ base: 2, md: 0 }}
                ml={{base:"",md:"13px"}}
                mt={{base:"", md:"13px"}}
                >
                  <Input
                    placeholder="Search for leads"
                    value={searchTerm}
                    onChange={handleSearchInputChange}
                    bg="#CCCBCB"
                    borderColor="gray.300"
                    _hover={{ borderColor: "gray.500" }}
                    focusBorderColor="blue.500"
                  />
                  <InputRightElement>
                    {searchTerm ? (
                      <IconButton
                        icon={<CloseIcon />}
                        size="sm"
                        aria-label="Clear search"
                      />
                    ) : (
                      <SearchIcon color="gray.500" />
                    )}
                  </InputRightElement>
                </InputGroup>

                <Box mb={{ base: 0, md: 0 }}>
                  <Text
                    fontSize="16px"
                    fontWeight="400"
                    color="black"
                    bg="white"
                    pl="8px"
                    pt="15px"
                  >
                    <Button
                      borderColor="E4E4E4"
                      borderWidth="1px"
                      borderStyle="solid"
                      bg="white"
                      leftIcon={<IoFilter />}
                      onClick={handleAddLead}
                    >
                      Filter
                    </Button>
                  </Text>
                </Box>

                <Box mb={{ base: 0, md: 0 }}>
                  <Text
                    fontSize="16px"
                    fontWeight="400"
                    color="black"
                    bg="white"
                    pl="8px"
                    pt="15px"
                  >
                    <Button
                      borderColor="E4E4E4"
                      borderWidth="1px"
                      borderStyle="solid"
                      bg="white"
                      leftIcon={<GrGroup />}
                    >
                      Grouping
                    </Button>
                  </Text>
                </Box>

                <Box mb={{ base: 0, md: 0 }}>
                  <Text
                    fontSize="16px"
                    fontWeight="400"
                    color="black"
                    bg="white"
                    pl="8px"
                    pt="15px"
                  >
                    <Button
                      borderColor="E4E4E4"
                      borderWidth="1px"
                      borderStyle="solid"
                      bg="white"
                    >
                      2 Campaign
                    </Button>
                  </Text>
                </Box>

                <Box mb={{ base: 0, md: 0 }}>
                  <Text
                    fontSize="16px"
                    fontWeight="400"
                    color="black"
                    bg="white"
                    pl="8px"
                    pt="15px"
                  >
                    <Button
                      borderColor="E4E4E4"
                      borderWidth="1px"
                      borderStyle="solid"
                      bg="white"
                      leftIcon={<CgBox />}
                    >
                      Create Campaign
                    </Button>
                  </Text>
                </Box>
              </Box>

             
            </Box>

            <Box borderWidth="1px" mt={0}>
              <TableContainer ml={-1}>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>
                        <Checkbox
                          isChecked={selectedItems === leads.length}
                          onChange={handleSelectAll}
                        />
                      </Th>
                      <Th>Task</Th>
                      <Th>Mail id</Th>
                      <Th>Phone No</Th>
                      <Th>Priority</Th>
                      {/* <Th>...</Th> */}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {currentItems?.map((lead) => (
                      <Tr key={lead.id}>
                        <Td>
                          <Checkbox
                            isChecked={lead.isSelected}
                            onChange={() => handleCheckboxChange(lead.id)}
                          />
                        </Td>
                        <Td>{lead.name}</Td>
                        <Td>{lead.email}</Td>
                        <Td>{lead.phone}</Td>
                        <Td>{lead.priority}</Td>

                        <Td>
                          <Menu>
                            <MenuButton bg={"white"} as={Button}>
                              ...
                            </MenuButton>
                            <MenuList>
                              <MenuItem onClick={() => handleEditLead(lead)}>
                                Edit
                              </MenuItem>
                              <MenuItem
                                onClick={() => handleDeleteLead(lead.id)}
                              >
                                Delete
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>

              <Box mt={4} display="flex" justifyContent="center">
                {Array.from({
                  length: Math.ceil(filteredLeads.length / itemsPerPage),
                }).map((_, idx) => (
                  <Button
                    key={idx}
                    variant="outline"
                    colorScheme="blue"
                    onClick={() => paginate(idx + 1)}
                    mx={1}
                    size="sm"
                    borderRadius="20px"
                  >
                    {idx + 1}
                  </Button>
                ))}
              </Box>
            </Box>

            {!isDesktop && (
              <Drawer placement="right" isOpen={isOpen} onClose={onClose}>
                <DrawerOverlay />
                <DrawerContent>
                  <DrawerCloseButton />
                  <DrawerHeader>Options</DrawerHeader>
                  <DrawerBody>
                    <Button mb={2} w="100%">
                      {filteredLeads.length} Total Leads
                    </Button>
                    <Button onClick={handleFileUpload} mb={2} w="100%">
                      Import Leads
                    </Button>
                    <Button onClick={handleExportLeads} mb={2} w="100%">
                      Export Selected Leads
                    </Button>
                    <Button mb={2} w="100%" onClick={handleDeleteAllLeads}>
                      Delete All Leads
                    </Button>
                    <Button mb={2} w="100%" onClick={handleAddLead}>
                      Add Leads
                    </Button>
                    <Button mb={2} w="100%">
                      Create Campaigns
                    </Button>
                    <Button
                      mb={2}
                      w="100%"
                      onClick={() => handleAddLink(leads.id)}
                    >
                      Generate Link
                    </Button>
                    <Button mb={2} w="100%">
                      {selectedItems} Leads selected{" "}
                    </Button>
                  </DrawerBody>
                  <DrawerFooter>
                    <Button variant="outline" mr={3} onClick={onClose}>
                      Close
                    </Button>
                  </DrawerFooter>
                </DrawerContent>
              </Drawer>
            )}

            {showAddForm && (
              <Modal isOpen={showAddForm} onClose={() => setShowAddForm(false)}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>
                    {isEditing ? "Edit Lead" : "Add Lead"}
                  </ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <FormControl isInvalid={formErrors.name}>
                      <FormLabel>Name</FormLabel>
                      <Input
                        value={newLead.name}
                        onChange={(e) =>
                          setNewLead({ ...newLead, name: e.target.value })
                        }
                      />
                      {formErrors.name && (
                        <FormErrorMessage>{formErrors.name}</FormErrorMessage>
                      )}
                    </FormControl>
                    <FormControl>
                      <FormLabel>Phone</FormLabel>
                      <Input
                        value={newLead.phone}
                        onChange={(e) =>
                          setNewLead({ ...newLead, phone: e.target.value })
                        }
                      />
                    </FormControl>
                    <FormControl isInvalid={formErrors.email}>
                      <FormLabel>Email</FormLabel>
                      <Input
                        value={newLead.email}
                        onChange={(e) =>
                          setNewLead({ ...newLead, email: e.target.value })
                        }
                      />
                      {formErrors.email && (
                        <FormErrorMessage>{formErrors.email}</FormErrorMessage>
                      )}
                    </FormControl>
                    <FormControl>
                      <FormLabel>Date</FormLabel>
                      <Input
                        type="date"
                        value={newLead.date}
                        onChange={(e) =>
                          setNewLead({ ...newLead, date: e.target.value })
                        }
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Priority</FormLabel>
                      <Input
                        value={newLead.priority}
                        onChange={(e) =>
                          setNewLead({ ...newLead, priority: e.target.value })
                        }
                      />
                    </FormControl>
                  </ModalBody>
                  <ModalFooter>
                    <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
                      {isEditing ? "Update" : "Add"}
                    </Button>
                    <Button
                      variant="ghost"
                      onClick={() => setShowAddForm(false)}
                    >
                      Cancel
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            )}
          </Box>
        </Box>

        <Box
          border="1px solid #ccc"
          ml={{ base: "12px", md: "10" }}
          mr={-3}
          borderRadius={"10px"}
          width={{ base: "100%", md: "30%" }}
        >
          <Box>
            <Box
              display="flex"
              flexDirection={{ base: "column", md: "row" }} // Stack buttons vertically on mobile, horizontally on larger screens
              justifyContent="space-between"
              width="100%"
            >
              <Box textAlign="center" flex="1" mx="2">
                <Box
                  bg="#E7FFE6"
                  color="#03F026"
                  fontSize="lg"
                  fontWeight="bold"
                  width="132px"
                  mt={3}
                  ml={3}
                  height="36px"
                  borderRadius="10px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <span
                    style={{
                      fontWeight: 500,
                      fontSize: "14px",
                      lineHeight: "21px",
                      letterSpacing: "-0.11px",
                    }}
                  >
                    Preview Mail
                  </span>
                </Box>
              </Box>

              <Box textAlign="center" flex="1" mx="2">
                <Box
                  bg="#E7FFE6"
                  color="#03F026"
                  fontSize="lg"
                  mt={3}
                  width="132px"
                  height="36px"
                  ml={{ base: "14px", md: "" }}
                  fontWeight="bold"
                  borderRadius="10px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <span
                    style={{
                      fontWeight: 500,
                      fontSize: "14px",
                      lineHeight: "21px",
                      letterSpacing: "-0.11px",
                    }}
                  >
                    Delivered
                  </span>
                </Box>
              </Box>
            </Box>
            {/* <Box display="flex" justifyContent="space-between" width="100%">
              <Box textAlign="center" flex="1" mx="2">
                <Box
                  bg="#E7FFE6"
                  color="#03F026"
                  fontSize="lg"
                  fontWeight="bold"
                  width={"132px"}
                  mt={3}
                  ml={3}
                  height={"36px"}
                  borderRadius="10px"
                >
                  <span
                    style={{
                      fontWeight: 500,
                      fontSize: "14px",
                      lineHeight: "21px",
                      letterSpacing: "-0.11px",
                    }}
                  >
                    Preview Mail
                  </span>
                </Box>
              </Box>

              <Box textAlign="center" flex="1" mx="2">
                <Box
                  bg="#E7FFE6"
                  color="#03F026"
                  fontSize="lg"
                  mt={3}
                  width={"132px"}
                  height={"36px"}
                  fontWeight="bold"
                  p={"auto"}
                  borderRadius="10px"
                >
                  <span
                    style={{
                      fontWeight: 500,
                      fontSize: "14px",
                      lineHeight: "21px",
                      letterSpacing: "-0.11px",
                    }}
                  >
                    Delivered
                  </span>
                </Box>
              </Box>
            </Box> */}
            <Text
              fontWeight="500"
              fontSize="14px"
              ml={5}
              mt={5}
              mb={1}
              lineHeight="14px"
              color="#000000"
            >
              Owner
            </Text>
            <Box
              w={"100%"}
              pl={5}
              pb={5}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              borderRadius="10px"
            >
              <Box display="flex" alignItems="center" gap={5}>
                <Avatar src="https://bit.ly/broken-link" />
                <Box display="flex" flexDirection="column" mt={5}>
                  <Text fontWeight="bold">Ajay Sonawane</Text>
                </Box>
              </Box>
            </Box>
            <Box pt={3} pl={5}>
              <Text ml={1}>Date Created</Text>

              <Box
                bg="#E7FFE6"
                color="#03F026"
                fontSize="lg"
                fontWeight="bold"
                width="220px"
                height="36px"
                ml={1}
                borderRadius="10px"
                display="flex"
                alignItems="center" // Center items vertically
                justifyContent="center" // Center items horizontally
                p="auto"
              >
                <span
                  style={{
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "21px",
                    letterSpacing: "-0.11px",
                  }}
                >
                  {campaignDetails?.start_day} {campaignDetails?.start_month} {campaignDetails?.start_year} | {campaignDetails?.hour} : {campaignDetails?.minute} {campaignDetails?.timeday}
                </span>
              </Box>
            </Box>
            <Box pt={3} pl={5}>
              <Text ml={1}>Date End</Text>

              <Box
                bg="#E7FFE6"
                color="#03F026"
                fontSize="lg"
                fontWeight="bold"
                width="220px"
                ml={1}
                height="36px"
                borderRadius="10px"
                display="flex"
                alignItems="center" // Center items vertically
                justifyContent="center" // Center items horizontally
                p="auto"
              >
                <span
                  style={{
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "21px",
                    letterSpacing: "-0.11px",
                  }}
                >
                {campaignDetails?.end_day} {campaignDetails?.end_month} {campaignDetails?.end_year} | 12:00 AM

                </span>
              </Box>
            </Box>
            <Box pt={3} pl={5}>
              <Text ml={1}>Budget</Text>

              <Box
                bg="#E7FFE6"
                color="#03F026"
                fontSize="lg"
                fontWeight="bold"
                ml={1}
                width="100px"
                height="36px"
                borderRadius="10px"
                display="flex"
                alignItems="center" // Center items vertically
                justifyContent="center" // Center items horizontally
                p="auto"
              >
                <span
                  style={{
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "21px",
                    letterSpacing: "-0.11px",
                  }}
                >
                  $ {campaignDetails?.price}/{campaignDetails?.budget_type}
                </span>
              </Box>
            </Box>
          </Box>

          {!isDesktop && (
            <Drawer placement="right" isOpen={isOpen} onClose={onClose}>
              <DrawerOverlay />
              <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>Options</DrawerHeader>
                <DrawerBody>
                  <Button mb={2} w="100%">
                    {filteredLeads.length} Total Leads
                  </Button>
                  <Button onClick={handleFileUpload} mb={2} w="100%">
                    Import Leads
                  </Button>
                  <Button onClick={handleExportLeads} mb={2} w="100%">
                    Export Selected Leads
                  </Button>
                  <Button mb={2} w="100%" onClick={handleDeleteAllLeads}>
                    Delete All Leads
                  </Button>
                  <Button mb={2} w="100%" onClick={handleAddLead}>
                    Add Leads
                  </Button>
                  <Button mb={2} w="100%">
                    Create Campaigns
                  </Button>
                  <Button
                    mb={2}
                    w="100%"
                    onClick={() => handleAddLink(leads.id)}
                  >
                    Generate Link
                  </Button>
                  <Button mb={2} w="100%">
                    {selectedItems} Leads selected{" "}
                  </Button>
                </DrawerBody>
                <DrawerFooter>
                  <Button variant="outline" mr={3} onClick={onClose}>
                    Close
                  </Button>
                </DrawerFooter>
              </DrawerContent>
            </Drawer>
          )}

          {showAddForm && (
            <Modal isOpen={showAddForm} onClose={() => setShowAddForm(false)}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>
                  {isEditing ? "Edit Lead" : "Add Lead"}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <FormControl isInvalid={formErrors.name}>
                    <FormLabel>Name</FormLabel>
                    <Input
                      value={newLead.name}
                      onChange={(e) =>
                        setNewLead({ ...newLead, name: e.target.value })
                      }
                    />
                    {formErrors.name && (
                      <FormErrorMessage>{formErrors.name}</FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl>
                    <FormLabel>Phone</FormLabel>
                    <Input
                      value={newLead.phone}
                      onChange={(e) =>
                        setNewLead({ ...newLead, phone: e.target.value })
                      }
                    />
                  </FormControl>
                  <FormControl isInvalid={formErrors.email}>
                    <FormLabel>Email</FormLabel>
                    <Input
                      value={newLead.email}
                      onChange={(e) =>
                        setNewLead({ ...newLead, email: e.target.value })
                      }
                    />
                    {formErrors.email && (
                      <FormErrorMessage>{formErrors.email}</FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl>
                    <FormLabel>Date</FormLabel>
                    <Input
                      type="date"
                      value={newLead.date}
                      onChange={(e) =>
                        setNewLead({ ...newLead, date: e.target.value })
                      }
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Priority</FormLabel>
                    <Input
                      value={newLead.priority}
                      onChange={(e) =>
                        setNewLead({ ...newLead, priority: e.target.value })
                      }
                    />
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
                    {isEditing ? "Update" : "Add"}
                  </Button>
                  <Button variant="ghost" onClick={() => setShowAddForm(false)}>
                    Cancel
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          )}
        </Box>

        {showLinkForm && (
          <Modal isOpen={showLinkForm} onClose={() => setShowLinkForm(false)}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Add Link</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <FormControl>
                  <FormLabel>Link</FormLabel>
                  <Input value={newLink} isReadOnly />
                </FormControl>
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="blue" onClick={handleLinkSubmit}>
                  Save Link
                </Button>
                <Button onClick={() => setShowLinkForm(false)}>Cancel</Button>
                <Button onClick={() => navigator.clipboard.writeText(newLink)}>
                  Copy Link
                </Button>
                <Button
                  onClick={() => {
                    if (navigator.share) {
                      navigator.share({
                        title: "Lead Link",
                        url: newLink,
                      });
                    } else {
                      alert("Sharing is not supported in this browser");
                    }
                  }}
                >
                  Share Link
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        )}
      </Flex>
    </Flex>
  );
}
