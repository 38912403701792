import React from 'react';
import { Box, Heading, Text, Checkbox, Flex, useBreakpointValue,Select } from '@chakra-ui/react';
import { px } from 'framer-motion';

const NotificationSettings = () => {
  const direction = useBreakpointValue({ base: 'column', md: 'row' });

  return (
    <Flex direction="column" padding="20px" gap="4">
      <Box flex="1" marginLeft={"20px"}>
        <Heading as="h1" size="lg" marginBottom="1">
          Notification Settings
        </Heading>
        <Text>
          We may still send you important notifications about your account outside of your notification settings.
        </Text>
      </Box>

      <Flex
        direction={direction}
        justify="space-between"
        align="flex-start"
        padding="20px"
        borderBottom="1px solid #ccc"
      >
        <Box flex="1" marginRight={direction === 'row' ? '20px' : '0'}>
          <Heading as="h1" size="md" marginBottom="4">
            Notification From Us
          </Heading>
          <Text>
            Receive messages with our news, events, or any other campaigns we find relevant for you.
          </Text>
        </Box>
        <Flex direction="column" align="flex-start" flex="1">
          {/* <Checkbox  marginBottom="">New Campaign Launched</Checkbox> */}
          <Checkbox
        sx={{
          '.chakra-checkbox__control': {
            borderColor: '#F52F2F', //
            _checked: {
              bg: '#F52F2F', //
              borderColor: '#F52F2F', //
              color: 'white', //
            },
          },
        }}
        marginBottom=""
      >
        New Campaign Launched
      </Checkbox>
          <Text marginBottom="4">
            Receive notification when a new campaign is launched
          </Text>
          {/* <Checkbox marginBottom="">Campaign Updates</Checkbox> */}
          <Checkbox
        sx={{
          '.chakra-checkbox__control': {
            borderColor: '#F52F2F', //
            _checked: {
              bg: '#F52F2F', //
              borderColor: '#F52F2F', //
              color: 'white', //
            },
          },
        }}
        marginBottom=""
      >
       Campaign Updates
      </Checkbox>

          <Text>
            Campaign Performance: Graphical Reports: views, clicks, conversions
          </Text>
        </Flex>
      </Flex>

      <Flex
        direction={direction}
        justify="space-between"
        align="flex-start"
        padding="20px"
        borderBottom="1px solid #ccc"
      >
        <Box flex="1" marginRight={direction === 'row' ? '20px' : '0'}>
          <Heading as="h1" size="md" marginBottom="4">
            Email Notification
          </Heading>
          <Text>
            You can receive email notifications for any new direct messages.
          </Text>
        </Box>
        <Flex direction="column" align="flex-start" flex="1">
          {/* <Checkbox marginBottom="4">Receive promotional emails</Checkbox> */}
          <Checkbox
        sx={{
          '.chakra-checkbox__control': {
            borderColor: '#F52F2F', //
            _checked: {
              bg: '#F52F2F', //
              borderColor: '#F52F2F', //
              color: 'white', //
            },
          },
        }}
        marginBottom="4"
      >
        Receive promotional emails
      </Checkbox>
          {/* <Checkbox marginBottom="">Receive budget updates</Checkbox> */}
          <Checkbox
        sx={{
          '.chakra-checkbox__control': {
            borderColor: '#F52F2F', //
            _checked: {
              bg: '#F52F2F', //
              borderColor: '#F52F2F', //
              color: 'white', //
            },
          },
        }}
        marginBottom=""
      >
        Receive budget updates
      </Checkbox>
          <Text marginBottom="4">
            Receive a notification email of transactions
          </Text>
          {/* <Checkbox>Daily Summary</Checkbox> */}
          <Checkbox
        sx={{
          '.chakra-checkbox__control': {
            borderColor: '#F52F2F', //
            _checked: {
              bg: '#F52F2F', //
              borderColor: '#F52F2F', //
              color: 'white', //
            },
          },
        }}
        marginBottom=""
      >
        Daily Summary
      </Checkbox>
          <Text>
            Receive a daily digest of data requests completed in a day
          </Text>
        </Flex>
      </Flex>

      <Flex
        direction={direction}
        justify="space-between"
        align="flex-start"
        padding="20px"
        borderBottom="1px solid #ccc"
      >
        <Box flex="1" marginRight={direction === 'row' ? '20px' : '0'}>
          <Heading as="h1" size="md" marginBottom="4">
            SMS Notification
          </Heading>
          <Text>
            You can receive SMS notifications for any new direct messages.
          </Text>
        </Box>
        <Flex direction="column" align="flex-start" flex="1">
          {/* <Checkbox marginBottom="">Receive SMS Campaign alerts</Checkbox> */}
          <Checkbox
        sx={{
          '.chakra-checkbox__control': {
            borderColor: '#F52F2F', //
            _checked: {
              bg: '#F52F2F', //
              borderColor: '#F52F2F', //
              color: 'white', //
            },
          },
        }}
        marginBottom=""
      >
        Receive SMS Champaign alerts
      </Checkbox>
          <Text marginBottom="4">
            Campaign Performance: views, clicks, conversions
          </Text>
        </Flex>
      </Flex>

      <Flex
        direction={direction}
        justify="space-between"
        align="flex-start"
        padding="20px"
        borderBottom="1px solid #ccc"
      >
        <Box flex="1" marginRight={direction === 'row' ? '20px' : '0'}>
          <Heading as="h1" size="md" marginBottom="4">
            Sound
          </Heading>
          <Text>
            This is a paragraph about notification sounds.
          </Text>
        </Box>
        <Flex direction="column" align="flex-start" flex="1">
          {/* <Checkbox marginBottom="">Play sound for all notifications</Checkbox> */}
          <Checkbox
        sx={{
          '.chakra-checkbox__control': {
            borderColor: '#F52F2F', //
            _checked: {
              bg: '#F52F2F', //
              borderColor: '#F52F2F', //
              color: 'white', //
            },
          },
        }}
        marginBottom=""
      >
        Play sound for all notification
      </Checkbox>
          <Text>
            Want sound for all notifications.
          </Text>
        </Flex>
      </Flex>

      <Flex
        direction={direction}
        justify="space-between"
        align="flex-start"
        padding="20px"
        borderBottom="1px solid #ccc"
      >
        <Box flex="1" marginRight={direction === 'row' ? '20px' : '0'}>
          <Heading as="h1" size="md" marginBottom="4">
            Do Not Disturb
          </Heading>
          <Text>
            Configure a period of time where notifications will not generate alerts.
          </Text>
        </Box>
        <Flex direction="column" align="flex-start" flex="1">
          {/* <Checkbox marginBottom="">Pause notifications</Checkbox> */}
          <Checkbox
        sx={{
          '.chakra-checkbox__control': {
            borderColor: '#F52F2F', //
            _checked: {
              bg: '#F52F2F', //
              borderColor: '#F52F2F', //
              color: 'white', //
            },
          },
        }}
        marginBottom=""
      >
        Pause notification
      </Checkbox>
      <Select maxW={"100px"} placeholder="1 Hr">
        <option value="1Hr">1 Hr</option>
        <option value="2Hr">2 Hr</option>
        <option value="3Hr">3 Hr</option>
        <option value="always">Always</option>
      </Select>
          <Text>
            Pause notifications for an hour if you need a quiet place.
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default NotificationSettings;
