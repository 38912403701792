import React from 'react';
import { Box, Flex, Heading, Input, Select, useToast, Text, Icon, Card } from '@chakra-ui/react';
import { ChatIcon, CheckCircleIcon, CloseIcon, InfoIcon } from '@chakra-ui/icons';
import { Bar, Pie, Line } from 'react-chartjs-2';
import * as XLSX from 'xlsx';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement, LineElement, PointElement } from 'chart.js';
import './WhatsAppAnalytics.css';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement, LineElement, PointElement);

const AnalyticsCard = ({ icon, title, value, detail, colorScheme, children }) => (
  <Card
    p={4}
    flex="1"
    borderWidth="1px"
    borderColor="gray.200"
    textAlign="left"
    bg={`${colorScheme}.50`}
    minWidth="250px"
    m={2}
    borderRadius="md"
    _hover={{ 
      bg: `${colorScheme}.100`,
      boxShadow: 'lg',
      transform: 'scale(1.03)',
      transition: 'transform 0.3s, box-shadow 0.3s'
    }}
    display="flex"
    flexDirection="column"
    justifyContent="space-between"
  >
    <Flex alignItems="center" mb={2} color={`${colorScheme}.700`}>
      {icon && <Icon as={icon} boxSize={6} mr={2} />}
      <Heading as="h2" size="md">{title}</Heading>
    </Flex>
    <Text fontSize="2xl" fontWeight="bold" color={`${colorScheme}.700`}>{value}</Text>
    <Text mt={2} color={`${colorScheme}.600`}>{detail}</Text>
    {children}
  </Card>
);

const createHistogramData = (value, label) => ({
  labels: ['Rate'],
  datasets: [{
    label,
    data: [value],
    backgroundColor: 'rgba(75, 192, 192, 0.2)',
    borderColor: 'rgba(75, 192, 192, 1)',
    borderWidth: 1,
  }],
});

const pieChartData = {
  labels: ['Delivered', 'Undelivered', 'Read', 'Unsubscribed'],
  datasets: [{
    data: [70, 10, 15, 5],
    backgroundColor: ['#4CAF50', '#FF5722', '#FFC107', '#2196F3'],
  }],
};

const lineChartData = {
  labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
  datasets: [{
    label: 'Engagement Metrics',
    data: [50, 45, 60, 65, 55, 50, 40],
    fill: false,
    borderColor: '#2196F3',
    tension: 0.1,
  }],
};

const chartOptions = {
  responsive: true,
  plugins: { 
    legend: { display: false } 
  },
  scales: { 
    x: { display: false }, 
    y: { beginAtZero: true, display: false } 
  }
};

const exportToExcel = (data, filename) => {
  const ws = XLSX.utils.json_to_sheet([data]);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Report');
  XLSX.writeFile(wb, filename);
};

const WhatsAppAnalytics = () => {
  const toast = useToast();

  const analyticsData = {
    sent: 7000,
    readRate: '50%',
    clickRate: '15%',
    unsubscribedRate: '5%',
    details: {
      deliveredRate: 70,
      undeliveredRate: 10,
      readRate: 15,
      unsubscribedRate: 5,
    }
  };

  const handleSaveReport = () => {
    localStorage.setItem('whatsappAnalyticsReport', JSON.stringify(analyticsData));
    toast({
      title: 'Report Saved',
      description: 'The report has been saved to local storage.',
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
  };

  const handleExportReport = () => {
    exportToExcel(analyticsData, 'whatsapp_analytics_report.xlsx');
    toast({
      title: 'Report Exported',
      description: 'The report has been exported as an Excel file.',
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
  };

  return (
    <Box p={6}>
      <Flex justifyContent="space-between" alignItems="center" className="analytics-header" wrap="wrap">
        <Heading as="h1" size="xl" mb={{ base: 4, md: 0 }}>WhatsApp Analytics</Heading>
        <Flex alignItems="center">
          <Input placeholder="Search..." mr={4} width={{ base: '100%', md: 'auto' }} />
          <Select placeholder="Last 30 Days" width={{ base: '100%', md: 'auto' }}>
            <option value="7_days">Last 7 Days</option>
            <option value="30_days">Last 30 Days</option>
            <option value="60_days">Last 60 Days</option>
          </Select>
        </Flex>
      </Flex>
      
      <Flex wrap="wrap" mb={2}>
        <AnalyticsCard
          icon={ChatIcon}
          title="Sent"
          value={analyticsData.sent}
          detail="Messages sent"
          colorScheme="blue"
        />
        <AnalyticsCard
          icon={CheckCircleIcon}
          title="Read Rate"
          value={analyticsData.readRate}
          detail="Messages read"
          colorScheme="green"
        />
        <AnalyticsCard
          icon={InfoIcon}
          title="Click Rate"
          value={analyticsData.clickRate}
          detail="Clicks on links"
          colorScheme="yellow"
        />
        <AnalyticsCard
          icon={CloseIcon}
          title="Unsubscribed Rate"
          value={analyticsData.unsubscribedRate}
          detail="Unsubscribed"
          colorScheme="red"
        />   
      </Flex>

      <Flex direction="row" className="analytics-actions" wrap="wrap" mb={4}>
        <Text cursor={'pointer'} onClick={handleSaveReport}>
          Save Report
        </Text>
        <Text cursor={'pointer'} onClick={handleExportReport}>
          Export Report
        </Text>
      </Flex>

      <Heading as="h2" size="lg" color="black" mb={4}>Delivery Analytics</Heading>
      <Flex wrap="wrap" mb={6}>
        <Box flex="1" minWidth="300px" p={4} bg="teal.50" borderRadius="md" borderWidth="1px" borderColor="teal.200">
          <Heading as="h3" size="md" mb={2}>Delivered Rate</Heading>
          <Bar
            data={createHistogramData(analyticsData.details.deliveredRate, 'Delivered Rate')}
            options={{ ...chartOptions, scales: { x: { display: true }, y: { beginAtZero: true, display: true } } }}
            height={150}
          />
          <Text mt={2} color="teal.600">{`${analyticsData.details.deliveredRate}%`}</Text>
        </Box>
        <Box flex="1" minWidth="300px" p={4} bg="orange.50" borderRadius="md" borderWidth="1px" borderColor="orange.200">
          <Heading as="h3" size="md" mb={2}>Undelivered Rate</Heading>
          <Bar
            data={createHistogramData(analyticsData.details.undeliveredRate, 'Undelivered Rate')}
            options={{ ...chartOptions, scales: { x: { display: true }, y: { beginAtZero: true, display: true } } }}
            height={150}
          />
          <Text mt={2} color="orange.600">{`${analyticsData.details.undeliveredRate}%`}</Text>
        </Box>
        <Box flex="1" minWidth="300px" p={4} bg="purple.50" borderRadius="md" borderWidth="1px" borderColor="purple.200">
          <Heading as="h3" size="md" mb={2}>Read Rate</Heading>
          <Bar
            data={createHistogramData(analyticsData.details.readRate, 'Read Rate')}
            options={{ ...chartOptions, scales: { x: { display: true }, y: { beginAtZero: true, display: true } } }}
            height={150}
          />
          <Text mt={2} color="purple.600">{`${analyticsData.details.readRate}%`}</Text>
        </Box>
        <Box flex="1" minWidth="300px" p={4} bg="cyan.50" borderRadius="md" borderWidth="1px" borderColor="cyan.200">
          <Heading as="h3" size="md" mb={2}>Unsubscribed Rate</Heading>
          <Bar
            data={createHistogramData(analyticsData.details.unsubscribedRate, 'Unsubscribed Rate')}
            options={{ ...chartOptions, scales: { x: { display: true }, y: { beginAtZero: true, display: true } } }}
            height={150}
          />
          <Text mt={2} color="cyan.600">{`${analyticsData.details.unsubscribedRate}%`}</Text>
        </Box>
      </Flex>

      <Flex direction={{ base: 'column', md: 'row' }} gap={4}>
        <Box className="chart-box">
          <Line
            data={lineChartData}
            options={{ responsive: true }}
            height={300}
          />
        </Box>
        <Box className="chart-box">
          <Pie
            data={pieChartData}
            options={{ responsive: true }}
            height={300}
          />
        </Box>
      </Flex>
    </Box>
  );
};

export default WhatsAppAnalytics;
