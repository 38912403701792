import React from 'react';
import {
  HStack,
  VStack,
  Avatar,
  Text,
  Box,
  IconButton,
  Spacer,
  Badge,
  AvatarGroup,
  Flex,
} from '@chakra-ui/react';
import { MdOutlineVideoChat } from 'react-icons/md';
import { AiOutlineMail } from 'react-icons/ai';
import { EmailIcon } from '@chakra-ui/icons';
import { BsThreeDotsVertical, BsChatDots } from 'react-icons/bs';
const HeaderChat = () => {
  return (
    <Box w="100%">
      <HStack p={4} borderBottom="1px solid gray">
        <Avatar name="Marcom" bg="red.500" src="link-to-avatar" />
        <Flex flexDirection="column" align="start" spacing={0}>
          <Text m={0} p={0} fontWeight="bold">
            Marcom
          </Text>
          <AvatarGroup size="xs" max={3}>
            <Avatar name="Ryan Florence" src="https://bit.ly/ryan-florence" />
            <Avatar name="Segun Adebayo" src="https://bit.ly/sage-adebayo" />
            <Avatar name="Kent Dodds" src="https://bit.ly/kent-c-dodds" />
            <Avatar
              name="Prosper Otemuyiwa"
              src="https://bit.ly/prosper-baba"
            />
            <Avatar
              ml={3}
              name="Christian Nwamba"
              src="https://bit.ly/code-beast"
            />
          </AvatarGroup>
        </Flex>
        <Spacer />
        <IconButton
          borderRadius="full"
          bg="#C3D7EB"
          size="md"
          aria-label="Messages"
          icon={<AiOutlineMail />}
        />
        <IconButton
          borderRadius="full"
          bg="#C3D7EB"
          size="md"
          aria-label="Messages"
          icon={<MdOutlineVideoChat />}
        />
        <IconButton
          borderRadius="full"
          bg="#C3D7EB"
          size="md"
          aria-label="Messages"
          icon={<BsThreeDotsVertical />}
        />
      </HStack>
    </Box>
  );
};

export default HeaderChat;
