import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Icon,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  FcAbout,
  FcAssistant,
  FcCollaboration,
  FcConferenceCall,
  FcManager,
} from "react-icons/fc";

const Card = ({ heading, description, icon, href }) => {
  return (
    <Box
      bg={"white"}
      maxW={{ base: "full", md: "275px" }}
      w={"full"}
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      p={5}
      transition="all 0.3s ease-in-out"
      _hover={{
        transform: "translateY(-5px)",
        borderRadius: "xl",
        borderColor: useColorModeValue("red.400", "red.600"),
      }}
    >
      <Stack align={"start"} spacing={2}>
        <Flex
          w={16}
          h={16}
          align={"center"}
          justify={"center"}
          color={"white"}
          rounded={"full"}
          bg={useColorModeValue("gray.100", "gray.700")}
        >
          {icon}
        </Flex>
        <Box mt={2}>
          <Heading size="md">{heading}</Heading>
          <Text mt={1} fontSize={"sm"}>
            {description}
          </Text>
        </Box>
        <Button variant={"link"} colorScheme={"red"} size={"sm"} href={href}>
          Learn more
        </Button>
      </Stack>
    </Box>
  );
};

export default function CardPage() {
  return (
    <Box p={4} bg={"red.500"}>
      <Stack spacing={4} as={Container} maxW={"3xl"} textAlign={"center"}>
        <Heading
          fontSize={{ base: "2xl", sm: "4xl" }}
          fontWeight={"bold"}
          color={"white"}
        >
          Marketing Services
        </Heading>
        <Text color={"white"} fontSize={{ base: "sm", sm: "lg" }}>
          Explore our range of marketing services tailored to boost your
          business.
        </Text>
      </Stack>

      <Container maxW={"5xl"} mt={12}>
        <Flex flexWrap="wrap" gridGap={6} justify="center">
          <Card
            heading={"Digital Marketing"}
            icon={<Icon as={FcAssistant} w={10} h={10} />}
            description={
              "Enhance your online presence with our strategic digital marketing solutions."
            }
            href={"#"}
          />
          <Card
            heading={"Collaborative Campaigns"}
            icon={<Icon as={FcCollaboration} w={10} h={10} />}
            description={
              "Join forces with us to create impactful collaborative marketing campaigns."
            }
            href={"#"}
          />
          <Card
            heading={"Social Media Marketing"}
            icon={<Icon as={FcConferenceCall} w={10} h={10} />}
            description={
              "Engage with your audience and grow your brand through social media marketing."
            }
            href={"#"}
          />
          <Card
            heading={"Management Consulting"}
            icon={<Icon as={FcManager} w={10} h={10} />}
            description={
              "Gain insights and strategic advice from our expert management consultants."
            }
            href={"#"}
          />
          <Card
            heading={"About Us"}
            icon={<Icon as={FcAbout} w={10} h={10} />}
            description={
              "Learn more about our company and how we can help you achieve marketing success."
            }
            href={"#"}
          />
        </Flex>
      </Container>
    </Box>
  );
}
