import React from 'react';
import { MdOutlineVideoChat } from 'react-icons/md';
import { IoQrCodeOutline } from 'react-icons/io5';
import {
  Flex,
  Box,
  Avatar,
  Text,
  IconButton,
  Divider,
  Button,
  Icon,
} from '@chakra-ui/react';
import { EmailIcon } from '@chakra-ui/icons';
import { AiOutlineMail } from 'react-icons/ai';
import {
  BsChatDots,
  BsQrCode,
  BsThreeDotsVertical,
  BsBoxArrowRight,
} from 'react-icons/bs';
const ProfileChat = () => {
  return (
    <Box
      w="20%"
      border="1px"
      borderTop={'0px'}
      borderBottom={'0px'}
      borderColor="#CCCBCB"
      height="95vh"
      bg="#FFFFFF"
      p={4}
    >
      <Flex direction="column" p={4} py={0} w="100%" maxW="300px">
        {/* Top Section with Avatar and Info */}
        <Flex justify="space-between" align="center" mb={2}>
          <Text m={0} fontWeight="bold">
            User Info
          </Text>
          <IconButton
            aria-label="More options"
            icon={<BsThreeDotsVertical />}
            size="sm"
          />
        </Flex>
        <Flex direction="column" align="center" mb={1}>
          <Avatar size="xl" name="Ajay Sonawane" src="link-to-avatar" mb={2} />
          <Text fontSize="sm" color="gray.500">
            Recent 09:30 A.M
          </Text>
        </Flex>

        {/* Action Icons */}
        <Flex justify="space-around" mb={2}>
          <IconButton
            borderRadius="full"
            bg="#C3D7EB"
            size="md"
            aria-label="Messages"
            icon={<AiOutlineMail />}
          />
          <IconButton
            borderRadius="full"
            bg="#C3D7EB"
            size="md"
            aria-label="Messages"
            icon={<MdOutlineVideoChat />}
          />
          <IconButton
            borderRadius="full"
            bg="#C3D7EB"
            size="md"
            aria-label="Messages"
            icon={<IoQrCodeOutline />}
          />
        </Flex>

        <Divider />

        {/* User Information */}
        <Flex direction="column" align="start" mt={1}>
          <Text m={0} fontWeight="bold">
            Name
          </Text>
          <Text>Ajay Sonawane</Text>

          <Text m={0} fontWeight="bold">
            Company Name
          </Text>
          <Text>Marcom</Text>

          <Text m={0} fontWeight="bold">
            Mobile
          </Text>
          <Text>(209) 555-0104</Text>

          <Text m={0} fontWeight="bold">
            Email
          </Text>
          <Text>georgia.young@example.com</Text>

          <Text m={0} fontWeight="bold">
            Bio
          </Text>
          <Text>The long barrow was built...</Text>
        </Flex>

        {/* Exit Group Button */}
        <Button colorScheme="red" variant="ghost" mt={1} alignSelf="start">
          <Flex justifyContent="center" alignItems="center" align="center">
            <Icon color="black" as={BsBoxArrowRight} mr={8} />
            <Text m={0}>Exit Group</Text>
          </Flex>
        </Button>
      </Flex>
    </Box>
  );
};

export default ProfileChat;
