import { IoEyeOutline } from "react-icons/io5";
import { CiSearch } from "react-icons/ci";

import React from "react";
import { Box, Flex, Text, Checkbox, Select } from "@chakra-ui/react";

const PrivacySettings = () => {
  return (
    <>
      <Flex
        direction={{ base: "column", md: "row" }}
        justify="flex-start"
        align="flex-start"
        padding="20px"
        maxW={{ base: "100%", md: "100%", lg: "60%" }}
      >
        <Box flex="1" marginRight={{ md: "20px", base: "0" }}>
          <Text
            display="flex"
            alignItems="center"
            fontSize="18px"
            fontWeight="700"
            lineHeight="28px"
            textAlign="left"
          >
            <IoEyeOutline style={{ marginRight: "8px" }} />
            Profile Visibility
          </Text>
        </Box>
        <Flex
          direction="column"
          align="flex-start"
          flex="1"
          mt={{ base: "4", md: "0" }}
        >
          <Select maxW={"300px"} bg={"#F6F7F8"}  placeholder="Public">
            <option>Private</option>
          </Select>
        </Flex>
      </Flex>

      <Flex
        direction={{ base: "column", md: "row" }}
        justify="flex-start"
        align="flex-start"
        padding="20px"
        maxW={{ base: "100%", md: "100%", lg: "60%" }}
      >
        <Box flex="1" marginRight={{ md: "20px", base: "0" }}>
          <Text
            display="flex"
            alignItems="center"
            fontSize="18px"
            fontWeight="700"
            lineHeight="28px"
            textAlign="left"
          >
            <CiSearch style={{ marginRight: "8px" }} />
            Search Visibility
          </Text>
        </Box>
        <Flex
          direction="column"
          align="flex-start"
          flex="1"
          mt={{ base: "4", md: "0" }}
        >
          <Checkbox
            sx={{
              ".chakra-checkbox__control": {
                borderColor: "#F52F2F",
                _checked: {
                  bg: "#F52F2F",
                  borderColor: "#F52F2F",
                  color: "white",
                },
              },
            }}
            marginBottom="2"
          >
            Allow search engines to index my profile
          </Checkbox>
          <Text>This will help to grow the community of the marcom.</Text>
        </Flex>
      </Flex>
    </>
  );
};

export default PrivacySettings;
