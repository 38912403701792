import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RiDeleteBin6Line } from "react-icons/ri";
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Sidebar from '../Sidebar/Sidebar';
import {
  Box,
  Text,
  Button,
} from '@chakra-ui/react';

export default function CampaignDrafts() {
  const navigate = useNavigate();
  const [AllDraft, setAllDraft] = useState([]);

  useEffect(() => {
    const LocalStorageData1 = localStorage.getItem("DraftItems");
    if (LocalStorageData1) {
      const FinalLocalData1 = JSON.parse(LocalStorageData1);
      setAllDraft(FinalLocalData1);
    }
  }, []);

  const HandleDeleteCampaign = (id) => {
    const newCampaigns = AllDraft.filter((item) => item.id !== id);
    setAllDraft(newCampaigns);
  };

  return (
    <>
      <Box width="100%" display="flex">
        <Box width="100%" p="20px">
          <Text fontSize="30px" color="#000000" fontWeight="700">Campaign Drafts</Text>
          <Box width="100%" display="flex" justifyContent="space-between" flexWrap="wrap" mt="20px">
            <Box width="100%" display="flex" justifyContent="space-between" mb="20px">
              <Text fontSize="20px" color="#000000" fontWeight="500">All Drafts</Text>
              <Button
                bg="red.400"
                color="white"
                _hover={{ bg: "red.300", color: "white" }}
                onClick={() => navigate("/CampaignType")}
              >
                + Create Campaign
              </Button>
            </Box>
            <Box width="100%">
              {AllDraft.length > 0 && <Text fontWeight="bold">{AllDraft.length} Draft{AllDraft.length > 1 ? 's' : ''}</Text>}
              {AllDraft.map((item, i) => (
                <Box key={item.id} p="20px" mb="10px" boxShadow="1px 2px 10px lightgray" borderRadius="8px">
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Text fontSize="20px" fontWeight="600">{item.campaignName}</Text>
                    <RiDeleteBin6Line size="20" cursor="pointer" color='red' onClick={() => HandleDeleteCampaign(item.id)} />
                  </Box>

                  <Box mt="10px">
                    <Text fontSize="16px" fontWeight="500">Campaign Details</Text>
                    <Text fontSize="14px">{item.campaignName}</Text>
                    <Text fontSize="14px">{item.campaignObjective}</Text>
                  </Box>

                  {item.campaignType === "Email" && (
                    <>
                      <Box mt="10px">
                        <Text fontSize="16px" fontWeight="500">Email Details</Text>
                        <Text fontSize="14px">{item.senderName}</Text>
                        <Text fontSize="14px">{item.senderEmail}</Text>
                        <Text fontSize="14px">{item.subjectLine}</Text>
                      </Box>

                      <Box mt="10px">
                        <Text fontSize="16px" fontWeight="500">Email Content</Text>
                        <Text fontSize="14px">{item.headline}</Text>
                        <Text fontSize="14px">{item.bodytext}</Text>
                      </Box>
                    </>
                  )}

                  {item.campaignType === "Whatsapp" && (
                    <Box mt="10px">
                      <Text fontSize="16px" fontWeight="500">Whatsapp Content</Text>
                      <Text fontSize="14px">{item.addContentTextMessage}</Text>
                      <Text fontSize="14px">{item.offerDetailTextMessage}</Text>
                    </Box>
                  )}

                  <Box mt="10px">
                    <Text fontSize="16px" fontWeight="500">Schedule</Text>
                    <Text fontSize="14px">{item.startDay}-{item.startMonth}-{item.startYear}</Text>
                    <Text fontSize="14px">{item.endDay}-{item.endMonth}-{item.endYear}</Text>
                    <Text fontSize="14px">{item.timeHour}:{item.timeMinute} {item.timeDay}</Text>
                  </Box>

                  <Box mt="10px">
                    <Text fontSize="16px" fontWeight="500">Campaign Budget</Text>
                    <Text fontSize="14px">{item.budgetType}</Text>
                    <Text fontSize="14px">{item.price}</Text>
                  </Box>
                </Box>
              ))}
              {AllDraft.length === 0 && <Text pt="20px" textAlign="center" fontSize="22px" fontWeight="bold" color="red.400">No Created Draft!</Text>}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
