import React from 'react';
import {
  Box, Flex, Heading, Select, useToast, Text, Icon, Card, Switch, useColorMode, 
  Tooltip as ChakraTooltip, Button, Grid
} from '@chakra-ui/react';
import { EmailIcon, CheckCircleIcon, CloseIcon, StarIcon, ArrowUpIcon, ArrowDownIcon } from '@chakra-ui/icons';
import Chart from 'react-google-charts';
import * as XLSX from 'xlsx';
import './EmailAnalytics.css';

const AnalyticsCard = ({ icon, title, value, detail, colorScheme, trend }) => (
  <Card
    p={5}
    minWidth="200px"
    height="150px"
    borderWidth="1px"
    borderColor="gray.200"
    textAlign="left"
    bg={`${colorScheme}.50`}
    m={2}
    borderRadius="md"
    _hover={{
      bg: 'white.500',
      boxShadow: 'lg',
      transform: 'scale(1.09)',
      transition: 'transform 0.3s, box-shadow 0.3s, bg 0.3s'
    }}
  >
    <Flex alignItems="center" mb={2} color={`${colorScheme}.700`}>
      {icon && <Icon as={icon} boxSize={5} mr={2} />}
      <Heading as="h2" size="sm">{title}</Heading>
      {trend && (
        <ChakraTooltip label={trend.direction === 'up' ? 'Increase' : 'Decrease'}>
          <Icon 
            as={trend.direction === 'up' ? ArrowUpIcon : ArrowDownIcon} 
            color={trend.direction === 'up' ? 'green.500' : 'red.500'}
            ml={2}
          />
        </ChakraTooltip>
      )}
    </Flex>
    <Text fontSize="xl" fontWeight="bold" color={`${colorScheme}.700`}>{value}</Text>
    <Text mt={2} color={`${colorScheme}.600`}>{detail}</Text>
  </Card>
);

const histogramData = [
  ['Month', 'Open Rate', 'Click Rate', 'Close Rate', 'Click Through Rate'],
  ['Jan', 65, 45, 20, 10],
  ['Feb', 59, 38, 15, 8],
  ['Mar', 80, 50, 25, 12],
  ['Apr', 81, 55, 30, 15],
  ['May', 56, 40, 20, 10],
  ['Jun', 55, 30, 15, 8],
  ['Jul', 40, 25, 10, 5],
];

const histogramOptions = {
  title: 'Campaign Metrics',
  chartArea: { width: '90%' },
  hAxis: { title: 'Month', minValue: 0 },
  vAxis: { title: 'Percentage' },
  colors: ['#ADD8E6', '#90EE90', '#FFB6C1', '#FFD700'],
  seriesType: 'bars',
  series: { 4: { type: 'line' } },
  legend: { position: 'bottom' },
};

const donutData = [
  ['Metric', 'Percentage'],
  ['Open Rate', 45],
  ['Click Rate', 12],
  ['Close Rate', 8],
  ['Click Through Rate', 10],
];

const donutOptions = {
  title: 'Campaign Metrics Distribution',
  pieHole: 0.1,
  colors: ['#ADD8E6', '#90EE90', '#FFB6C1', '#FFD700'], 
  legend: { position: 'bottom' },
  chartArea: { width: '100%' },
};

const exportToExcel = (data, filename) => {
  const ws = XLSX.utils.json_to_sheet([data]);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Report');
  XLSX.writeFile(wb, filename);
};

const EmailAnalytics = () => {
  const toast = useToast();
  const { colorMode, toggleColorMode } = useColorMode();

  const analyticsData = {
    sent: 5000,
    openRate: '45%',
    clickRate: '12%',
    closeRate: '8%',
    clickThroughRate: '10%',
    details: {
      deliveredRate: 80,
      undeliveredRate: 5,
      unsubscribedRate: 10,
      spamReport: 5,
    }
  };

  const handleSaveReport = () => {
    localStorage.setItem('emailAnalyticsReport', JSON.stringify(analyticsData));
    toast({
      title: 'Report Saved',
      description: 'The report has been saved to local storage.',
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
  };

  const handleExportReport = () => {
    exportToExcel(analyticsData, 'email_analytics_report.xlsx');
    toast({
      title: 'Export Successful',
      description: 'The report has been exported to Excel.',
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
  };

  return (
    <Flex direction="column" h="auto" p={1}>
      <Flex justify="space-between" align="center" mb={4}>
        <Heading as="h1" size="lg">Analytics</Heading>
        <Flex align="center">
          <Select placeholder="Select Campaign" mr={4}>
            <option value="campaign1">Campaign 1</option>
            <option value="campaign2">Campaign 2</option>
            <option value="campaign3">Campaign 3</option>
          </Select>
          <Icon as={StarIcon} boxSize={6} color="yellow.400" mr={4} />
          <Switch isChecked={colorMode === 'dark'} onChange={toggleColorMode} mr={4} />
          <Flex>
            <Button onClick={handleSaveReport} colorScheme="red" mr={2}>Save Report</Button>
            <Button onClick={handleExportReport} colorScheme="red">Export</Button>
          </Flex>
        </Flex>
      </Flex>
      <Flex wrap="wrap" mb={8}>
        <Box flex="1">
          <AnalyticsCard
            icon={EmailIcon}
            title="Sent Emails"
            value={analyticsData.sent}
            detail="Total emails sent"
            colorScheme="red.500"
          />
        </Box>
        <Box flex="1">
          <AnalyticsCard
            icon={CheckCircleIcon}
            title="Open Rate"
            value={analyticsData.openRate}
            detail="Percentage of emails opened"
            colorScheme="white"
            trend={{ direction: 'up' }}
          />
        </Box>
        <Box flex="1">
          <AnalyticsCard
            icon={CloseIcon}
            title="Close Rate"
            value={analyticsData.closeRate}
            detail="Percentage of emails closed without interaction"
            colorScheme="white"
            trend={{ direction: 'down' }}
          />
        </Box>
        <Box flex="1">
          <AnalyticsCard
            icon={StarIcon}
            title="Click Through Rate"
            value={analyticsData.clickThroughRate}
            detail="Percentage of clicks through links in emails"
            colorScheme="white"
            trend={{ direction: 'up' }}
          />
        </Box>
      </Flex>
      <Grid templateColumns="repeat(2, 1fr)" gap={6}>
        <Box>
          <Card
            p={4}
            borderWidth="1px"
            borderColor="gray.200"
            borderRadius="md"
            boxShadow="md"
          >
            <Chart
              width={'100%'}
              height={300} 
              chartType="Histogram"
              data={histogramData}
              options={histogramOptions}
            />
          </Card>
        </Box>
        <Box>
          <Card
            p={4}
            borderWidth="1px"
            borderColor="gray.200"
            borderRadius="md"
            boxShadow="md"
          >
            <Chart
              width={'100%'}
              height={300} 
              chartType="PieChart"
              data={donutData}
              options={donutOptions}
            />
          </Card>
        </Box>
      </Grid>
    </Flex>
  );
};

export default EmailAnalytics;
