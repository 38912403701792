import React, { useState } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Sidebar from '../Sidebar/Sidebar';
import Style from './Dashboard.module.css';
import {
  Box,
  Text,
  Flex,
  Image,
  Select,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Button,
} from '@chakra-ui/react';
import img1 from './icons/v1.png';
import img2 from './icons/ic-trending-up-24px.png';
import img3 from './icons/i1.png';
import img4 from './icons/i2.png';
import img5 from './icons/i3.png';
import img6 from './icons/ic-trending-down-24px.png';
import img7 from './icons/fire-fill 1.png';
import img8 from './icons/v2.png';
import img9 from './icons/v3.png';
import { Line } from 'react-chartjs-2';
import Chart from 'react-google-charts';


export default function Dashboard() {
  const [campaigns, setCampaigns] = useState({
    created: 200,
    running: 92,
    ended: 88,
    draft: 20,
  });

  const initialBudgetData = {
    filter: 'All',
    spend: 200,
    remaining: 800,
    details: [
      { month: 'June', amount: 3000, spent: 1800, remaining: 1200 },
      { month: 'July', amount: 2000, spent: 1200, remaining: 800 },
      { month: 'August', amount: 3000, spent: 1800, remaining: 1200 },
      { month: 'September', amount: 3000, spent: 1800, remaining: 1200 },
      { month: 'October', amount: 2000, spent: 1200, remaining: 800 },
      { month: 'November', amount: 2000, spent: 1200, remaining: 800 },
    ],
  };

  const [budgetData, setBudgetData] = useState(initialBudgetData);

  const [leadsList, setLeadsList] = useState([
    { course: 'Docker Course', leads: 15, status: 'Hot', campaigns: 2 },
    { course: 'AI is the future', leads: 19, status: 'Cold', campaigns: 4 },
    { course: 'Cloud AWS Webinar', leads: 8, status: 'Hot', campaigns: 1 },
    { course: 'Fullstack Demanding Tec', leads: 24, status: 'Warm', campaigns: 3 },
    { course: 'Node.js Course', leads: 32, status: 'Cold', campaigns: 1 },
    { course: 'React.js Course', leads: 52, status: 'Hot', campaigns: 2 },
    { course: 'Devops Cloud Course', leads: 36, status: 'Warm', campaigns: 4 },
  ]);

  const [chartData, setChartData] = useState({
    labels: ['5K', '10K', '15K', '20K', '25K', '30K', '35K', '40K', '45K', '50K'],
    datasets: [
      {
        label: 'Campaign',
        data: [20, 40, 10, 80, 30, 90, 50, 70, 100, 60],
        borderColor: '#ff8d8d',
        backgroundColor: '#ffdddd',
        fill: true,
      },
    ],
  });

//   const donutData = [
//   ['Metric', 'Percentage'],
//   ['Open Rate', 45],
//   ['Click Rate', 12],
//   ['Close Rate', 8],
//   ['Click Through Rate', 10],
// ];

// const donutOptions = {
//   title: 'Campaign Metrics Distribution',
//   pieHole: 0.1,
//   colors: ['#2196F3', '#4CAF50', '#FF5722', '#FFC107'],
//   legend: { position: 'bottom' },
//   chartArea: { width: '100%' },
// };

const data = [
  ['Month', 'Budget Spend $'],
  ['Jan', 100],
  ['Feb', 200],
  ['Mar', 750],
  ['Apr', 400],
  ['May', 125],
  ['June', 667],
  ['JulY', 50],
  ['Aug', 225]

];

const options = {
  title: "Company Budget",
  hAxis: { title: "Month", titleTextStyle: { color: "red" } },
  vAxis: { minValue: 0 },
  chartArea: { width: "80%", height: "70%" },
};

  const [viewAllLeads, setViewAllLeads] = useState(false);

  const handleBudgetFilterChange = (e) => {
    const filter = e.target.value;
    let updatedDetails;

    switch (filter) {
      case 'Daily':
        updatedDetails = [
          { day: '1', amount: 100, spent: 30, remaining: 70 },
          { day: '6', amount: 130, spent: 80, remaining: 50 },
          { day: '2', amount: 100, spent: 60, remaining: 40 },
          { day: '3', amount: 120, spent: 80, remaining: 40 },
          { day: '4', amount: 100, spent: 60, remaining: 40 },
          { day: '5', amount: 110, spent: 80, remaining: 30 },
        ];
        break;
      case 'Monthly':
        updatedDetails = [
          { month: 'June', amount: 3000, spent: 1800, remaining: 1200 },
          { month: 'July', amount: 2000, spent: 1200, remaining: 800 },
          { month: 'August', amount: 3000, spent: 1800, remaining: 1200 },
          { month: 'September', amount: 3000, spent: 1800, remaining: 1200 },
          { month: 'October', amount: 2000, spent: 1200, remaining: 800 },
          { month: 'November', amount: 2000, spent: 1200, remaining: 800 },
        ];
        break;
      case 'Yearly':
        updatedDetails = [
          { year: '2020', amount: 24000, spent: 14400, remaining: 9600 },
          { year: '2021', amount: 36000, spent: 21600, remaining: 14400 },
          { year: '2022', amount: 24000, spent: 14400, remaining: 9600 },
          { year: '2023', amount: 36000, spent: 21600, remaining: 14400 },
          { year: '2024', amount: 24000, spent: 14400, remaining: 9600 },
          { year: '2025', amount: 36000, spent: 21600, remaining: 14400 },
        ];
        break;
      default:
        updatedDetails = initialBudgetData.details;
        break;
    }

    setBudgetData((prevData) => ({
      ...prevData,
      filter,
      details: updatedDetails,
    }));
  };

  const handleViewAllLeads = () => {
    setViewAllLeads(!viewAllLeads);
  };

  return (
    <>
      {/* <Box width={"100%"} height={"100%"} display={"flex"}> */}
        <Box width={"100%"} p={"0px 10px"}>
          <Text fontSize={"24px"} color={"#000000"} fontWeight={"700"}>Dashboard</Text>
          <Box width={"100%"} display={"flex"} justifyContent={"space-between"} flexWrap={"wrap"}>
            {/* ************************************Box 1**************************************** */}
            <Box width={{ base: "100%", sm: "100%", md: "48%", lg: "21%" }} mt={"5px"}  boxShadow={"1px 2px 10px lightgray"} borderRadius={"15px"} p={"20px"}>
              <Text className={Style?.textBottomMargin} display={"flex"} justifyContent={"space-between"} fontWeight={"500"} fontSize={"16px"}>
                Campaigns Created <Image src={img1} w={"40px"} h={"40px"} bg={"purple.100"} p={"10px"} borderRadius={"15px"} />
              </Text>
              <Text fontSize={"30px"} color={"#000000"} fontWeight={"600"}>{campaigns.created}</Text>
              <Flex>
                <Image src={img2} />
                <Text pl={"5px"} className={Style?.textBottomMargin} color={"#00B69B"}>8.5%</Text>
                <Text pl={"5px"} className={Style?.textBottomMargin}>Up from May</Text>
              </Flex>
            </Box>
            {/* ************************************Box 2**************************************** */}
            <Box width={{ base: "100%", sm: "100%", md: "48%", lg: "21%" }} mt={"5px"} height={"auto"} boxShadow={"1px 2px 10px lightgray"} borderRadius={"15px"} p={"20px"}>
              <Text className={Style?.textBottomMargin} display={"flex"} justifyContent={"space-between"} fontWeight={"500"} fontSize={"16px"}>
                Campaigns Running <Image src={img3} w={"40px"} h={"40px"} bg={"yellow.100"} p={"10px"} borderRadius={"15px"} />
              </Text>
              <Text fontSize={"30px"} color={"#000000"} fontWeight={"600"}>{campaigns.running}</Text>
            </Box>
            {/* ************************************Box 3**************************************** */}
            <Box width={{ base: "100%", sm: "100%", md: "48%", lg: "21%" }} mt={"5px"} height={"auto"} boxShadow={"1px 2px 10px lightgray"} borderRadius={"15px"} p={"20px"}>
              <Text className={Style?.textBottomMargin} display={"flex"} justifyContent={"space-between"} fontWeight={"500"} fontSize={"16px"}>
                Campaigns Ended <Image src={img4} w={"40px"} h={"40px"} bg={"red.100"} p={"10px"} borderRadius={"15px"} />
              </Text>
              <Text fontSize={"30px"} color={"#000000"} fontWeight={"600"}>{campaigns.ended}</Text>
            </Box>
            {/* ************************************Box 4**************************************** */}
            <Box width={{ base: "100%", sm: "100%", md: "48%", lg: "21%" }} mt={"5px"} height={"auto"} boxShadow={"1px 2px 10px lightgray"} borderRadius={"15px"} p={"20px"}>
              <Text className={Style?.textBottomMargin} display={"flex"} justifyContent={"space-between"} fontWeight={"500"} fontSize={"16px"}>
                Campaign Draft<Image src={img5} w={"40px"} h={"40px"} bg={"green.100"} p={"10px"} borderRadius={"15px"} />
              </Text>
              <Text fontSize={"30px"} color={"#000000"} fontWeight={"600"}>{campaigns.draft}</Text>
              <Flex>
                <Image src={img6} />
                <Text pl={"5px"} className={Style?.textBottomMargin} color={"red.500"}>4.2%</Text>
                <Text pl={"5px"} className={Style?.textBottomMargin}>Down from May</Text>
              </Flex>
            </Box>
          </Box>
          {/* ***********************************Row 2***************************************** */}
          <Box width={"100%"} display={"flex"} justifyContent={"space-between"} mt={"20px"} flexDir={{ base: "column", sm: "column", md: "100%", lg: "row" }}>
            {/* ************************************Left Box************************************** */}
            <Box width={{ base: "100%", sm: "100%", md: "100%", lg: "47.5%" }}>
              <Box display={"flex"} width={"100%"} justifyContent={"space-between"} alignItems={"center"}>
                <Text className={Style?.textBottomMargin} fontSize={"20px"} color={"#000000"} fontWeight={"500"}>Budget Overview</Text>
                <Select width={"20%"} outline={"none"} border={"none"} bg={"#EDEDED"} onChange={handleBudgetFilterChange}>
                  <option>All</option>
                  <option>Daily</option>
                  <option>Monthly</option>
                  <option>Yearly</option>
                </Select>
              </Box>
              <Box width={"100%"} display={"flex"} mt={"20px"}>
                <Box width={"20%"} height={"50px"} bg={"red.500"} display={"flex"} justifyContent={"center"} alignItems={"center"} flexDir={"column"}>
                  <Text className={Style?.textBottomMargin} fontSize={"14px"} fontWeight={"600"} color={"white"}>${budgetData.spend}</Text>
                  <Text className={Style?.textBottomMargin} fontSize={"14px"} fontWeight={"600"} color={"white"}>Spend</Text>
                </Box>
                <Box width={"80%"} height={"50px"} bg={"#EDEDED"} display={"flex"} justifyContent={"center"} alignItems={"center"} flexDir={"column"}>
                  <Text className={Style?.textBottomMargin} fontSize={"14px"} fontWeight={"600"} color={"#000000"}>${budgetData.remaining}</Text>
                  <Text className={Style?.textBottomMargin} fontSize={"14px"} fontWeight={"600"} color={"#000000"}>Remaining</Text>
                </Box>
              </Box>
              <Box width={"100%"}>
                <TableContainer border={"2px solid #EDEDED"} mt={"20px"}>
                  <Table variant='simple'>
                    <Thead bg={"#EDEDED"}>
                      <Tr>
                        <Th>{budgetData.filter === 'Daily' ? 'Day' : budgetData.filter === 'Yearly' ? 'Year' : 'Month'}</Th>
                        <Th>Amount</Th>
                        <Th isNumeric>Budget Spent</Th>
                        <Th isNumeric>Remaining</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {budgetData.details.map((detail, index) => (
                        <Tr key={index}>
                          <Td>{budgetData.filter === 'Daily' ? detail.day : budgetData.filter === 'Yearly' ? detail.year : detail.month}</Td>
                          <Td>${detail.amount}</Td>
                          <Td isNumeric>${detail.spent}</Td>
                          <Td isNumeric>${detail.remaining}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
            {/* ************************************Right Box************************************** */}
            <Box width={{ base: "100%", sm: "100%", md: "100%", lg: "47.5%" }}>
              <Box display={"flex"} width={"100%"} justifyContent={"space-between"} alignItems={"center"}>
                <Box display={"flex"}>
                  <Text className={Style?.textBottomMargin} fontSize={"20px"} color={"#000000"} fontWeight={"500"}>List of leads</Text>
                  <Text className={Style?.textBottomMargin} ml="5px" p={"5px 12px"} borderRadius={"6px"} bg="#EDEDED">{leadsList.length} Lists</Text>
                </Box>
                <Button onClick={handleViewAllLeads} mr={"2px"} variant={"link"} color={"#000000"} fontWeight={"500"}>{viewAllLeads ? 'Show Less' : 'View All'}</Button>
              </Box>
              <Box width={"100%"} p={"15px"} mt={"25px"} overflowY={viewAllLeads ? "auto" : "hidden"} borderRadius={"10px"}  border={"2px solid #DEDEDE"} maxHeight={viewAllLeads ? "400px" : "500px"}>
                {leadsList.map((lead, index) => (
                  <Box key={index} width={"100%"} bg={"#EDEDED"} display={"flex"} mt={"10px"} justifyContent={"space-between"} alignItems={"center"} p={"10px"}>
                    <Text className={Style?.textBottomMargin} w={"35%"} color={"#000000"} fontWeight={"500"}>{lead.course}</Text>
                    <Text className={Style?.textBottomMargin} p={"5px"} color={"#000000"} fontWeight={"600"} borderRadius={"15px"} bg={"lightgray"}>{lead.leads} Leads</Text>
                    <Text className={Style?.textBottomMargin} p={"5px"} color={lead.status === 'Hot' ? 'red.500' : lead.status === 'Cold' ? '#B286FD' : '#FFA947'} fontWeight={"600"} display={"flex"} borderRadius={"15px"} bg={"white"}>
                      <Image src={lead.status === 'Hot' ? img7 : lead.status === 'Cold' ? img8 : img9} width={"20px"} height={"20px"} /> {lead.status}
                    </Text>
                    <Text className={Style?.textBottomMargin} p={"5px"} color={"#000000"} fontWeight={"600"} borderRadius={"15px"} bg={"lightgray"}>{lead.campaigns} Campaigns</Text>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
          {/* ***********************************Chart*********************************************** */}
          <Box width={"100%"} mt={"20px"}>
            <Text color={"#000000"} fontWeight={"500"} pl={"20px"} fontSize={"24px"}>Campaigns</Text>
            {/* <Line 
            data={chartData} 
            /> */}

            <Chart
              chartType="AreaChart"
              width="100%"
              height="500px"
              data={data}
              options={options}
            />
          </Box>
        </Box>
      {/* </Box> */}
    </>
  );
}
