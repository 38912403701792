import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './Components/HomePage/HomePage';
import SignIn from './Components/SignUp/SignIn';
import SignUp from './Components/SignUp/SignUp';
import EmailVerification from './Components/SignUp/EmailVerification';
import LogIn from './Components/LogIn/LogIn';
import RaiseTicket from './Components/Tickets/RaiseTicket';
import FeaturedApp from './Components/FeaturedApp/FeaturedApp';
import Customer from './Components/Customer/Customer';
import CRMPage from './Components/CRM/CRMPage';
import Contacts from './Components/CRM/Contacts';
import EmailCampaign from './Components/CampaignManagementPages/EmailCampaign';
import WhatsappCampaign from './Components/CampaignManagementPages/WhatsappCampaign';
import CampaignType from './Components/CampaignManagementPages/CampaignType';
import LeadManagement from './Components/LeadManagementPage/LeadManagement';
import CampaignDraft from './Components/CampaignManagementPages/CampaignDraft';
import ABTestingCampaign from './Components/CampaignManagementPages/ABTestingCampaign';
import MyCampaigns from './Components/CampaignManagementPages/MyCampaigns';
import LeadImport from './LeadImport';
import Profile from './Components/User Profile/Profile';
import Dashboard from './Components/Dashboard/Dashboard';
import SettingsPage from './Components/Settings/SettingsPage';
import Lead from './Components/LeadManagementPage/Lead';
import EmailAnalytics from './Components/Analytics/EmailAnalytics';
import Notifications from './Components/Notifications/Notifications';
import Supports from './Components/Supports/Supports';
import AllCampaigns from './Components/CampaignManagementPages/AllCampaigns';
import User from './Components/Users/User';
import Protection from './Components/Protected Route/Protection';
import CampaignDetail from './Components/CampaignManagementPages/CampaignDetail';
import FacebookCampaign from './Components/CampaignManagementPages/FacebookCampaign';
import LinkedinCampaign from './Components/CampaignManagementPages/LinkedinCampaign';
import InstagramCampaign from './Components/CampaignManagementPages/InstagramCampaign';
import TwitterCampaign from './Components/CampaignManagementPages/TwitterCampaign';
import ForgetPassword from './Components/SignUp/ForgetPassword';
import NotificationSettings from './Components/Settings/NotificationSettings';
import HelpCenter from './Components/Supports/HelpCenter';
import Layout from './Components/Layout/Layout';
import CommunityGroup from './Components/CommunityGroup/CommunityGroup';

function App() {
  return (
    <div>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/login" element={<LogIn />} />
            <Route
              path="/RaiseTicket"
              element={<Layout><Protection Component={RaiseTicket} /></Layout>}
            />
            <Route
              path="/FeaturedApp"
              element={<Layout><Protection Component={FeaturedApp} /></Layout>}
            />
            <Route
              path="/customer"
              element={<Layout><Protection Component={Customer} /></Layout>}
            />
            <Route
              path="/CRMPage"
              element={<Layout><Protection Component={CRMPage} /></Layout>}
            />
            <Route
              path="/Contacts"
              element={<Layout><Protection Component={Contacts} /></Layout>}
            />
            {/* <Route path="/LeadManagement" element={<LeadManagement/>} /> */}
            <Route
              path="/AllCampaigns"
              element={<Layout><Protection Component={AllCampaigns} /></Layout>}
            />
            <Route
              path="/EmailCampaign"
              element={<Layout><Protection Component={EmailCampaign} /></Layout>}
            />
            <Route
              path="/WhatsappCampaign"
              element={<Layout><Protection Component={WhatsappCampaign} /></Layout>}
            />
            <Route
              path="/LeadImport"
              element={<Layout><Protection Component={LeadImport} /></Layout>}
            />
            <Route
              path="/CampaignType"
              element={<Layout><Protection Component={CampaignType} /></Layout>}
            />
            <Route
              path="/CampaignDraft"
              element={<Layout><Protection Component={CampaignDraft} /></Layout>}
            />
            <Route
              path="/ABtesting"
              element={<Layout><Protection Component={ABTestingCampaign} /></Layout>}
            />
            <Route
              path="/MyCampaigns"
              element={<Layout><Protection Component={MyCampaigns} /></Layout>}
            />
            <Route
              path="/FacebookCampaign"
              element={<Layout><Protection Component={FacebookCampaign} /></Layout>}
            />
            <Route
              path="/LinkedinCampaign"
              element={<Layout><Protection Component={LinkedinCampaign} /></Layout>}
            />
            <Route
              path="/InstagramCampaign"
              element={<Layout><Protection Component={InstagramCampaign} /></Layout>}
            />
            <Route
              path="/TwitterCampaign"
              element={<Layout><Protection Component={TwitterCampaign} /></Layout>}
            />
            <Route
              path="/profile"
              element={<Layout><Protection Component={Profile} /></Layout>}
            />
            <Route
              path="/Dashboard"
              element={<Layout><Protection Component={Dashboard} /></Layout>}
            />
            <Route
              path="/CampaignDetails/:id/:type"
              element={<Layout><Protection Component={CampaignDetail} /></Layout>}
            />
            <Route
              path="/SettingsPage"
              element={<Layout><Protection Component={SettingsPage} /></Layout>}
            />

            <Route path="/leads" element={<Layout><Protection Component={Lead} /></Layout>} />
            <Route
              path="/EmailAnalytics"
              element={<Layout><Protection Component={EmailAnalytics} /></Layout>}
            />
            <Route
              path="/notifications"
              element={<Layout><Protection Component={Notifications} /></Layout>}
            />
            <Route
              path="/support"
              element={<Layout><Protection Component={Supports} /></Layout>}
            />
            <Route path="/User" element={<Layout><Protection Component={User} /></Layout>} />
            <Route
              path="/campaigns/:id"
              element={<Layout><Protection Component={CampaignDetail} /></Layout>}
            />
            <Route path="/emailverification" element=<EmailVerification /> />
            <Route path="/verify-email/:token" element=<EmailVerification /> />
            <Route path="/forget-password/:email" element=<ForgetPassword /> />
            <Route path="/account-profile" element={<Layout><Protection Component={HelpCenter}/></Layout>} />
            <Route path="/getting-started" element={<Layout><Protection Component={HelpCenter}/></Layout>} />
            <Route path="/learning-support" element={<Layout><Protection Component={HelpCenter}/></Layout>} />
            <Route path="/purchase-refund" element={<Layout><Protection Component={HelpCenter}/></Layout>} />
            <Route path="/security" element={<Layout><Protection Component={HelpCenter}/></Layout>} />
            <Route path="/Communitygroup" element={<Layout><Protection Component={CommunityGroup}/></Layout>} />
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
