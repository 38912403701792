import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import Footer from "../Footer/Footer";
import { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Img1 from "../LeadManagementPage/Logos/draft-line 1.png";
import Img2 from "../LeadManagementPage/Logos/exchange-2-line 1.png";
import Img3 from "../LeadManagementPage/Logos/eye-line 1.png";
import Style from "../LeadManagementPage/LeadManagement.module.css";
import { BiImageAdd } from "react-icons/bi";
// import { useToast } from "@chakra-ui/react";
import {ToastContainer, toast } from 'react-toastify';
import PreviewTemplate from "./PreviewTemplate";
import {
  Box,
  Text,
  Button,
  Image,
  Input,
  Select,
  Textarea,
  Flex,
  useBreakpointValue,
  useDisclosure,
  IconButton,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { CallAPI } from "../../Services/service";
import endpoint from "../../Services/endpoint";
// import { ToastContainer, toast } from "react-toastify";

export default function TwitterCampaign() {
  const [allFiledsFilled, setAllFieldsFilled] = useState(false);
  const [isSavedCampaign, setIsSavedCampaign] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isDesktop = useBreakpointValue({ base: false, md: true });
//   const toast = useToast();
  const navigate = useNavigate();
  const [date, setDate] = useState(new Date());
  const HandleDateOnChange = (value) => {
    setDate(value);
    console.log(value);
  };

  const userDetails = JSON.parse(localStorage.getItem("userData"))

  // const toast = useToast()
  // let uid = (new Date().getTime()).toString(36)

  const AddNewCampaignForm = () => {
    setFormData([
      ...formData,
      {
        userId:userDetails.id,
        campaign_name: "",
        campaign_objective: "",
        location: "",
        age_from: "",
        age_to: "",
        gender: "",
        interest: "",
        languages: "",
        keywords: "",
        followers: "",
        start_day: "DD",
        start_month: "MM",
        start_year: "YY",
        end_day: "DD",
        end_month: "MM",
        end_year: "YY",
        standard_timezone: "",
        hour: "00",
        minute: "00",
        timeday: "AM",
        tweet_text: "",
        call_to_action: "",
        budget_type: "",
        price: "",
      },
    ]);
    // toast({
    //     title: 'Added',
    //     status: 'success',
    //     duration: 3000,
    //     isClosable: true,
    //   })
  };

  const [formData, setFormData] = useState([
    {
      userId:userDetails.id,
      campaign_name: "",
      campaign_objective: "",
      location: "",
      age_from: "",
      age_to: "",
      gender: "",
      interest: "",
      languages: "",
      keywords: "",
      followers: "",
      start_day: "DD",
      start_month: "MM",
      start_year: "YY",
      end_day: "DD",
      end_month: "MM",
      end_year: "YY",
      standard_timezone: "",
      hour: "00",
      minute: "00",
      timeday: "AM",
      tweet_text: "",
      call_to_action: "",
      budget_type: "",
      price: "",
    },
  ]);

  const DeleteCampaign = (formIndex) => {
    const newForms = formData.filter((item, index) => index !== formIndex);
    setFormData(newForms);
    // toast({
    //     title: 'Deleted',
    //     status: 'success',
    //     duration: 3000,
    //     isClosable: true,
    //   })
  };

  const HandleOnChange = (e, i) => {
    const { name, value } = e.target;
    const onChangeValue = [...formData];
    onChangeValue[i][name] = value;
    setFormData(onChangeValue);
  };




  const SaveCampaignData = (e) => {
    e.preventDefault();
    formData.forEach((obj) => {
      for (const [key, value] of Object.entries(obj)) {
        console.log(`${key}: ${value}`);
        if (value === "") {
          setAllFieldsFilled(true);
          setIsSavedCampaign(false);
          break;
        }
        if (value !== "") {
          setAllFieldsFilled(false);
          setIsSavedCampaign(true);
        }
      }
      saveInDB();
    });
  };

 


  const saveInDB = () => {
    try {
      CallAPI(endpoint.twitterCampaign, formData)
        .then((res) => {
          console.log(res);
        if(res?.status?.code===200){
            toast.success(res?.status?.message)
        }else{
            toast.error("Failed to Create test")
        }
        })
        .catch((error) => console.log(error));
    } catch (error) {
      console.log("Error>>", error);
    }
  };

  const SaveDraft = (e) => {
    const finalData = JSON.stringify(formData);
    localStorage.setItem("DraftItems", finalData);
    // toast({
    //     title: 'Draft Saved Successfully',
    //     status: 'success',
    //     duration: 3000,
    //     isClosable: true,
    // })
  };

  const generateOptions = (start, end) =>
    Array.from({ length: end - start + 1 }, (_, i) => (
      <option key={i + start}>{i + start}</option>
    ));

  return (
    <>
      <Box display={"flex"}>
        <Box w={"100%"} h={"auto"}>
          {/* *******************************TOP BUTTONS***************************** */}

          {/* <Box
            width={{ base: "70%", md: "93%" }}
            display={"flex"}
            alignItems={"center"}
          >
            {isDesktop ? (
              <Box w={"100%"} display={"flex"} p={"25px"}>
                <Button bg={"#EBEBEB"} borderRadius="10px" onClick={SaveDraft}>
                  <Image src={Img1} height="16px" mr={"10px"} />
                  <span color={"#000000"} fontSize={"16px"}>
                    Save Draft
                  </span>
                </Button>
                <Button bg={"#EBEBEB"} m={"0px 20px"} borderRadius="10px">
                  <Image src={Img2} height="16px" mr={"10px"} />
                  <span fontSize={"16px"} color={"#000000"}>
                    Change Template
                  </span>
                </Button>
                <Button
                  bg={"#EBEBEB"}
                  borderRadius="10px"
                  onClick={() => setShowPreview(true)}
                >
                  <Image src={Img3} height="16px" mr={"10px"} />
                  <span fontSize={"16px"} color={"#000000"}>
                    Twitter Preview
                  </span>
                </Button>
                <Button
                  bg={"#EBEBEB"}
                  borderRadius="10px"
                  ml={"20px"}
                  onClick={() => navigate("/LeadManagement")}
                >
                  <span fontSize={"16px"} color={"#000000"}>
                    All Campaigns
                  </span>
                </Button>
                <Button
                  bg={"#EBEBEB"}
                  borderRadius="10px"
                  ml={"20px"}
                  onClick={() => navigate("/CampaignDraft")}
                >
                  <span fontSize={"16px"} color={"#000000"}>
                    All Drafts
                  </span>
                </Button>
              </Box>
            ) : (
              <IconButton
                icon={<HamburgerIcon />}
                aria-label="Menu"
                variant="outline"
                onClick={onOpen}
                ml={"25px"}
                mt={"5px"}
              />
            )}
          </Box>

          {!isDesktop && (
            <Drawer placement="right" isOpen={isOpen} onClose={onClose}>
              <DrawerOverlay />
              <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>Options</DrawerHeader>
                <DrawerBody>
                  <Button mb={"5px"} w={"100%"} onClick={SaveDraft}>
                    <Image src={Img1} height="16px" mr={"10px"} />
                    <span color={"#000000"} fontSize={"16px"}>
                      Save Draft
                    </span>
                  </Button>
                  <Button mb={"5px"} w={"100%"}>
                    <Image src={Img2} height="16px" mr={"10px"} />
                    <span fontSize={"16px"} color={"#000000"}>
                      Change Template
                    </span>
                  </Button>
                  <Button
                    mb={"5px"}
                    w={"100%"}
                    onClick={() => setShowPreview(true)}
                  >
                    <Image
                      src={Img3}
                      height="16px"
                      mr={"10px"}
                      width={"auto"}
                    />
                    <span fontSize={"16px"} color={"#000000"}>
                      Twitter Preview
                    </span>
                  </Button>
                  <Button
                    mb={"5px"}
                    w={"100%"}
                    onClick={() => navigate("/LeadManagement")}
                  >
                    <span fontSize={"16px"} color={"#000000"}>
                      All Campaigns
                    </span>
                  </Button>
                  <Button
                    mb={"5px"}
                    w={"100%"}
                    onClick={() => navigate("/CampaignDraft")}
                  >
                    <span fontSize={"16px"} color={"#000000"}>
                      All Drafts
                    </span>
                  </Button>
                </DrawerBody>
                <DrawerFooter>
                  <Button variant="outline" mr={3} onClick={onClose}>
                    Close
                  </Button>
                </DrawerFooter>
              </DrawerContent>
            </Drawer>
          )} */}

          {/* **********************************FORM SECTION*************************** */}
          <Text className={Style?.textBottomMargin} pl={"10px"} color="#000000" fontSize={"24px"} fontWeight={"700"} >Twitter Campaign Form</Text>
          {formData?.map((items, i) => {
            return (
              <>
                <Box
                  className={Style?.mainDiv}
                  w={"100%"}
                  display={"flex"}
                  flexDirection={{
                    base: "column",
                    sm: "column",
                    md: "column",
                    lg: "row",
                  }}
                  justifyContent={"space-between"}
                  p={"0px 10px"}
                  pb={"25px"}
                  key={items.uniqueID}
                >
                  <Box
                    className={Style?.formDiv}
                    width={{ base: "100%", sm: "100%", md: "100%", lg: "40%" }}
                  >
                    <Box>
                      <Text
                        fontSize={"24px"}
                        color={"#000000"}
                        fontWeight={"500"}
                      >
                        Campaign Details
                      </Text>
                      <Text
                        fontSize={"18px"}
                        color={"#252B42"}
                        fontWeight={"500"}
                      >
                        Campaign Name *
                      </Text>
                      <Input
                        required
                        type="text"
                        border={"1px solid #E6E6E6"}
                        bg={"#F9F9F9"}
                        borderRadius={"5px"}
                        placeholder="Campaign Name *"
                        name="campaign_name"
                        value={formData.campaign_name}
                        onChange={(e) => HandleOnChange(e, i)}
                      />
                      {allFiledsFilled && formData[i]?.campaign_name === "" ? (
                        <Text color={"red"}>Campaign name is required.</Text>
                      ) : (
                        ""
                      )}
                      <Text
                        fontSize={"18px"}
                        color={"#252B42"}
                        fontWeight={"500"}
                        pt={"10px"}
                      >
                        Objective *
                      </Text>
                      <Select
                        width="100%%"
                        color="gray"
                        fontSize="16px"
                        fontWeight="400"
                        bg="#F9F9F9"
                        borderRadius="6px"
                        name="campaign_objective"
                        onChange={(e) => HandleOnChange(e, i)}
                      >
                        <option>Select</option>
                        <option>Advertisement for campaign</option>
                      </Select>
                      {allFiledsFilled &&
                      formData[i]?.campaign_objective === "" ? (
                        <Text color={"red"}>
                          Campaign objective is required.
                        </Text>
                      ) : (
                        ""
                      )}
                    </Box>
                    <Box mt={"30px"}>
                      <Text
                        fontSize={"24px"}
                        color={"#000000"}
                        fontWeight={"500"}
                      >
                        Audience
                      </Text>
                      <Text
                        fontSize={"18px"}
                        color={"#252B42"}
                        fontWeight={"500"}
                      >
                        Location*
                      </Text>
                      <Input
                        required
                        type="text"
                        border={"1px solid #E6E6E6"}
                        bg={"#F9F9F9"}
                        borderRadius={"5px"}
                        placeholder="Location*"
                        name="location"
                        value={formData.location}
                        onChange={(e) => HandleOnChange(e, i)}
                      />
                      {allFiledsFilled && formData[i]?.location === "" ? (
                        <Text color={"red"}>Location is required.</Text>
                      ) : (
                        ""
                      )}
                      <Text
                        fontSize={"18px"}
                        color={"#252B42"}
                        fontWeight={"500"}
                        mt={"20px"}
                      >
                        Age Range*
                      </Text>
                      <Flex justifyContent="space-between">
                        <Text fontSize="16px" color="#000000" fontWeight="500">
                          From
                        </Text>
                        <Text fontSize="16px" color="#000000" fontWeight="500">
                          To
                        </Text>
                      </Flex>
                      <Flex justifyContent="space-between">
                        <Select
                          width="40%"
                          color="#8740FB"
                          fontSize="16px"
                          fontWeight="700"
                          bg="#F2E3FD"
                          borderRadius="6px"
                          name="age_from"
                          onChange={(e) => HandleOnChange(e, i)}
                        >
                          {generateOptions(1, 99)}
                        </Select>
                        <Select
                          width="40%"
                          color="#8740FB"
                          fontSize="16px"
                          fontWeight="700"
                          bg="#F2E3FD"
                          borderRadius="6px"
                          name="age_to"
                          onChange={(e) => HandleOnChange(e, i)}
                        >
                          {generateOptions(1, 99)}
                        </Select>
                      </Flex>
                      <Text
                        fontSize={"18px"}
                        color={"#252B42"}
                        fontWeight={"500"}
                        pt={"10px"}
                      >
                        Gender *
                      </Text>
                      <Select
                        width="100%%"
                        color="gray"
                        fontSize="16px"
                        fontWeight="400"
                        bg="#F9F9F9"
                        borderRadius="6px"
                        name="audience_gender"
                        onChange={(e) => HandleOnChange(e, i)}
                      >
                        <option>Male</option>
                        <option>Female</option>
                      </Select>
                      {allFiledsFilled &&
                      formData[i]?.audience_gender === "" ? (
                        <Text color={"red"}>Gender is required.</Text>
                      ) : (
                        ""
                      )}
                      <Text
                        fontSize={"18px"}
                        color={"#252B42"}
                        fontWeight={"500"}
                      >
                        interest
                      </Text>
                      <Input
                        required
                        type="text"
                        border={"1px solid #E6E6E6"}
                        bg={"#F9F9F9"}
                        borderRadius={"5px"}
                        placeholder="interest"
                        name="interest"
                        value={formData.interest}
                        onChange={(e) => HandleOnChange(e, i)}
                      />
                      {allFiledsFilled && formData[i]?.interest === "" ? (
                        <Text color={"red"}>Campaign Interest is required.</Text>
                      ) : (
                        ""
                      )}
                      <Text
                        fontSize={"18px"}
                        color={"#252B42"}
                        fontWeight={"500"}
                      >
                        Languages
                      </Text>
                      <Input
                        required
                        type="text"
                        border={"1px solid #E6E6E6"}
                        bg={"#F9F9F9"}
                        borderRadius={"5px"}
                        placeholder="Languages"
                        name="languages"
                        value={formData.languages}
                        onChange={(e) => HandleOnChange(e, i)}
                      />
                      <Text
                        fontSize={"18px"}
                        color={"#252B42"}
                        fontWeight={"500"}
                      >
                        Keywords
                      </Text>
                      <Input
                        required
                        type="text"
                        border={"1px solid #E6E6E6"}
                        bg={"#F9F9F9"}
                        borderRadius={"5px"}
                        placeholder="Keywords"
                        name="languages"
                        value={formData.keywords}
                        onChange={(e) => HandleOnChange(e, i)}
                      />
                      <Text
                        fontSize={"18px"}
                        color={"#252B42"}
                        fontWeight={"500"}
                      >
                        Followers
                      </Text>
                      <Input
                        required
                        type="text"
                        border={"1px solid #E6E6E6"}
                        bg={"#F9F9F9"}
                        borderRadius={"5px"}
                        placeholder="Followers"
                        name="followers"
                        value={formData.followers}
                        onChange={(e) => HandleOnChange(e, i)}
                      />

                      <Text
                        fontSize="24px"
                        color="#000000"
                        fontWeight="500"
                        mt="25px"
                      >
                        Campaign Budget
                      </Text>
                      <Box
                        display="flex"
                        alignItems="center"
                        width="100%"
                        justifyContent="space-between"
                        flexDirection={{ base: "column", lg: "column" }}
                      >
                        <Box
                          width={{ base: "100%", lg: "100%" }}
                          flexDir="column"
                        >
                          <Text
                            fontSize="16px"
                            color="#000000"
                            fontWeight="500"
                          >
                            Budget Type *
                          </Text>
                          <Select
                            width="100%"
                            border="1px solid #E6E6E6"
                            color="#737373"
                            fontSize="16px"
                            fontWeight="400"
                            bg="#F9F9F9"
                            borderRadius="6px"
                            name="budget_type"
                            onChange={(e) => HandleOnChange(e, i)}
                          >
                            <option>Daily</option>
                            <option>Monthly</option>
                            <option>Yearly</option>
                          </Select>
                          {allFiledsFilled &&
                            formData[i]?.budget_type === "" && (
                              <Text color="red">
                                Budget duration is required
                              </Text>
                            )}
                        </Box>
                        <Box
                          width={{ base: "100%", lg: "100%" }}
                          flexDir="column"
                        >
                          <Text
                            fontSize="16px"
                            color="#000000"
                            fontWeight="500"
                          >
                            Price *
                          </Text>
                          <Input
                            width="100%"
                            required
                            type="text"
                            border="1px solid #E6E6E6"
                            bg="#F9F9F9"
                            borderRadius="5px"
                            placeholder="$ Price"
                            name="price"
                            value={formData.price}
                            onChange={(e) => HandleOnChange(e, i)}
                          />
                          {allFiledsFilled && formData[i]?.price === "" && (
                            <Text color="red">Price is required</Text>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                  <Box
                    className={Style?.timerDiv}
                    width={{ base: "100%", lg: "55%" }}
                  >
                    <Text fontSize="24px" color="#000000" fontWeight="500">
                      Schedule
                    </Text>
                    <Box
                      display="flex"
                      width={{ base: "100%", lg: "90%" }}
                      flexDir={{ base: "column", lg: "row" }}
                      height="auto"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Box width={{ base: "100%", lg: "45%" }}>
                        <Text fontSize="16px" color="#000000" fontWeight="500">
                          Start Date
                        </Text>
                        <Flex justifyContent="space-between">
                          <Select
                            width="26%"
                            color="#8740FB"
                            fontSize="16px"
                            fontWeight="700"
                            bg="#F2E3FD"
                            borderRadius="6px"
                            name="start_day"
                            onChange={(e) => HandleOnChange(e, i)}
                          >
                            {generateOptions(1, 31)}
                          </Select>
                          <Select
                            width="40%"
                            color="#8740FB"
                            fontSize="16px"
                            fontWeight="700"
                            bg="#F2E3FD"
                            borderRadius="6px"
                            name="start_month"
                            onChange={(e) => HandleOnChange(e, i)}
                          >
                            {[
                              "January",
                              "February",
                              "March",
                              "April",
                              "May",
                              "June",
                              "July",
                              "August",
                              "September",
                              "October",
                              "November",
                              "December",
                            ].map((month) => (
                              <option key={month}>{month}</option>
                            ))}
                          </Select>
                          <Select
                            width="30%"
                            color="#8740FB"
                            fontSize="16px"
                            fontWeight="700"
                            bg="#F2E3FD"
                            borderRadius="6px"
                            name="start_year"
                            onChange={(e) => HandleOnChange(e, i)}
                          >
                            {generateOptions(2024, 2029)}
                          </Select>
                        </Flex>
                        <Text
                          fontSize="16px"
                          color="#000000"
                          fontWeight="500"
                          mt="20px"
                        >
                          End Date
                        </Text>
                        <Flex justifyContent="space-between">
                          <Select
                            width="26%"
                            color="#8740FB"
                            fontSize="16px"
                            fontWeight="700"
                            bg="#F2E3FD"
                            borderRadius="6px"
                            name="end_day"
                            onChange={(e) => HandleOnChange(e, i)}
                          >
                            {generateOptions(1, 31)}
                          </Select>
                          <Select
                            width="40%"
                            color="#8740FB"
                            fontSize="16px"
                            fontWeight="700"
                            bg="#F2E3FD"
                            borderRadius="6px"
                            name="end_month"
                            onChange={(e) => HandleOnChange(e, i)}
                          >
                            {[
                              "January",
                              "February",
                              "March",
                              "April",
                              "May",
                              "June",
                              "July",
                              "August",
                              "September",
                              "October",
                              "November",
                              "December",
                            ].map((month) => (
                              <option key={month}>{month}</option>
                            ))}
                          </Select>
                          <Select
                            width="30%"
                            color="#8740FB"
                            fontSize="16px"
                            fontWeight="700"
                            bg="#F2E3FD"
                            borderRadius="6px"
                            name="end_year"
                            onChange={(e) => HandleOnChange(e, i)}
                          >
                            {generateOptions(2024, 2029)}
                          </Select>
                        </Flex>
                        <Text
                          fontSize="16px"
                          color="#000000"
                          fontWeight="500"
                          mt="20px"
                        >
                          Time
                        </Text>
                        <Box>
                          <Select
                            width="26%"
                            color="#8740FB"
                            fontSize="16px"
                            fontWeight="700"
                            bg="#F2E3FD"
                            borderRadius="6px"
                            name="standard_timezone"
                            onChange={(e) => HandleOnChange(e, i)}
                          >
                            <option>IST</option>
                          </Select>
                        </Box>
                        <Flex justifyContent="space-between" mt="5px">
                          <Select
                            width="26%"
                            color="#8740FB"
                            fontSize="16px"
                            fontWeight="700"
                            bg="#F2E3FD"
                            borderRadius="6px"
                            name="hour"
                            onChange={(e) => HandleOnChange(e, i)}
                          >
                            {generateOptions(1, 12)}
                          </Select>
                          <Select
                            width="40%"
                            color="#8740FB"
                            fontSize="16px"
                            fontWeight="700"
                            bg="#F2E3FD"
                            borderRadius="6px"
                            name="minute"
                            onChange={(e) => HandleOnChange(e, i)}
                          >
                            {generateOptions(0, 59)}
                          </Select>
                          <Select
                            width="30%"
                            color="#8740FB"
                            fontSize="16px"
                            fontWeight="700"
                            bg="#F2E3FD"
                            borderRadius="6px"
                            name="timeday"
                            onChange={(e) => HandleOnChange(e, i)}
                          >
                            <option>AM</option>
                            <option>PM</option>
                          </Select>
                        </Flex>
                      </Box>
                      <Box
                        mt={{ base: "20px", lg: "0px" }}
                        width={{ base: "", lg: "45%" }}
                        height="100%"
                        justifyContent="flex-end"
                        boxShadow="2px 3px 10px 3px lightgray"
                      >
                        <Calendar
                          onChange={HandleDateOnChange}
                          value={date}
                          height="100%"
                        />
                      </Box>
                    </Box>

                    <Text
                      fontSize={"18px"}
                      color={"#252B42"}
                      fontWeight={"500"}
                      mt={"20px"}
                    >
                      Ad Creative*
                    </Text>
                    <Text
                      fontSize={"16px"}
                      color={"#252B42"}
                      fontWeight={"400"}
                      mt={"20px"}
                    >
                      Your photo should be in PNG or JPEG format
                    </Text>
                    <Input
                      type="file"
                      accept="jpg, jpeg, png, mp4"
                      id="adContentMedia"
                      display={"none"}
                      onChange={(e) => console.log(e.target.files[0].name)}
                    ></Input>
                    <label for="adContentMedia">
                      <Box
                        width={"100%"}
                        height={"40px"}
                        mt={"10px"}
                        border={"1px solid #E6E6E6"}
                        bg={"#F9F9F9"}
                        borderRadius={"5px"}
                        display={"flex"}
                        justifyContent={"space-between"}
                        pl={"19px"}
                        pt={"8px"}
                        name="location"
                        value={formData.senderName}
                      >
                        <Text fontSize={"16px"} color={"gray"} width={"80%"}>
                          PNG or JPEG format
                        </Text>
                      </Box>
                    </label>
                    <Button mt={"5px"} bg={"none"} _hover={{ bg: "none" }}>
                      Remove
                    </Button>

                    <Text
                      fontSize={"18px"}
                      color={"#252B42"}
                      fontWeight={"500"}
                      mt={"20px"}
                    >
                      Tweet Content
                    </Text>
                    <Text
                      fontSize={"18px"}
                      color={"#252B42"}
                      fontWeight={"500"}
                      mt={"20px"}
                    >
                      Tweet
                    </Text>
                    <Textarea
                      required
                      type="text"
                      border={"1px solid #E6E6E6"}
                      bg={"#F9F9F9"}
                      borderRadius={"5px"}
                      placeholder="Tweet"
                      name="tweet_text"
                      value={formData.tweet_text}
                      onChange={(e) => HandleOnChange(e, i)}
                    />
                    {allFiledsFilled && formData[i]?.tweet_text === "" && (
                      <Text color="red">Headline is required</Text>
                    )}

                    <Text
                      fontSize={"18px"}
                      color={"#252B42"}
                      fontWeight={"500"}
                      mt={"20px"}
                    >
                      Call to Action
                    </Text>
                    <Textarea
                      required
                      type="text"
                      border={"1px solid #E6E6E6"}
                      bg={"#F9F9F9"}
                      borderRadius={"5px"}
                      placeholder="Call to Action"
                      name="call_to_action"
                      value={formData.call_to_action}
                      onChange={(e) => HandleOnChange(e, i)}
                    />
                    {allFiledsFilled && formData[i]?.call_to_action === "" && (
                      <Text color="red">CallTOAction is required</Text>
                    )}
                  </Box>
                </Box>
                <Box
                  width={"100%"}
                  p={"0px 25px"}
                  display={"flex"}
                  flexDir={{
                    base: "column",
                    sm: "column",
                    md: "column",
                    lg: "row",
                  }}
                  justifyContent={"space-between"}
                >
                  <Button
                    width={{ base: "100%", sm: "100%", md: "100%", lg: "49%" }}
                    onClick={() => DeleteCampaign(i)}
                  >
                    Delete Campaign
                  </Button>
                  <Button
                    width={{ base: "100%", sm: "100%", md: "100%", lg: "49%" }}
                    mt={{ base: "10px", sm: "10px", md: "10px", lg: "0px" }}
                    onClick={AddNewCampaignForm}
                  >
                    Add New Campaign
                  </Button>
                </Box>
              </>
            );
          })}
          <Box
            width={"100%"}
            p={"10px 25px"}
            display={"flex"}
            justifyContent={"center"}
          >
            <Button
              width={{ base: "100%", sm: "100%", md: "100%", lg: "32%" }}
              bg={"red.500"}
              color={"white"}
              _hover={{ bg: "red.300", color: "white" }}
              onClick={SaveCampaignData}
            >
              Save Campaign
            </Button>
          </Box>
        </Box>
      </Box>

      {showPreview && (
        <PreviewTemplate
          showPreview={showPreview}
          setShowPreview={setShowPreview}
          campaignType={"Twitter"}
          formData={formData}
        />
      )}
      <ToastContainer/> 
    </>
  );
}
