import React, { useState } from 'react';
import { Box, Text, Button, Collapse } from '@chakra-ui/react';

const SecurityQuestions = () => {
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  const questions = [
    {
      question: 'How do I secure my account?',
      answer:
        'To secure your account, use a strong password and enable two-factor authentication.',
    },
    {
      question: 'What should I do if I suspect my account is compromised?',
      answer:
        'If you suspect your account is compromised, change your password immediately and contact support.',
    },
  ];

  return (
    <Box>
      <Text fontSize="lg" fontWeight="medium" color="#000000">
        Security
      </Text>

      {questions.map((q, index) => (
        <Box key={index} mb={4}>
          <Text
            fontSize="16px"
            fontWeight="bold"
            cursor="pointer"
            onClick={() => setSelectedQuestion(index)}
            color={selectedQuestion === index ? '#B83D9B' : '#6B46C1'}
          >
            {q.question}
          </Text>
          {selectedQuestion === index && (
            <Text fontSize="16px" fontWeight={500}>
              {q.answer}
            </Text>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default SecurityQuestions;
