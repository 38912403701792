import React, { useState, useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import Filter from './Filter';
import './CRMPage.css';

function CRMPage() {
  const [page, setPage] = useState('dashboard');
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const lineChartRef = useRef(null);
  const barChartRef = useRef(null);
  const pieChartRef = useRef(null); 
  const doughnutChartRef = useRef(null); 
  const lineChartInstance = useRef(null);
  const barChartInstance = useRef(null);
  const pieChartInstance = useRef(null);
  const doughnutChartInstance = useRef(null);
  const [stats, setStats] = useState({
    leads: 150,
    deals: 75,
    tickets: 200
  });

  const [filteredStats, setFilteredStats] = useState(stats);

  useEffect(() => {
    const lineData = {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      datasets: [{
        label: 'Sales Performance',
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
        data: [65, 59, 80, 81, 56, 55, 40],
      }]
    };

    const barData = {
      labels: ['Product A', 'Product B', 'Product C', 'Product D', 'Product E'],
      datasets: [{
        label: 'Sales by Product',
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
        ],
        borderWidth: 1,
        data: [150, 200, 180, 250, 210],
      }]
    };

    const pieData = {
      labels: ['Leads', 'Deals', 'Tickets'],
      datasets: [{
        label: 'CRM Distribution',
        backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(255, 206, 86, 0.2)'],
        borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 206, 86, 1)'],
        borderWidth: 1,
        data: [filteredStats.leads, filteredStats.deals, filteredStats.tickets],
      }]
    };

    const doughnutData = {
      labels: ['Product A', 'Product B', 'Product C', 'Product D', 'Product E'],
      datasets: [{
        label: 'Product Sales Distribution',
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
        ],
        borderWidth: 1,
        data: [150, 200, 180, 250, 210],
      }]
    };

    const initializeCharts = () => {
      if (lineChartRef.current && barChartRef.current && pieChartRef.current && doughnutChartRef.current) {
        if (lineChartInstance.current) lineChartInstance.current.destroy();
        if (barChartInstance.current) barChartInstance.current.destroy();
        if (pieChartInstance.current) pieChartInstance.current.destroy();
        if (doughnutChartInstance.current) doughnutChartInstance.current.destroy();

        const lineCtx = lineChartRef.current.getContext('2d');
        lineChartInstance.current = new Chart(lineCtx, {
          type: 'line',
          data: lineData,
          options: {
            scales: {
              y: {
                beginAtZero: true
              }
            }
          }
        });

        const barCtx = barChartRef.current.getContext('2d');
        barChartInstance.current = new Chart(barCtx, {
          type: 'bar',
          data: barData,
          options: {
            scales: {
              y: {
                beginAtZero: true
              }
            }
          }
        });

        const pieCtx = pieChartRef.current.getContext('2d');
        pieChartInstance.current = new Chart(pieCtx, {
          type: 'pie',
          data: pieData,
          options: {
            responsive: true,
          }
        });

        const doughnutCtx = doughnutChartRef.current.getContext('2d');
        doughnutChartInstance.current = new Chart(doughnutCtx, {
          type: 'doughnut',
          data: doughnutData,
          options: {
            responsive: true,
          }
        });
      }
    };

    initializeCharts();

    return () => {
      if (lineChartInstance.current) lineChartInstance.current.destroy();
      if (barChartInstance.current) barChartInstance.current.destroy();
      if (pieChartInstance.current) pieChartInstance.current.destroy();
      if (doughnutChartInstance.current) doughnutChartInstance.current.destroy();
    };
  }, [filteredStats]);

  const handleSidebarItemClick = (pageName) => {
    setPage(pageName);
  };

  const handleFilter = (criteria) => {
    setFilteredStats({
      leads: criteria.leads ? parseInt(criteria.leads) : stats.leads,
      deals: criteria.deals ? parseInt(criteria.deals) : stats.deals,
      tickets: criteria.tickets ? parseInt(criteria.tickets) : stats.tickets
    });
  };

  const handleExport = () => {
    const csvData = [
      ['Category', 'Value'],
      ['Leads', filteredStats.leads],
      ['Deals', filteredStats.deals],
      ['Tickets', filteredStats.tickets]
    ];

    let csvContent = "data:text/csv;charset=utf-8,";

    csvData.forEach(row => {
      let rowContent = row.join(",");
      csvContent += rowContent + "\r\n";
    });

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "crm_data.csv");
    document.body.appendChild(link);
    link.click();
  };

  return (
    <div className="app">
      <div className={`sidebar ${isSidebarCollapsed ? 'collapsed' : ''}`}>
        <div className="sidebar-item" onClick={() => setIsSidebarCollapsed(!isSidebarCollapsed)}>
          <span>{isSidebarCollapsed ? '>' : '<'}</span>
        </div>
        <div className="sidebar-item" onClick={() => handleSidebarItemClick('dashboard')}>
          <span>Dashboard</span>
        </div>
        <div className="sidebar-item" onClick={() => handleSidebarItemClick('sales')}>
          <span>Sales</span>
        </div>
        <div className="sidebar-item" onClick={() => handleSidebarItemClick('marketing')}>
          <span>Marketing</span>
        </div>
        <div className="sidebar-item" onClick={() => handleSidebarItemClick('support')}>
          <span>Support</span>
        </div>
        <div className="sidebar-item" onClick={() => handleSidebarItemClick('reports')}>
          <span>Reports</span>
        </div>
        <div className="sidebar-item" onClick={() => handleSidebarItemClick('integrations')}>
          <span>Integrations</span>
        </div>
      </div>
      <div className="content">
        {page === 'dashboard' && (
          <div>
            <h2>Dashboard</h2>
            <button className="export-button" onClick={handleExport}>Export Data</button>
            <Filter onFilter={handleFilter} />
            <div className="chart-container">
              <div className="chart-box">
                <canvas ref={lineChartRef} />
              </div>
              <div className="chart-box">
                <canvas ref={barChartRef} />
              </div>
              <div className="chart-box">
                <canvas ref={pieChartRef} />
              </div>
              <div className="chart-box">
                <canvas ref={doughnutChartRef} />
              </div>
            </div>
          </div>
        )}
        {page === 'sales' && <h2>Sales Page</h2>}
        {page === 'marketing' && <h2>Marketing Page</h2>}
        {page === 'support' && <h2>Support Page</h2>}
        {page === 'reports' && (
          <div>
            <h2>Reports</h2>
            <div className="reports-container">
              <div className="report-box">
                <div className="report-header">Monthly Sales Report</div>
                <table className="report-table">
                  <thead>
                    <tr>
                      <th>Month</th>
                      <th>Sales</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>January</td>
                      <td>$10,000</td>
                    </tr>
                    <tr>
                      <td>February</td>
                      <td>$12,000</td>
                    </tr>
                    <tr>
                      <td>March</td>
                      <td>$15,000</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="report-box">
                <div className="report-header">Customer Acquisition Report</div>
                <table className="report-table">
                  <thead>
                    <tr>
                      <th>Month</th>
                      <th>New Customers</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>January</td>
                      <td>50</td>
                    </tr>
                    <tr>
                      <td>February</td>
                      <td>70</td>
                    </tr>
                    <tr>
                      <td>March</td>
                      <td>90</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        {page === 'integrations' && (
          <div>
            <h2>Integrations</h2>
            <div className="integrations-container">
              <div className="integration-box">
                <div className="integration-header">Google Analytics</div>
                <p className="integration-description">Integrate Google Analytics to track your web traffic and performance.</p>
              </div>
              <div className="integration-box">
                <div className="integration-header">Salesforce</div>
                <p className="integration-description">Connect Salesforce to sync your CRM data and improve your sales process.</p>
              </div>
              <div className="integration-box">
                <div className="integration-header">Mailchimp</div>
                <p className="integration-description">Integrate Mailchimp for email marketing campaigns and audience management.</p>
              </div>
              <div className="integration-box">
                <div className="integration-header">Slack</div>
                <p className="integration-description">Connect Slack for team communication and collaboration.</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default CRMPage;
