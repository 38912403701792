import React, { useEffect, useState } from 'react'
import {Box,Text,Input,Button,Spinner} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { BsShieldLockFill } from "react-icons/bs";
import { CallAPI } from '../../Services/service';
import endpoints from '../../Services/endpoint';
import { toast } from 'react-toastify';

export default function ForgetPassword() {
    const navigate = useNavigate()
    const {email} = useParams()
    const [OTP, setOTP] = useState('') 
    const [newPass, setNewPass] = useState('') 
    const [showResendButton, setShowResendButton]= useState(false)
    const [timer, setTimer] = useState(120)
    const [isLoading, setIsLoading] = useState(false)
    const [isResendLoading, setIsResendLoading] = useState(false)

    useEffect(()=>{
        if(timer>1){
            setTimeout(()=>{
                setTimer(prev=> prev-1)
            },1000)
        }else{
            setShowResendButton(true)
        }          
    },[timer])

    const ResetPassword=()=>{
        setIsLoading(true)
        try {
            CallAPI(endpoints.resetpassword,{
                email:email,
                newPassword:newPass,
                otp:OTP
            }).then((res)=>{
               if(res?.status?.code===200){
                setIsLoading(false)
                navigate("/signin")
               }
            })
        } catch (error) {
            console.log(error)
        }
    }

    const ResendOTP = ()=>{
        setIsResendLoading(true)
        try {
            CallAPI(endpoints.sendotp,{
            email:email
            }).then((res)=>{
            if(res?.status?.code===200){
                setIsResendLoading(false)
                setShowResendButton(false)
                setTimer(120)
                toast.success("OTP Send Successful. Check your email")
            }
            })
        } catch (error) {
            console.log("Error>>",error)
        }
    }
  
  return (
        <Box width="100%" height={"100vh"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <Box display={"flex"} flexDir={"column"} justifyContent={"center"} alignItems={"center"} p={"20px"} border={"1px solid lightgray"} borderRadius={"6px"}>
            <BsShieldLockFill size={"100"} color='red'/>
            <Text fontSize={"30px"} fontWeight={"700"} color={"#000000"}>Reset Your Password</Text>
            <Text fontSize={"16px"} fontWeight={"500"} color={"#000000"}>Check your email to get the OTP</Text>
            <Input type='text' placeholder={"New Password"}  value={newPass} onChange={(e)=>setNewPass(e.target.value)}/>
            <Input type='number' placeholder={"OTP"} mt={"10px"} value={OTP} onChange={(e)=>setOTP(e.target.value)} />
            {(OTP.length>=6 && newPass.length>=8)?
                <Button mt={"10px"} w={"100%"} bg={"red.400"} color={"#FFFFFF"} _hover={{bg:"red.500"}} onClick={ResetPassword}>   
                    {isLoading ? <Spinner size="sm" /> : 'Submit'}
                </Button>
                :
                <Button mt={"10px"} w={"100%"} color={"#FFFFFF"} _hover={{bg:""}}>Submit</Button>
            }
            {(showResendButton)?
                <Button mt={"10px"} w={"100%"} bg={"red.400"} color={"#FFFFFF"} _hover={{bg:"red.500"}} onClick={ResendOTP}>                    
                    {isResendLoading ? <Spinner size="sm" /> : 'Resend OTP'}
                </Button>
                :
                <Button mt={"10px"} w={"100%"} bg={"red.400"} color={"#FFFFFF"} _hover={{bg:"red.500"}}>{timer} Seconds</Button>
            }
            <Text fontSize={"16px"} fontWeight={"500"} color={"blue"} cursor={"pointer"} textDecoration={"underline"} mt={"20px"} onClick={()=>navigate('/signup')}>Back to Login</Text>
          </Box>
       </Box>
  )
}
