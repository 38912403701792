import React,{useEffect,useState} from 'react'
import { MdEmail } from "react-icons/md";
import {Box,Text,Input} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { SERVER_URL } from '../../Services/service';
import axios from 'axios';
import { CallAPI } from '../../Services/service';
import endpoints from '../../Services/endpoint';


export default function EmailVerification() {
  const navigate = useNavigate()
  const {token} = useParams()
  const [message, setMessage] = useState()
  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const response = await axios.post(
          `${SERVER_URL}/api/user/verify-email?token=${token}`
        );

        if (response.status === 200) {
          // Encrypt and store the user data in session storage
          setMessage(
            "Congratulations! Your email has been successfully verified."
          )
          localStorage.setItem("userData",JSON.stringify(response?.data?.data))
          setTimeout(()=>{
            navigate('/dashboard')
          },1000)
        } else {
          setMessage("Email verification failed. Please try again.");
        }
      } catch (error) {
        console.error("Email verification failed. Please try again.", error);
        setMessage("Email verification in Pending");
      }
    };
    if (token) {
      verifyEmail();
    }
  }, [token]);

  return (
    <>
       <Box width="100%" height={"100vh"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <Box display={"flex"} flexDir={"column"} justifyContent={"center"} alignItems={"center"} p={"20px"} border={"1px solid lightgray"} borderRadius={"6px"}>
            <MdEmail size={"100"} color='red'/>
            <Text fontSize={"30px"} fontWeight={"700"} color={"#000000"}>Email Verification</Text>
            <Text fontSize={"16px"} fontWeight={"500"} color={"#000000"}>Check your email and verify your account</Text>
            <Text>{message}</Text>            
            <Text fontSize={"16px"} fontWeight={"500"} color={"blue"} cursor={"pointer"} textDecoration={"underline"} mt={"20px"} onClick={()=>navigate('/signup')}>Signup using another email</Text>
          </Box>
       </Box>
    </>
  )
}
