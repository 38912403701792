import React, { useState } from 'react';
import './Filter.css'

function Filter({ onFilter }) {
  const [criteria, setCriteria] = useState({
    leads: '',
    deals: '',
    tickets: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCriteria((prev) => ({ ...prev, [name]: value }));
  };

  const handleFilterClick = () => {
    onFilter(criteria);
  };

  return (
    <div className="filter-container">
      <input
        type="number"
        name="leads"
        placeholder="Leads"
        value={criteria.leads}
        onChange={handleInputChange}
      />
      <input
        type="number"
        name="deals"
        placeholder="Deals"
        value={criteria.deals}
        onChange={handleInputChange}
      />
      <input
        type="number"
        name="tickets"
        placeholder="Tickets"
        value={criteria.tickets}
        onChange={handleInputChange}
      />
      <button className="filter-button" onClick={handleFilterClick}>
        Apply Filter
      </button>
    </div>
  );
}

export default Filter;
