import React from 'react'
import {
    Box , 
    Text,
    Button,
    Image,
} from '@chakra-ui/react'
import { transform } from 'framer-motion'
export default function PreviewTemplate(props) {
  const {
    setShowPreview,
    campaignType,
    formData
  }=props
  return (
    <Box width={"100%"} height={"100%"} bg={"rgba(0,0,0,0.8)"} position={"absolute"}  top={"0"} zIndex={"1"} display={"flex"} justifyContent={"center"}>
      <Box width={{base:"95%", sm:"95%", md:"95%", lg:"50%"}} height={"950px"} borderRadius={"5px"} bg={"white"} mt={"50px"}  p={"25px"} overflowY={"auto"}>
        <Box w={"100%"} display={"flex"} justifyContent={"space-between"}>
          <Text color={"red.400"} fontSize={"28px"} fontWeight="700">{campaignType} Campaign Preview</Text>
          <Text><Button onClick={()=>setShowPreview(false)}>Close</Button></Text>
        </Box>
        {
          formData.map((item)=>{
            return(
              <>
                {  
                  (campaignType==="Whatsapp") && 
                  <Box>
                    <Image src='https://thumbs.dreamstime.com/b/consultant-presenting-tag-cloud-information-technology-213591032.jpg' width={"100%"} height={"300px"}/>
                  </Box>
                }
                <Box w={"100%"}>
                  <Text color={"#000000"} fontSize={"20px"} fontWeight="600">Campaign Details</Text>
                  <Text color={"gray"} fontSize={"18px"} fontWeight="600">{item.campaignName}</Text>
                  <Text color={"gray"} fontSize={"18px"} fontWeight="600">{item.campaignObjective}</Text>
                </Box>
                {
                  (campaignType==="Email") &&
                  <> 
                  <Box w={"100%"}>
                    <Text color={"#000000"} fontSize={"20px"} fontWeight="600">Email Details</Text>
                    <Text color={"gray"} fontSize={"18px"} fontWeight="600">{item.senderName}</Text>
                    <Text color={"gray"} fontSize={"18px"} fontWeight="600">{item.senderEmail}</Text>
                    <Text color={"gray"} fontSize={"18px"} fontWeight="600">{item.subjectLine}</Text>
                  </Box>

                  <Box w={"100%"}>
                    <Text color={"#000000"} fontSize={"20px"} fontWeight="600">Email Content</Text>
                    <Text color={"gray"} fontSize={"18px"} fontWeight="600">{item.headline}</Text>
                    <Text color={"gray"} fontSize={"18px"} fontWeight="600">{item.bodytext}</Text>
                  </Box>
                  </>
                }
                {
                  (campaignType==="Whatsapp") &&
                  <> 
                  <Box w={"100%"}>
                    <Text color={"#000000"} fontSize={"20px"} fontWeight="600">Whatsapp Content</Text>
                    <Text color={"gray"} fontSize={"18px"} fontWeight="600">{item.addContentTextMessage}</Text>
                    <Text color={"gray"} fontSize={"18px"} fontWeight="600">{item.offerDetailTextMessage}</Text>
                  </Box>
                  </>
                }
                <Box w={"100%"}>
                  <Text color={"#000000"} fontSize={"20px"} fontWeight="600">Schedule</Text>
                  <Text color={"gray"} fontSize={"18px"} fontWeight="600">{item.startDay}-{item.startMonth}-{item.startYear}</Text>
                  <Text color={"gray"} fontSize={"18px"} fontWeight="600">{item.endDay}-{item.endMonth}-{item.endYear}</Text>
                  <Text color={"gray"} fontSize={"18px"} fontWeight="600">{item.timeHour}:{item.timeMinute} {item.timeDay}</Text>
                </Box>
                <Box w={"100%"}>
                  <Text color={"#000000"} fontSize={"20px"} fontWeight="600">Campaign Budget</Text>
                  <Text color={"gray"} fontSize={"18px"} fontWeight="600">{item.budgetType}</Text>
                  <Text color={"gray"} fontSize={"18px"} fontWeight="600">{item.price}</Text>                 
                </Box>
              </>
            )
          })
        }
      </Box>
    </Box>
  )
}
