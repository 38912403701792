import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowRight } from "react-icons/fa";
import { Box, Text, Button, Image, Switch, Flex , 
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import { FaFacebook } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import Drag_icon from './icons/Drag_icon.png'
import Pin_icon from './icons/Pin_icon.png'
import option_icon from './icons/option_icon.png'
import Style from './Campaign.module.css'
import { BsThreeDotsVertical } from "react-icons/bs";
import { GrInstagram } from "react-icons/gr";
import { SiGmail } from "react-icons/si";
import { FaXTwitter, FaLinkedinIn } from "react-icons/fa6";




const CampaignCard=({ id, imgSrc, campaign_name, campaign_type, notFunctional, Campaign_objective, username, testing_type, setAlphaTesting, setBetaTesting})=>{

  const [isChecked, setIsChecked] = useState(true);
  const navigate = useNavigate();

  const [testingType, setTestingType] = useState(testing_type)

  const handleToggle = () => {
    setIsChecked(!isChecked);
  };

  const handleSeeMore = () => {
    navigate(`/campaigns/${id}`);
  };

  useEffect(()=>{
    if(testing_type===""){
      setTestingType("")
    }
    if(testing_type==="alpha"){
      setTestingType("alpha")
    }
    if(testing_type==="beta"){
      setTestingType("beta")
    }
  },[testing_type])

  return (
    <>
      <Box width={"100%"} h={"auto"} bg={"white"} borderRadius={"6px"} border={"1px solid #E7E7E7"} mt={"20px"}>
        <Box display="flex"  alignItems={"center"} width={"100%"} bg={"#EBEBEB"} p={"10px 20px"}>
          <Image src={Drag_icon} width={"10px"} height={"15px"} cursor={"pointer"}/>
          <Text className={Style?.textBottomMargin} display={"flex"} ml={"7%"} w={"86%"}>
            <Image className={Style?.textBottomMargin} src={imgSrc} width={"35px"} height={"35px"} borderRadius={"50%"} />
            <Text className={Style?.textBottomMargin}  fontSize={"20px"} ml={"15px"} fontWeight={"600"} color={"#000000"}>{username}</Text>
            <Text className={Style?.textBottomMargin}  fontSize={"16px"} fontWeight={"500"} pl="10px" mt="3px" color={"#8E8B8B"} textAlign={"justify"}>25-August, 12:10 PM to 05-September, 01:00 PM</Text>
            {(testingType==="alpha") && <Text className={Style?.textBottomMargin} fontWeight={700} color={"#000000"} ml={"10px"} fontSize={"20px"}>A</Text>}
            {(testingType==="beta") && <Text className={Style?.textBottomMargin} fontWeight={700} color={"#000000"} ml={"10px"} fontSize={"20px"}>B</Text>}
          </Text>
          <Box display={"flex"}>
            { (notFunctional!==false) && 
              <>
                <Image src={Pin_icon} width={"15px"} height={"20px"} cursor={"pointer"} mt={"10px"}/> 
                <Menu>
                  <MenuButton as={Button} bg={"#EBEBEB"} _hover={{bg:"#EBEBEB"}}>
                    <BsThreeDotsVertical />
                  </MenuButton>
                  <MenuList w={"20px"}>
                    <MenuItem onClick={()=>setAlphaTesting(id, campaign_type)}>Alpha Testing</MenuItem>
                    <MenuItem onClick={()=>setBetaTesting(id, campaign_type)}>Beta Testing</MenuItem>
                    {/* <MenuItem>Publish Campaign</MenuItem> */}
                    <MenuItem onClick={()=>navigate(`/CampaignDetails/${id}/${campaign_type}`)}>See Details</MenuItem>
                  </MenuList>
                </Menu>
              </>
            }
          </Box>
        </Box>
        <Box display={"flex"} p={"10px 20px"}>
          <Switch size='md' disabled/>
          <Button bg={"red.100"} borderRadius={"15px"} ml={"5%"}  _hover={{bg:"red.100"}}>
          {
            (campaign_type==="whatsapp") && 
            <IoLogoWhatsapp size={"20"} color='#07D943'/>
          }
          {
            (campaign_type==="facebook") && 
            <FaFacebook size={"20"} color='blue'/>
          }
          {
            (campaign_type==="instagram") && 
            <GrInstagram size={"20"} color='red'/>
          }
          {
            (campaign_type==="email") && 
            <SiGmail  size={"20"} color='orange'/>
          }
          {
            (campaign_type==="twitter") && 
            <FaXTwitter  size={"20"} color='black'/>
          }
          {
            (campaign_type==="linkedin") && 
            <FaLinkedinIn size={"20"} color='purple'/>
          }
          </Button>
          <Box width={"20%"}>
            <Text className={Style?.textBottomMargin}  fontSize={"18px"} pl={"15px"} fontWeight={"600"} color={"#000000"}>{campaign_name}</Text>
            <Text className={Style?.textBottomMargin}  fontSize={"16px"} fontWeight={"400"} pl="15px" mt="3px" color={"#8E8B8B"} textAlign={"justify"}>{Campaign_objective}</Text>
          </Box>
          <Box display={"flex"} ml={"25px"} mt={"0px"}>
            <Box ml={"30px"} display={"flex"} flexDir={"column"} alignItems={"center"}>
              <Text className={Style?.textBottomMargin} w={"60px"} h={"60px"} textAlign={"center"} bg={"red.100"} fontSize={"18px"} p={"15px"} borderRadius={"60px"} fontWeight={"600"} color={"#000000"}>7</Text>
              <Text className={Style?.textBottomMargin} fontSize={"18px"} fontWeight={"500"} color={"#000000"}>Delivered</Text>
            </Box>
            <Box ml={"30px"} display={"flex"} flexDir={"column"} alignItems={"center"}>
              <Text className={Style?.textBottomMargin} w={"60px"} h={"60px"} textAlign={"center"} bg={"red.100"} fontSize={"18px"} p={"15px"} borderRadius={"60px"} fontWeight={"600"} color={"#000000"}>5</Text>
              <Text className={Style?.textBottomMargin} fontSize={"18px"} fontWeight={"500"} color={"#000000"}>Bounced</Text>
            </Box>
            <Box ml={"30px"} display={"flex"} flexDir={"column"} alignItems={"center"}>
              <Text className={Style?.textBottomMargin} w={"60px"} h={"60px"} textAlign={"center"} bg={"red.100"} fontSize={"18px"} p={"15px"} borderRadius={"60px"} fontWeight={"600"} color={"#000000"}>4</Text>
              <Text className={Style?.textBottomMargin} fontSize={"18px"} fontWeight={"500"} color={"#000000"}>Blocked</Text>
            </Box>
            <Box ml={"30px"} display={"flex"} flexDir={"column"} alignItems={"center"}>
              <Text className={Style?.textBottomMargin} w={"60px"} h={"60px"} textAlign={"center"} bg={"red.100"} fontSize={"18px"} p={"15px"} borderRadius={"60px"} fontWeight={"600"} color={"#000000"}>9</Text>
              <Text className={Style?.textBottomMargin} fontSize={"18px"} fontWeight={"500"} color={"#000000"}>Reply</Text>
            </Box>          
          </Box>
        </Box>
      </Box>
    </>
)}

export default CampaignCard

