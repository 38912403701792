import React from 'react';
import Chats from './Chats/Chats';
import ProfileChat from './Profile/ProfileChat';
import { Box, Flex } from '@chakra-ui/react';
import Sidebar from '../Sidebar/Sidebar';
import MessageContainer from './Messages/MessageContainer';
const CommunityGroup = () => {
  return (
    <Box flex="1" m={0} p={0}>
      <Flex borderColor="#CCCBCB">
        <Chats />
        <MessageContainer />
        <ProfileChat />
        {/* <MessageContainer/> */}
      </Flex>
    </Box>
  );
};

export default CommunityGroup;
