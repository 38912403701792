import React, { useState } from 'react';
import './FeaturedApp.css';
import {
  FaUserCircle,
  FaBullhorn,
  FaChartLine,
  FaHeadset,
  FaChartPie,
  FaUserPlus,
} from "react-icons/fa";
import crmImage from '../image/crm.jpg';
import cserviceImage from '../image/cservice.jpg';
import leadManagementImage from '../image/Lead Management.jpg';
import marketImage from '../image/market.jpg';
import salesImage from '../image/S1.jpg';
import M2Image from '../image/sales.jpg';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { Link } from 'react-router-dom';

const FeaturedApp = () => {
  const [activeHeading, setActiveHeading] = useState(null);

  const headings = [
    { id: 1, title: 'CRM' },
    { id: 2, title: 'Marketing Automation' },
    { id: 3, title: 'Sales Tracking' },
    { id: 4, title: 'Customer Service' },
    { id: 5, title: 'Analytics' },
    { id: 6, title: 'Lead Management' },
  ];

  const icons = {
    CRM: <FaUserCircle />,
    'Marketing Automation': <FaBullhorn />,
    'Sales Tracking': <FaChartLine />,
    'Customer Service': <FaHeadset />,
    "Analytics": <FaChartPie />,
    'Lead Management': <FaUserPlus />,
  };

  const cards = [
    { id: 1, headingId: 1, icon: icons.CRM, title: 'CRM Basics', details: 'Learn the fundamentals of CRM.', image: crmImage },
    { id: 2, headingId: 1, icon: icons.CRM, title: 'Advanced CRM Techniques', details: 'Deep dive into advanced CRM strategies.',  image: crmImage },
    { id: 3, headingId: 1, icon: icons.CRM, title: 'CRM Tools', details: 'Explore essential CRM tools.',  image: crmImage  },
    { id: 4, headingId: 1, icon: icons.CRM, title: 'CRM Audit', details: 'Conduct a comprehensive CRM audit.',  image: crmImage  },
    { id: 5, headingId: 1, icon: icons.CRM, title: 'CRM Metrics', details: 'Understand key CRM metrics.',  image: crmImage  },
    { id: 6, headingId: 2, icon: icons['Marketing Automation'], title: 'Marketing Automation Strategy', details: 'How to create a marketing automation strategy.', image: marketImage },
    { id: 7, headingId: 2, icon: icons['Marketing Automation'], title: 'Marketing Automation Tools', details: 'Tools for marketing automation.',  image: marketImage },
    { id: 8, headingId: 2, icon: icons['Marketing Automation'], title: 'Lead Nurturing', details: 'Nurture your leads effectively.',  image: marketImage },
    { id: 9, headingId: 2, icon: icons['Marketing Automation'], title: 'Campaign Management', details: 'Manage your campaigns efficiently.',  image: marketImage },
    { id: 10, headingId: 2, icon: icons['Marketing Automation'], title: 'Performance Tracking', details: 'Track the performance of your campaigns.',  image: marketImage },
    { id: 11, headingId: 3, icon: icons['Sales Tracking'], title: 'Sales Pipeline Management', details: 'Manage your sales pipeline effectively.', image: salesImage },
    { id: 12, headingId: 3, icon: icons['Sales Tracking'], title: 'Sales Forecasting', details: 'Accurate sales forecasting techniques.',image: salesImage },
    { id: 13, headingId: 3, icon: icons['Sales Tracking'], title: 'Sales Analytics', details: 'Analyze your sales data.', image: salesImage },
    { id: 14, headingId: 3, icon: icons['Sales Tracking'], title: 'Sales Performance Metrics', details: 'Key metrics for sales performance.', image: salesImage },
    { id: 15, headingId: 3, icon: icons['Sales Tracking'], title: 'Sales Team Management', details: 'Manage your sales team effectively.', image: salesImage },
    { id: 16, headingId: 4, icon: icons['Customer Service'], title: 'Customer Service Basics', details: 'Learn the fundamentals of customer service.', image: cserviceImage },
    { id: 17, headingId: 4, icon: icons['Customer Service'], title: 'Advanced Customer Service Techniques', details: 'Advanced strategies for customer service.',  image: cserviceImage  },
    { id: 18, headingId: 4, icon: icons['Customer Service'], title: 'Customer Service Tools', details: 'Explore essential customer service tools.',  image: cserviceImage  },
    { id: 19, headingId: 4, icon: icons['Customer Service'], title: 'Customer Service Audit', details: 'Conduct a comprehensive customer service audit.',  image: cserviceImage  },
    { id: 20, headingId: 4, icon: icons['Customer Service'], title: 'Customer Service Metrics', details: 'Understand key customer service metrics.',  image: cserviceImage  },
    { id: 21, headingId: 5, icon: icons.Analytics, title: 'Analytics Basics', details: 'Get started with analytics.', image: M2Image },
    { id: 22, headingId: 5, icon: icons.Analytics, title: 'Advanced Analytics', details: 'Advanced techniques for analytics.', image: M2Image },
    { id: 23, headingId: 5, icon: icons.Analytics, title: 'Analytics Tools', details: 'Explore essential analytics tools.', image: M2Image },
    { id: 24, headingId: 5, icon: icons.Analytics, title: 'Analytics Strategies', details: 'Develop effective analytics strategies.', image: M2Image },
    { id: 25, headingId: 5, icon: icons.Analytics, title: 'Analytics Metrics', details: 'Key metrics for analytics.', image: M2Image },
    { id: 26, headingId: 6, icon: icons['Lead Management'], title: 'Lead Management Basics', details: 'Learn the fundamentals of lead management.', image: leadManagementImage },
    { id: 27, headingId: 6, icon: icons['Lead Management'], title: 'Advanced Lead Management Techniques', details: 'Deep dive into advanced lead management strategies.', image: leadManagementImage },
    { id: 28, headingId: 6, icon: icons['Lead Management'], title: 'Lead Management Tools', details: 'Explore essential lead management tools.', image: leadManagementImage },
    { id: 29, headingId: 6, icon: icons['Lead Management'], title: 'Lead Management Audit', details: 'Conduct a comprehensive lead management audit.', image: leadManagementImage },
    { id: 30, headingId: 6, icon: icons['Lead Management'], title: 'Lead Management Metrics', details: 'Understand key lead management metrics.',  image: leadManagementImage },
  ];

  return (
    <div>
    <div className="app">
      <div className="sidebar">
        {headings.map(heading => (
          <div
            key={heading.id}
            className="sidebar-item"
            onMouseEnter={() => setActiveHeading(heading.id)}
          >
            {heading.title}
          </div>
        ))}
      </div>
      <div className="cards-container">
        {cards
          .filter(card => card.headingId === activeHeading)
          .slice(0, 5)
          .map(card => (
            <div key={card.id} className="card">
              <div className="card-icon">{card.icon}</div>
              <img src={card.image} alt={card.title} className="card-image" />
              <h3>{card.title}</h3>
              <p>{card.details}</p>
              <Link to={"/CRMPage"}>
              <button className="try-now-button">
                Try Now <span className="arrow">→</span>
              </button>
              </Link>
            </div>
          ))}
      </div>
    </div>
    <Footer/>
    </div>
  );
};

export default FeaturedApp;
