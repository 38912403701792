import React, { useState } from 'react';
import { 
  Box, 
  Button, 
  FormControl, 
  FormLabel, 
  Input, 
  Select, 
  VStack, 
  HStack, 
  Avatar, 
  Tag, 
  TagLabel, 
  TagCloseButton, 
  useToast,
  Text,
  Flex
} from '@chakra-ui/react';

function Contacts() {
  const [contacts, setContacts] = useState([
    { id: 1, name: 'Mohit Sharma', email: 'Mohit.Sharma@example.com', phone: '9999999999', groups: ['Customers'] },
    { id: 2, name: 'Sreenu Reddy', email: 'Sreenu.Reddy@example.com', phone: '9876543210', groups: ['Leads', 'Customers'] },
  ]);
  const [searchQuery, setSearchQuery] = useState('');
  const [groups, setGroups] = useState(['Customers', 'Leads']);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [newContactName, setNewContactName] = useState('');
  const [newContactEmail, setNewContactEmail] = useState('');
  const [newContactPhone, setNewContactPhone] = useState('');
  const [newContactGroups, setNewContactGroups] = useState([]);
  const toast = useToast();

  const addGroup = (newGroup) => {
    if (!groups.includes(newGroup)) {
      setGroups([...groups, newGroup]);
    }
  };

  const deleteGroup = (groupToDelete) => {
    const updatedGroups = groups.filter(group => group !== groupToDelete);
    setGroups(updatedGroups);
    const updatedContacts = contacts.map(contact => ({
      ...contact,
      groups: contact.groups.filter(group => group !== groupToDelete)
    }));
    setContacts(updatedContacts);
    if (selectedGroup === groupToDelete) {
      setSelectedGroup('');
    }
  };

  const assignContactToGroup = (contactId, group) => {
    const updatedContacts = contacts.map(contact => {
      if (contact.id === contactId) {
        return {
          ...contact,
          groups: [...contact.groups, group]
        };
      }
      return contact;
    });
    setContacts(updatedContacts);
  };

  const removeContactFromGroup = (contactId, group) => {
    const updatedContacts = contacts.map(contact => {
      if (contact.id === contactId) {
        return {
          ...contact,
          groups: contact.groups.filter(g => g !== group)
        };
      }
      return contact;
    });
    setContacts(updatedContacts);
  };

  const filteredContacts = contacts.filter(contact =>
    (contact.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      contact.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
      contact.phone.toLowerCase().includes(searchQuery.toLowerCase())) &&
    (selectedGroup === '' || contact.groups.includes(selectedGroup))
  );

  const handleDeleteContact = (id) => {
    setContacts(contacts.filter(contact => contact.id !== id));
    toast({
      title: "Contact deleted.",
      description: "The contact has been removed.",
      status: "info",
      duration: 3000,
      isClosable: true,
    });
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const clearSearch = () => {
    setSearchQuery('');
  };

  const handleGroupFilterChange = (group) => {
    setSelectedGroup(group);
  };

  const handleNewContactSubmit = (event) => {
    event.preventDefault();

    const newContactId = contacts.length > 0 ? contacts[contacts.length - 1].id + 1 : 1;

    const newContact = {
      id: newContactId,
      name: newContactName,
      email: newContactEmail,
      phone: newContactPhone,
      groups: newContactGroups
    };

    setContacts([...contacts, newContact]);

    setNewContactName('');
    setNewContactEmail('');
    setNewContactPhone('');
    setNewContactGroups([]);

    toast({
      title: "Contact added.",
      description: "A new contact has been added.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  const handleNewContactNameChange = (event) => {
    setNewContactName(event.target.value);
  };

  const handleNewContactEmailChange = (event) => {
    setNewContactEmail(event.target.value);
  };

  const handleNewContactPhoneChange = (event) => {
    setNewContactPhone(event.target.value);
  };

  const handleNewContactGroupsChange = (event) => {
    const selectedGroups = Array.from(event.target.selectedOptions, option => option.value);
    setNewContactGroups(selectedGroups);
  };

  return (
    <Box p={5}>
      <Text fontSize="2xl" mb={4}>Contacts</Text>
      <Box mb={4}>
        <HStack spacing={3}>
          <Input
            placeholder="Search contacts..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <Button onClick={clearSearch}>Clear</Button>
        </HStack>
      </Box>
      <Box mb={4}>
        <Text fontSize="xl" mb={2}>Groups</Text>
        <HStack spacing={3} wrap="wrap">
          {groups.map(group => (
            <Tag
              size="lg"
              key={group}
              variant={selectedGroup === group ? "solid" : "outline"}
              colorScheme={selectedGroup === group ? "purple" : "gray"}
              cursor="pointer"
              onClick={() => handleGroupFilterChange(group)}
            >
              <TagLabel>{group}</TagLabel>
              <TagCloseButton onClick={() => deleteGroup(group)} />
            </Tag>
          ))}
        </HStack>
      </Box>
      <Box mb={4}>
        <FormControl>
          <FormLabel>New Group</FormLabel>
          <HStack spacing={3}>
            <Input placeholder="New group name" />
            <Button onClick={() => addGroup('New Group')}>Add Group</Button>
          </HStack>
        </FormControl>
      </Box>
      <Flex direction="column" mb={4}>
        {filteredContacts.map(contact => (
          <Box
            key={contact.id}
            p={4}
            mb={4}
            borderWidth={1}
            borderRadius="lg"
            boxShadow="sm"
          >
            <HStack spacing={3}>
              <Avatar name={contact.name} src={`https://api.adorable.io/avatars/285/${contact.email}.png`} />
              <Box flex="1">
                <Text fontWeight="bold">{contact.name}</Text>
                <Text>Email: {contact.email}</Text>
                <Text>Phone: {contact.phone}</Text>
                <HStack spacing={2} mt={2}>
                  {contact.groups.map(group => (
                    <Tag key={group} colorScheme="blue">
                      <TagLabel>{group}</TagLabel>
                      <TagCloseButton onClick={() => removeContactFromGroup(contact.id, group)} />
                    </Tag>
                  ))}
                </HStack>
              </Box>
              <Button colorScheme="red" onClick={() => handleDeleteContact(contact.id)}>Delete</Button>
            </HStack>
          </Box>
        ))}
      </Flex>
      <Box>
        <Text fontSize="xl" mb={2}>Add New Contact</Text>
        <form onSubmit={handleNewContactSubmit}>
          <VStack spacing={4} align="flex-start">
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                type="text"
                value={newContactName}
                onChange={handleNewContactNameChange}
                required
              />
            </FormControl>
            <FormControl>
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                value={newContactEmail}
                onChange={handleNewContactEmailChange}
                required
              />
            </FormControl>
            <FormControl>
              <FormLabel>Phone</FormLabel>
              <Input
                type="text"
                value={newContactPhone}
                onChange={handleNewContactPhoneChange}
                required
              />
            </FormControl>
            <FormControl>
              <FormLabel>Groups</FormLabel>
              <Select
                multiple
                value={newContactGroups}
                onChange={handleNewContactGroupsChange}
              >
                {groups.map(group => (
                  <option key={group} value={group}>{group}</option>
                ))}
              </Select>
            </FormControl>
            <Button type="submit" colorScheme="purple">Add Contact</Button>
          </VStack>
        </form>
      </Box>
    </Box>
  );
}

export default Contacts;
