import React, { useState, useEffect } from 'react';
import {
  Link,
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  FormErrorMessage,
  Spinner,
  InputRightElement,
  IconButton,
  Image
} from '@chakra-ui/react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import logoImage from '../image/NewMarcomLogo.png';
import marketingImage1 from '../image/SignUplogo.svg';
import marketingImage2 from '../image/SigninLogo.svg';
import './SignIn.css';
import { CallAPI } from '../../Services/service';
import endpoints from '../../Services/endpoint';
import Header from '../Header/Header';
import { useGoogleLogin } from '@react-oauth/google';
import { FcGoogle } from "react-icons/fc";
import {jwtDecode} from 'jwt-decode';
import axios from 'axios'
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { BsCloudSnowFill } from 'react-icons/bs';

const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [currentImage, setCurrentImage] = useState(marketingImage1);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false); 


  useEffect(() => {
    const imageInterval = setInterval(() => {
      setCurrentImage((prevImage) =>
        prevImage === marketingImage1 ? marketingImage2 : marketingImage1
      );
    }, 3000);

    return () => clearInterval(imageInterval);
  }, []);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
    return passwordRegex.test(password);
  };

  const validateForm = () => {
    let errors = {};
    if (!email) {
      errors.email = 'Email is required.';
    } else if (!validateEmail(email)) {
      errors.email = 'Email is invalid.';
    }

    if (!showForgotPassword) {
      if (!password) {
        errors.password = 'Password is required.';
      } else if (!validatePassword(password)) {
        errors.password = 'Password is invalid.';
      }
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsLoading(true);
      try {
        const res = await CallAPI(endpoints.signin, {
          email: email,
          password: password,
        }).then((res)=>{
          if (res?.code === 200) {
            console.log(res)
            toast.success(res?.message);
            localStorage.setItem('userToken', res?.userToken);
            localStorage.setItem('userData', JSON.stringify(res?.userData));
            setIsAuthenticated(true);
            navigate('/dashboard');
            toast.success("Log in Success")
          }
        })
      } catch (error) {
        console.log(error);
        toast.error('Sign In Failed!');
      } finally {
        setIsLoading(false);
      }
    }
  };



  const sendOTP = async (e) => {
    e.preventDefault();
    if (validateEmail(email)) {
      setIsLoading(true)
      try {
        CallAPI(endpoints.sendotp,{
          email:email
        }).then((res)=>{
          if(res?.status?.code===200){
            setIsLoading(false)
            navigate(`/forget-password/${email}`)      
          }
        })
      } catch (error) {
        console.log("Error>>",error)
      }
    } else {
      toast.error('Invalid email address.');
    }
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    setShowForgotPassword(true);
    setErrors({});
  };

  const handleBackToSignIn = (e) => {
    e.preventDefault();
    setShowForgotPassword(false);
    setErrors({});
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const UserGoogleLogin=()=>{
    const userData = localStorage.getItem("userData")
    if(userData){
      googleLogin()
    }else{
      toast.warning("Kindly Signup your account!")
    }
  }

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        // const res = await CallAPI(endpoints.googleSignIn, {
        //   tokenId: tokenResponse.credential,
        // });

        // if (res?.code === 200) {
          // toast.success('Google Sign In Successful!');
          // localStorage.setItem('userToken', res?.userToken);
          // localStorage.setItem('userData', JSON.stringify(res?.userData));
          // setIsAuthenticated(true);
          // navigate('/dashboard');
    
        // }
        toast.success(' Signin successful');
        // toast.success('Google Sign In Successful!');
        console.log(tokenResponse)
       localStorage.setItem('userToken', tokenResponse.access_token);
       const url = `https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=${tokenResponse.access_token}`;
        
       fetch(url)
       .then((response)=>response.json())
       .then((data)=>{
        // console.log(data);
       })
       .catch(function(error){
        console.log(error);        
       })
       setTimeout(() => {
        navigate('/dashboard');
      }, 2000);
        
      } catch (error) {
        toast.error('Google Sign In Failed!');
      }
    },
    onError: (error) => {
      toast.error('Google Sign In Failed!');
    },
  });

  return (
    <>
      <Box
        bg={useColorModeValue('gray.50', 'gray.800')}
        borderBottom={'1px solid lightgray'}
      >
        <Link href="/">
          <Image
            src={logoImage}
            width={'200px'}
            height={'80px'}
            mixBlendMode={'multiply'}
            pl={'5px'}
          />
        </Link>
      </Box>      
      <Flex
        minH={'85vh'}
        align={'center'}
        justify={'center'}
        bg={useColorModeValue('gray.50', 'gray.800')}
        p={1}
      >
        <Box className="signin-container">
          <Box
            className="signin-image"
            style={{ backgroundImage: `url(${currentImage})` }}
          />
          <Box className="signin-form">
            <Box w={'100%'}>
              <Stack align={'center'} mb={6}>
                <img
                  src={logoImage}
                  alt="Logo"
                  className="signin-logo"
                />
                <Heading fontSize={'4xl'} textAlign={'center'}>
                  {showForgotPassword ? 'Forgot Password' : 'Sign In'}
                </Heading>
              </Stack>

              {!showForgotPassword && (
                <Stack direction="row" justify="center" spacing={4} mb={2}>
                  <Button
                    onClick={() => UserGoogleLogin()}
                    colorScheme="blue"
                    variant="outline"
                  ><FcGoogle />
                  </Button>
                </Stack>
              )}

              <Text textAlign={"center"} fontSize={"14px"}>or use your account</Text>

              {!showForgotPassword ? (
                <form onSubmit={handleSubmit}>
                  <FormControl id="email" isRequired isInvalid={errors.email} mb={4}>
                    <FormLabel>Email address</FormLabel>
                    <Input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {errors.email && <FormErrorMessage>{errors.email}</FormErrorMessage>}
                  </FormControl>
                  <FormControl id="password" isRequired isInvalid={errors.password} mb={4}>
                    <FormLabel>Password</FormLabel>
                    <InputGroup>
                      <Input
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <InputRightElement h={'full'}>
                        <Button
                          variant={'ghost'}
                          onClick={() =>
                            setShowPassword((showPassword) => !showPassword)
                          }
                        >
                          {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                    {errors.password && <FormErrorMessage>{errors.password}</FormErrorMessage>}
                  </FormControl>
                  <Stack spacing={2} pt={2}>
                    <Link color={'blue.400'} onClick={handleForgotPassword} align={'right'}>
                      Forgot Password?
                    </Link>
                    <Button
                      loadingText="Submitting"
                      size="lg"
                      bg={'blue.400'}
                      color={'white'}
                      _hover={{
                        bg: 'blue.500',
                      }}
                      type="submit"
                      disabled={isLoading}
                    >
                      {isLoading ? <Spinner size="sm" /> : 'Sign In'}
                    </Button>
                  </Stack>
                </form>
              ) : (
                <form onSubmit={sendOTP}>
                  <FormControl id="forgot-email" isRequired isInvalid={errors.email} mb={4}>
                    <FormLabel>Email address</FormLabel>
                    <Input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {errors.email && <FormErrorMessage>{errors.email}</FormErrorMessage>}
                  </FormControl>
                  <Stack spacing={10} pt={4}>
                    <Button
                      loadingText="Submitting"
                      size="lg"
                      bg={'blue.400'}
                      color={'white'}
                      _hover={{
                        bg: 'blue.500',
                      }}
                      type="submit"
                  
                    >
                      {isLoading ? <Spinner size="sm" /> : 'Send OTP'}
                      </Button>
                  </Stack>
                  <Stack pt={4} textAlign={"center"}>
                    <Link color={'blue.400'} onClick={handleBackToSignIn}>
                      Back to Sign In
                    </Link>
                  </Stack>
                </form>
              )}

              {!showForgotPassword && (
                <Stack pt={4}>
                  <Text align={'center'}>
                    Don't have an account? <Link color={'blue.400'} href='/signup'>Sign Up</Link>
                  </Text>
                </Stack>
              )}
            </Box>
          </Box>
        </Box>
        <ToastContainer />
      </Flex>
    </>
  );
};

export default SignIn;
