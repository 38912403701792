import {
  Box,
  Input,
  VStack,
  Text,
  Avatar,
  HStack,
  Badge,
  Flex,
} from '@chakra-ui/react';
import React from 'react';

const Conversation = () => {
  return (
    <Box w="full">
      <Flex justifyContent="center" gap={50} w="full" alignItems="center">
        <Flex justifyContent="space-between" gap={18} alignItems="start">
          <Avatar name="Amaraj" bg="red.500" src="link-to-avatar" />
          <Flex
            flexDirection="column"
            justifyContent="start"
            gap={1}
            alignItems="start"
          >
            <Text fontWeight={500} m={0} p={0}>
              Easthar Howar
            </Text>
            <Text fontWeight={400} mb={0} p={0} fontSize="xs">
              May be on Monday?
            </Text>
          </Flex>
          <Box bg="red" w={2} h={2} mt={2} borderRadius="full"></Box>
        </Flex>
        <Text mt={2} ml={0} fontSize="xs" color="#82DE01">
          9:23am
          <Box
            w="20px"
            h="20px"
            bg="red.500"
            color="white"
            borderRadius="full"
            display="flex"
            alignItems="center"
            justifyContent="center"
            fontSize="12px"
            fontWeight="semibold"
            ml={2}
          >
            1
          </Box>
        </Text>
      </Flex>
    </Box>
  );
};

export default Conversation;
