import React, { useState } from "react";
import {
  Box,
  Text,
  Button,
  useToast,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import * as XLSX from "xlsx";

const LeadImport = () => {
  const [leads, setLeads] = useState([]);
  const [importedLeads, setImportedLeads] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [confirmAdd, setConfirmAdd] = useState(false);
  const toast = useToast();
  const cancelRef = React.useRef();

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const leads = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      setImportedLeads(leads.slice(1));
      setIsOpen(true);
    };
    reader.readAsArrayBuffer(file);
  };

  const handleAddLeads = () => {
    setLeads((prevLeads) => [...prevLeads, ...importedLeads]);
    setIsOpen(false);

    toast({
      title: "Leads imported successfully from Excel.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  const handleClose = () => {
    setIsOpen(false);
    setImportedLeads([]);
  };

  return (
    <Box p={4}>
      <Text fontSize="xl">Import Leads from Excel</Text>
      <input type="file" accept=".xlsx" onChange={handleFileUpload} />

      {importedLeads.length > 0 && (
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={handleClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Add Imported Leads
              </AlertDialogHeader>

              <AlertDialogBody>
                Do you want to add {importedLeads.length} leads to the existing list?
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={handleClose}>
                  Cancel
                </Button>
                <Button colorScheme="green" onClick={handleAddLeads} ml={3}>
                  Add Leads
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}

      {leads.length > 0 && (
        <Table variant="striped" colorScheme="gray">
          <Thead>
            <Tr>
              {leads[0].map((header, index) => (
                <Th key={index}>{header}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {leads.slice(1).map((lead, rowIndex) => (
              <Tr key={rowIndex}>
                {lead.map((data, cellIndex) => (
                  <Td key={cellIndex}>{data}</Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </Box>
  );
};

export default LeadImport;
