import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Heading,
  Stack,
  Avatar,
  Text,
  Button,
  SimpleGrid,
  Card,
  CardBody,
  Input,
  useToast,
  VStack,
  HStack,
} from '@chakra-ui/react';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import Footer from '../Footer/Footer';
import { motion } from 'framer-motion';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import { FaCheckCircle, FaTimesCircle, FaInfoCircle } from 'react-icons/fa';

const UserPage = () => {
  const toast = useToast();
  const [user, setUser] = useState({
    name: '',
    email: '',
    avatar: 'https://via.placeholder.com/150',
    role: '',
    joined: '',
    phone: '',
    address: '',
  });

  const [activities, setActivities] = useState([
    { id: 1, type: 'login', message: 'Logged in', date: '2024-07-30', icon: FaCheckCircle },
    { id: 2, type: 'update', message: 'Updated profile information', date: '2024-07-28', icon: FaInfoCircle },
    { id: 3, type: 'error', message: 'Failed to sync data', date: '2024-07-27', icon: FaTimesCircle },
  ]);

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('user')) || {
      name: 'Mohit Sharma',
      email: 'Mohit.Sharma123@gmail.com',
      role: 'Sales Manager',
      joined: 'January 1, 2024',
      phone: '(123) 456-7890',
      address: 'Patna, Bihar, India',
    };
    setUser(storedUser);
    setUser([])
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleSaveProfile = () => {
    localStorage.setItem('user', JSON.stringify(user));
    toast({
      title: 'Profile saved.',
      description: "We've saved your profile details.",
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
  };

  const data = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June'],
    datasets: [
      {
        label: 'Sales',
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: 'rgba(54, 162, 235, 0.6)',
      },
      {
        label: 'Leads',
        data: [2, 3, 20, 5, 1, 4],
        backgroundColor: 'rgba(255, 206, 86, 0.6)',
      },
    ],
  };

  return (
    <Box display="flex" flexDirection="column" minH="100vh">
      <Header />
      <Box display="flex" flex="1">
        <Sidebar />
        <Container
          maxW="container.lg"
          py={8}
          flex="1"
          as={motion.div}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <Stack direction="row" justify="space-between" align="center" mb={8}>
            <Heading as="h1" size="lg">User Profile</Heading>
            <Button colorScheme="red" onClick={handleSaveProfile}>Save Profile</Button>
          </Stack>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
            <Card>
              <CardBody>
                <Stack spacing={4} align="center">
                  <Avatar size="xl" src={user.avatar} />
                  <Box textAlign="center">
                    <Input placeholder="Name" name="name" value={user.name} onChange={handleInputChange} />
                    <Input placeholder="Role" name="role" value={user.role} onChange={handleInputChange} />
                    <Input placeholder="Email" name="email" value={user.email} onChange={handleInputChange} />
                    <Input placeholder="Joined Date" name="joined" value={user.joined} onChange={handleInputChange} />
                  </Box>
                </Stack>
              </CardBody>
            </Card>
            <Stack spacing={4}>
              <Card>
                <CardBody>
                  <Heading size="md" mb={4}>Activity</Heading>
                  <VStack align="start" spacing={4}>
                    {activities.map((activity) => (
                      <HStack key={activity.id} align="center">
                        <Box as={activity.icon} color="blue.500" />
                        <Box>
                          <Text fontWeight="bold">{activity.message}</Text>
                          <Text fontSize="sm" color="gray.500">{activity.date}</Text>
                        </Box>
                      </HStack>
                    ))}
                  </VStack>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <Heading size="md" mb={4}>Contact Information</Heading>
                  <Input placeholder="Phone" name="phone" value={user.phone} onChange={handleInputChange} />
                  <Input placeholder="Address" name="address" value={user.address} onChange={handleInputChange} />
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <Heading size="md" mb={4}>Sales and Leads</Heading>
                  <Bar data={data} />
                </CardBody>
              </Card>
            </Stack>
          </SimpleGrid>
        </Container>
      </Box>
      <Footer />
    </Box>
  );
};

export default UserPage;
