import React, { useState } from 'react';
import { SearchIcon } from '@chakra-ui/icons';
import {
  Box,
  List,
  ListItemInputGroup,
  InputLeftElement,
  InputGroup,
  ListItem,
  Input,
  Icon,
} from '@chakra-ui/react';

const SearchInput = () => {
  const [query, setQuery] = useState('');
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);

  // Example suggestions
  const suggestions = [
    'How can i upload a profile picture',
    'How can i update my email',
  ];
  const handleInputChange = (e) => {
    const value = e.target.value;
    setQuery(value);

    // Filter suggestions based on input
    const filtered = suggestions.filter((suggestion) =>
      suggestion.toLowerCase().includes(value.toLowerCase())
    );

    setFilteredSuggestions(filtered);
  };

  // Handle suggestion click
  const handleSuggestionClick = (suggestion) => {
    setQuery(suggestion);
    setFilteredSuggestions([]); // Hide suggestions
  };

  return (
    <Box position="relative" width="280px">
      <InputGroup>
        <InputLeftElement
          pointerEvents="none"
          children={<Icon as={SearchIcon} color="gray.300" />}
        />
        <Input
          type="text"
          onChange={handleInputChange}
          placeholder="Search for the leads"
          border="1px"
          borderColor="#CCCBCB"
          bg="#EDEDED"
          borderRadius="md"
        />
      </InputGroup>
      {filteredSuggestions.length > 0 && (
        <Box
          position="absolute"
          bg="white"
          boxShadow="md"
          width="100%"
          zIndex={1}
          mt={2}
        >
          <List spacing={1}>
            {filteredSuggestions.map((suggestion, index) => (
              <ListItem
                key={index}
                padding="8px"
                cursor="pointer"
                _hover={{ backgroundColor: 'gray.100' }}
                onClick={() => handleSuggestionClick(suggestion)}
              >
                {suggestion}
              </ListItem>
            ))}
          </List>
        </Box>
      )}
    </Box>
  );
};

export default SearchInput;
