import React from 'react';
import {
  Box,
  Flex,
  Avatar,
  Text,
  Badge,
  Icon,
  InputGroup,
  InputRightElement,
  Input,
} from '@chakra-ui/react';
import { BsSend } from 'react-icons/bs';
import { FiSend } from 'react-icons/fi';
import { RiAttachment2 } from 'react-icons/ri';
const Messages = () => {
  const customScrollbarStyles = {
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#f0f0f0',
    },
    scrollbarWidth: 'thin',
    scrollbarColor: '#888 #f0f0f0',
  };
  return (
    <Box p={4} pr={0} w="100%">
      <Flex
        direction="column"
        justifyContent="space-between"
        p={4}
        pr={0}
        w="full"
        bg="white"
      >
        <Flex align="center" my={3} ml={12} w="80%">
          <Box flex="1" h="1px" bg="#B6B6B6" />
          <Text px={3} fontSize="16px" fontWeight={600} m={0} color="#757575">
            Today
          </Text>
          <Box flex="1" h="1px" bg="#B6B6B6" />
        </Flex>
        <Box
          m={0}
          p={0}
          h={'70%'}
          overflowY={'scroll'}
          sx={customScrollbarStyles}
        >
          <Flex align="start" mb={4}>
            <Avatar name="Darlene Robet" src="link-to-avatar" mr={4} />
            <Flex direction="column" flex="1">
              <Flex align="start" mb={2}>
                <Flex flexDirection="column">
                  <Text fontWeight="bold" m={0}>
                    Darlene Robet
                  </Text>
                  <Text fontSize="xs" color="#82DE01" m={0}>
                    06:32 pm
                  </Text>
                </Flex>
                <Box
                  bg="red"
                  ml={2}
                  w={2}
                  h={2}
                  mt={2}
                  borderRadius="full"
                ></Box>
              </Flex>

              <Box
                bg="blue.50"
                h={20}
                w="50%"
                p={4}
                borderRadius="0px 12px 12px 0px"
              >
                {/* Placeholder for the message content */}
              </Box>
            </Flex>
          </Flex>
          <Flex align="start" mb={4}>
            <Avatar name="Darlene Robet" src="link-to-avatar" mr={4} />
            <Flex direction="column" flex="1">
              <Flex align="start" mb={2}>
                <Flex flexDirection="column">
                  <Text fontWeight="bold" m={0}>
                    Darlene Robet
                  </Text>
                  <Text fontSize="xs" color="#82DE01" m={0}>
                    06:32 pm
                  </Text>
                </Flex>
                <Box
                  bg="red"
                  ml={2}
                  w={2}
                  h={2}
                  mt={2}
                  borderRadius="full"
                ></Box>
              </Flex>

              <Box
                bg="blue.50"
                h={20}
                w="50%"
                p={4}
                borderRadius="0px 12px 12px 0px"
              >
                {/* Placeholder for the message content */}
              </Box>
            </Flex>
          </Flex>
        </Box>

        {/* Repeat the above structure for additional messages */}
      </Flex>
      <InputGroup mx="auto" mt={90} w="80%">
        <InputRightElement
          pointerEvents="none"
          children={<Icon boxSize={4} as={FiSend} />}
        />
        <InputRightElement
          pointerEvents="none"
          mr={6}
          children={<Icon boxSize={5} as={RiAttachment2} />}
        />
        <Input
          type="text"
          placeholder="Send Messages"
          border="1px"
          borderColor="#D1202A"
          borderRadius="full"
          pr={10} // Add padding to the right to make space for the icon
        />
      </InputGroup>
    </Box>
  );
};

export default Messages;
