import React, { useState, useEffect } from 'react';
import {
  Flex, Box, FormControl, FormLabel, Input, InputGroup, HStack, InputRightElement, Stack, Button, Link, Heading, Text, useColorModeValue, FormErrorMessage, Image
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './signup.css';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import logoImage from '../image/NewMarcomLogo.png';
import marketingImage1 from '../image/SignUplogo.svg';
import marketingImage2 from '../image/SigninLogo.svg';
import { CallAPI } from '../../Services/service';
import endpoints from '../../Services/endpoint';
import { useNavigate } from 'react-router-dom';
import { FcGoogle } from "react-icons/fc";
import { useGoogleLogin } from '@react-oauth/google';

export default function SignUp() {
  const [showPassword, setShowPassword] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [errors, setErrors] = useState({});
  const [currentImage, setCurrentImage] = useState(marketingImage1);
  const [googleUserProfileData, setGoogleUserProfileData]=useState()

  const navigate = useNavigate();

  useEffect(() => {
    const imageInterval = setInterval(() => {
      setCurrentImage((prevImage) =>
        prevImage === marketingImage1 ? marketingImage2 : marketingImage1
      );
    }, 3000);
    return () => clearInterval(imageInterval);
  }, []);

  const validateForm = () => {
    let errors = {};
    if (!firstName.trim()) {
      errors.firstName = 'First name is required.';
    }
    if (!lastName.trim()) {
      errors.lastName = 'Last name is required.';
    }
    if (!email.trim()) {
      errors.email = 'Email is required.';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      errors.email = 'Email is invalid.';
    }
    if (!phoneNumber) {
      errors.phoneNumber = 'Phone number is required.';
    }
    if (!password.trim()) {
      errors.password = 'Create a password.';
    } else if (password.length < 8) {
      errors.password = 'Password must be at least 8 characters long.';
    } else if (!/(?=.*[a-z])/.test(password)) {
      errors.password = 'Password must contain at least one lowercase letter.';
    } else if (!/(?=.*[A-Z])/.test(password)) {
      errors.password = 'Password must contain at least one uppercase letter.';
    } else if (!/(?=.*\d)/.test(password)) {
      errors.password = 'Password must contain at least one number.';
    } else if (!/(?=.*[@$!%*?&])/.test(password)) {
      errors.password = 'Password must contain at least one special character.';
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const VerifyEmail =(email)=>{
    CallAPI(endpoints.emailverify,{
      email:email
    }).then((response)=>{
      // console.log("email verify response>>",response)
      if(response?.status?.code===200){
        navigate(`/verify-email/${"_"}`);
      }
    })  
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const res = CallAPI(endpoints.signup, {
          email_id: email,
          firstName: firstName,
          lastName: lastName,
          mobileNumber: phoneNumber,
          password: password
        }).then((res) => {
          if (res?.status?.code === 200) {
            console.log(res)
            toast.success(res?.status?.message);     
            VerifyEmail(email)
          }
        });
      } catch (error) {
        console.error('Error signing up:', error);
        toast.error('Sign up failed!');
      }
    }
  };

  const sendDataAfterGoogleSignup=(data)=>{
    const res = CallAPI(endpoints.signup, {
      email_id: data?.email,
      firstName: data?.given_name,
      lastName: data?.family_name,
      mobileNumber: "91",
      password: data?.id
    }).then((res) => {
      if (res?.status?.code === 200) {
        const userData = JSON.stringify(res?.session?.token)          // Here token is user data
        localStorage.setItem("userData",userData)
        localStorage.setItem("userToken",res?.session?.token?.uuid)
        toast.success(res?.status?.message);     
      }
    });
  }

  const googleSignUp = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
       
        localStorage.setItem('userToken', tokenResponse.access_token);
        // toast.success(' Signup successfull... ');

        const url = `https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=${tokenResponse.access_token}`;
        
        fetch(url)
        .then((response)=>response.json())
        .then((data)=>{
          sendDataAfterGoogleSignup(data)
        })
        .catch(function(error){
          console.log(error);        
        })

        setTimeout(() => {
          navigate('/signin');
        }, 5000);
      } catch (error) {
        console.error('Error signing up with Google:', error);
        toast.error('Google Sign Up Failed!');
      }
    },
    onError: (error) => {
      console.error('Google Sign Up Failed:', error);
      toast.error('Google Sign Up Failed!');
    },
  });

  return (
    <div>
      <Box
        bg={useColorModeValue('gray.50', 'gray.800')}
        borderBottom={'1px solid lightgray'}
      >
        <Link href="/">
          <Image
            src={logoImage}
            width={'200px'}
            height={'80px'}
            mixBlendMode={'multiply'}
            pl={'5px'}
          />
        </Link>
      </Box>
      <Flex
        minH={'50vh'}
        align={'center'}
        justify={'center'}
        bg={useColorModeValue('gray.50', 'gray.800')}
        p={4}
      >
        <Box className="signup-container">
          <Box
            className="signup-image"
            style={{
              backgroundImage: `url(${currentImage})`,
            }}
          />
          <Box className="signup-form" bg={useColorModeValue('white', 'gray.700')}>
            <Box w={'100%'}>
              <Stack align={'center'} mb={0} mt={"20px"}>
                <Heading fontSize={'4xl'} textAlign={'center'}>
                  Sign Up
                </Heading>
                <HStack spacing={4} justify={'center'}>
                  <Button onClick={googleSignUp} colorScheme="blue" variant="outline">
                  <FcGoogle />
                  </Button>
                </HStack>
              </Stack>
              <Text textAlign={"center"} fontSize={"14px"}>or use your email for registration</Text>
              <form onSubmit={handleSubmit}>
                <Stack spacing={4} mt={6}>
                  <HStack>
                    <Box>
                      <FormControl id="firstName" isRequired isInvalid={errors.firstName}>
                        <FormLabel>First Name</FormLabel>
                        <Input
                          type="text"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                        {errors.firstName && (
                          <FormErrorMessage>{errors.firstName}</FormErrorMessage>
                        )}
                      </FormControl>
                    </Box>
                    <Box>
                      <FormControl id="lastName" isRequired isInvalid={errors.lastName}>
                        <FormLabel>Last Name</FormLabel>
                        <Input
                          type="text"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                        {errors.lastName && (
                          <FormErrorMessage>{errors.lastName}</FormErrorMessage>
                        )}
                      </FormControl>
                    </Box>
                  </HStack>
                  <FormControl id="email" isRequired isInvalid={errors.email}>
                    <FormLabel>Email address</FormLabel>
                    <Input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {errors.email && (
                      <FormErrorMessage>{errors.email}</FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl id="phoneNumber" isRequired isInvalid={errors.phoneNumber}>
                    <FormLabel>Phone Number</FormLabel>
                    <PhoneInput
                      country={'us'}
                      value={phoneNumber}
                      onChange={(phone) => setPhoneNumber(phone)}
                      inputStyle={{
                        width: '100%',
                        height: '40px',
                      }}
                      containerStyle={{
                        width: '100%',
                      }}
                    />
                    {errors.phoneNumber && (
                      <FormErrorMessage>{errors.phoneNumber}</FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl id="password" isRequired isInvalid={errors.password}>
                    <FormLabel>Create Password</FormLabel>
                    <InputGroup>
                      <Input
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <InputRightElement h={'full'}>
                        <Button
                          variant={'ghost'}
                          onClick={() =>
                            setShowPassword((showPassword) => !showPassword)
                          }
                        >
                          {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                    {errors.password && (
                      <FormErrorMessage>{errors.password}</FormErrorMessage>
                    )}
                  </FormControl>
                  <Stack spacing={5} pt={2}>
                    <Button
                      loadingText="Submitting"
                      size="lg"
                      bg={'blue.400'}
                      color={'white'}
                      _hover={{
                        bg: 'blue.500',
                      }}
                      type="submit"
                    >
                      Sign up
                    </Button>
                    <Stack align={'center'}>
                      <Text align={'center'}>
                        Already a user? <Link color={'blue.400'} href='/signin'>Sign In</Link>
                      </Text>
                    </Stack>
                  </Stack>
                </Stack>
              </form>
            </Box>
          </Box>
        </Box>
        <ToastContainer />
      </Flex>
    </div>
  );
}
