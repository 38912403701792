import React, { useEffect, useState } from 'react';
import { Box, Flex, Text, Input } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Sidebar from '../Sidebar/Sidebar';
import SecurityQuestions from './components/SecurityQuestions';
import AccountProfileQuestions from './components/AccountProfileQuestions';
import LearningSupport from './components/LearningSupport';
import GettingStarted from './components/GettingStarted';
import PurchaseAndRefund from './components/PurchaseAndRefund';
import { Link } from 'react-router-dom';
import SearchBar from './components/Searchbar';
// Import other topic components

const HelpCenter = () => {
  const location = useLocation();
  const [selectedTopic, setSelectedTopic] = useState('');

  useEffect(() => {
    // Determine the selected topic based on the current route
    switch (location.pathname) {
      case '/account-profile':
        setSelectedTopic('Account & Profile');
        break;
      case '/getting-started':
        setSelectedTopic('Getting Started');
        break;
      case '/learning-support':
        setSelectedTopic('Learning Support');
        break;
      case '/purchase-refund':
        setSelectedTopic('Purchase & Refund');
        break;
      case '/security':
        setSelectedTopic('Security');
        break;
      default:
        setSelectedTopic('Account & Profile'); // Default to 'Account & Profile'
        break;
    }
  }, [location.pathname]);

  // Render the appropriate questions component based on selectedTopic
  const renderQuestionsComponent = () => {
    switch (selectedTopic) {
      case 'Security':
        return <SecurityQuestions />;
      case 'Account & Profile':
        return <AccountProfileQuestions />;
      case 'Learning Support':
        return <LearningSupport />;
      case 'Getting Started':
        return <GettingStarted />;
      case 'Purchase & Refund':
        return <PurchaseAndRefund />;
      // Add more cases for other topics
      default:
        return <AccountProfileQuestions />; // Default component
    }
  };

  return (
    <>
      <Flex>
        <Box flex="1">
          <Flex
            flexDirection="column"
            justifyContent="start"
            alignItems="start"
            gap="20px"
            ml={7}
            mt={8}
          >
            <Box w="100%">
              <Text color="#000000" fontSize="18px" fontWeight="medium">
                <Link to="/support">Home</Link> / {selectedTopic}
              </Text>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                w="100%"
                gap={7}
              >
                <Text color="#000000" fontSize="24px" fontWeight="700">
                  <span>{selectedTopic}</span>
                </Text>
                <Box w="50%">
                  <SearchBar />
                </Box>
              </Box>
            </Box>
            <Box w="100%">
              <Flex justifyContent="start" fontSize="18px" gap={9}>
                <Box w="30%">
                  <Text fontSize="18px" fontWeight="medium" color="#000000">
                    Topics
                  </Text>
                  {/* Add topic links here */}
                  <Text
                    color={
                      selectedTopic === 'Account & Profile'
                        ? '#B83D9B'
                        : '#6B46C1'
                    }
                    fontWeight="bold"
                    onClick={() => setSelectedTopic('Account & Profile')}
                    cursor="pointer"
                    fontSize="16px"
                  >
                    Account & Profile
                  </Text>
                  <Text
                    color={selectedTopic === 'Security' ? '#B83D9B' : '#6B46C1'}
                    fontWeight="bold"
                    onClick={() => setSelectedTopic('Security')}
                    cursor="pointer"
                    fontSize="16px"
                  >
                    Security
                  </Text>
                  <Text
                    color={
                      selectedTopic === 'Learning Support'
                        ? '#B83D9B'
                        : '#6B46C1'
                    }
                    fontWeight="bold"
                    onClick={() => setSelectedTopic('Learning Support')}
                    cursor="pointer"
                    fontSize="16px"
                  >
                    Learning Support
                  </Text>
                  <Text
                    color={
                      selectedTopic === 'Getting Started'
                        ? '#B83D9B'
                        : '#6B46C1'
                    }
                    fontWeight="bold"
                    onClick={() => setSelectedTopic('Getting Started')}
                    cursor="pointer"
                    fontSize="16px"
                  >
                    Getting Started
                  </Text>
                  <Text
                    color={
                      selectedTopic === 'Purchase & Refund'
                        ? '#B83D9B'
                        : '#6B46C1'
                    }
                    fontWeight="bold"
                    onClick={() => setSelectedTopic('Purchase & Refund')}
                    cursor="pointer"
                    fontSize="16px"
                  >
                    Purchase & Refund
                  </Text>
                  {/* Add more topic links here */}
                </Box>
                <Box ml={50} w="50%">
                  {renderQuestionsComponent()}{' '}
                  {/* Render the questions component */}
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </>
  );
};

export default HelpCenter;
