import React, { useState } from 'react';
import { Container, Box, SimpleGrid, Stack, Text, IconButton, Input, Flex, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, useDisclosure } from '@chakra-ui/react';
import { FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa';
import { BiMailSend } from 'react-icons/bi';
import MarcomLogo from "../image/NewMarcomLogo.png";
import FullScreenSvg from "../image/MARCOM.svg";
import Success from './Success'; // Import the Success component

const ListHeader = ({ children }) => {
  return (
    <Text fontWeight="600" fontSize="lg" mb={4} color="white">
      {children}
    </Text>
  );
};

const SocialButton = ({ children, label, href }) => {
  return (
    <IconButton
      as="a"
      href={href}
      aria-label={label}
      icon={children}
      variant="ghost"
      size="lg"
      color="white"
      _hover={{ color: 'red.600', bg: 'whiteAlpha.100' }}
    />
  );
};

const Footer = () => {
  const [email, setEmail] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure(); // Chakra UI's modal controls

  const handleSubscribe = () => {
    if (email) {
      localStorage.setItem('subscribedEmail', email);
      setEmail('');
      onOpen(); // Open the success popup
    }
  };

  return (
    <>
      <Box bg="gray.900" color="gray.200" py={10}>
        <Container as={Stack} maxW="6xl">
          <SimpleGrid templateColumns={{ sm: '1fr 1fr', md: '2fr 1fr 1fr 2fr' }} spacing={10}>
            <Stack spacing={6}>
              <Flex justify={{ base: 'center', md: 'start' }}>
                <Box
                  as="img"
                  src={MarcomLogo}
                  alt="Marcom Logo"
                  boxSize={{ base: "50px", md: "90px" }}
                  transition="transform 0.3s ease-in-out"
                  _hover={{ transform: "scale(1.1)", filter: "brightness(1.2)" }}
                />
              </Flex>
              <Stack direction="row" spacing={6}>
                <SocialButton label="Twitter" href="#">
                  <FaTwitter />
                </SocialButton>
                <SocialButton label="YouTube" href="#">
                  <FaYoutube />
                </SocialButton>
                <SocialButton label="Instagram" href="#">
                  <FaInstagram />
                </SocialButton>
              </Stack>
              <Text fontSize="sm" color="gray.500" textAlign={{ base: "center", md: "left" }}>
                © 2024 Marcom USA. All rights reserved.
              </Text>
            </Stack>

            <Stack align="flex-start">
              <ListHeader>Company</ListHeader>
              <Box as="a" href="#" _hover={{ color: 'red.600' }}>About us</Box>
              <Box as="a" href="#" _hover={{ color: 'red.600' }}>Blog</Box>
              <Box as="a" href="#" _hover={{ color: 'red.600' }}>Contact us</Box>
              <Box as="a" href="#" _hover={{ color: 'red.600' }}>Pricing</Box>
              <Box as="a" href="#" _hover={{ color: 'red.600' }}>Testimonials</Box>
            </Stack>

            <Stack align="flex-start">
              <ListHeader>Support</ListHeader>
              <Box as="a" href="#" _hover={{ color: 'red.600' }}>Help Center</Box>
              <Box as="a" href="#" _hover={{ color: 'red.600' }}>Terms of Service</Box>
              <Box as="a" href="#" _hover={{ color: 'red.600' }}>Legal</Box>
              <Box as="a" href="#" _hover={{ color: 'red.600' }}>Privacy Policy</Box>
              <Box as="a" href="#" _hover={{ color: 'red.600' }}>Status</Box>
            </Stack>

            <Stack align="flex-start">
              <ListHeader>Stay up to date</ListHeader>
              <Stack direction="row">
                <Input
                  placeholder="Your email address"
                  bg="whiteAlpha.100"
                  border={0}
                  _focus={{ bg: 'whiteAlpha.300', outline: 'none' }}
                  color="white"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <IconButton
                  bg="red.600"
                  color="white"
                  _hover={{ bg: 'red.700' }}
                  aria-label="Subscribe"
                  icon={<BiMailSend />}
                  onClick={handleSubscribe}
                />
              </Stack>
            </Stack>
          </SimpleGrid>

          <Box
            mt={10}
            width="100%"
            height="auto"
            bg="gray.900"
            color="gray.200"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <img src={FullScreenSvg} alt="Full Screen SVG" />
          </Box>
        </Container>
      </Box>

      {/* Modal for Success Popup */}
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Success />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Footer;
