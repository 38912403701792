import React from 'react';
import { Box } from '@chakra-ui/react';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';

const Layout = ({ children }) => {
  return (
    <Box display="flex" flexDirection="column" minHeight="80vh">
      {/* Header */}
      <Box
        as="header"
        position="fixed"
        top="0"
        left="0"
        right="0"
        height="60px"
        zIndex="1000"
        boxShadow="sm"
        backgroundColor="white"
      >
        <Header />
      </Box>

      {/* Sidebar and Main Content */}
      <Box display="flex" flex="1" pt="70px" pb="60px">
        {/* Sidebar */}
        <Box
          as="aside"
          position="fixed"
          top="60px"
          bottom="60px"
          left="0"
          zIndex="999"
          boxShadow="md"
          backgroundColor="none"
          padding="4"
          paddingTop="0px"
          overflow="hidden"
        >
          <Sidebar />
        </Box>

        {/* Main Content */}
        <Box
          as="main"
          flex="1"
          ml="10%"
          padding="10"
          paddingTop={'5'}
          backgroundColor="#FFFFFFF"
          overflowY="auto"
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
