import React, { useState } from 'react';
import { Input, Box, List, ListItem } from '@chakra-ui/react';

const SearchBar = () => {
  const [query, setQuery] = useState('');
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);

  // Example suggestions
  const suggestions = [
    'How can i upload a profile picture',
    'How can i update my email',
  ];

  // Handle input change
  const handleInputChange = (e) => {
    const value = e.target.value;
    setQuery(value);

    // Filter suggestions based on input
    const filtered = suggestions.filter((suggestion) =>
      suggestion.toLowerCase().includes(value.toLowerCase())
    );

    setFilteredSuggestions(filtered);
  };

  // Handle suggestion click
  const handleSuggestionClick = (suggestion) => {
    setQuery(suggestion);
    setFilteredSuggestions([]); // Hide suggestions
  };

  return (
    <Box position="relative" width="300px">
      <Input
        value={query}
        onChange={handleInputChange}
        placeholder="Search..."
        size="md"
      />
      {filteredSuggestions.length > 0 && (
        <Box
          position="absolute"
          bg="white"
          boxShadow="md"
          width="100%"
          zIndex={1}
          mt={2}
        >
          <List spacing={1}>
            {filteredSuggestions.map((suggestion, index) => (
              <ListItem
                key={index}
                padding="8px"
                cursor="pointer"
                _hover={{ backgroundColor: 'gray.100' }}
                onClick={() => handleSuggestionClick(suggestion)}
              >
                {suggestion}
              </ListItem>
            ))}
          </List>
        </Box>
      )}
    </Box>
  );
};

export default SearchBar;
