'use client'

import { Box, Heading, Text } from '@chakra-ui/react'
import { CheckCircleIcon } from '@chakra-ui/icons'

export default function Success() {
  return (
    <Box textAlign="center" py={10} px={6}>
      <CheckCircleIcon boxSize={'50px'} color={'green.500'} />
      <Heading as="h2" size="xl" mt={6} mb={2}>
        Subscription Successful!
      </Heading>
      <Text color={'gray.500'}>
        Thank you for subscribing. You will now receive our latest updates.
      </Text>
    </Box>
  )
}
