import React from 'react';
import { motion } from 'framer-motion';

const CompanyPartners = () => {
  const partners = [
    { name: 'Apollo.io', logo: 'https://th.bing.com/th/id/OIP.PseaIbJncZRDrGh7DPW8KQHaEI?rs=1&pid=ImgDetMain' },
    { name: 'Zoho', logo: 'https://www.zoho.com/books/images/new/zoho-logo-new.png' },
    { name: 'Agile CRM', logo: 'https://images.g2crowd.com/uploads/product/image/large_detail/large_detail_58df669fce501aff4e5598ddd8f564a8/agile-crm.png' },
    { name: 'Pega', logo: 'https://www.pyze.com/wp-content/uploads/2020/07/pega-transparent-logo-2.png' },
  ];

  const containerStyle = {
    textAlign: 'center',
    padding: '50px 20px',
    backgroundColor: '#f9f9f9',
  };

  const titleStyle = {
    fontSize: '2rem',
    color: '#333',
    marginBottom: '30px',
    textTransform: 'uppercase',
    letterSpacing: '2px',
  };

  const gridStyle = {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: '20px',
  };

  const cardStyle = {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '15px',
    boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    width: '180px',
    height: '180px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid transparent',
    transition: 'transform 0.3s ease-in-out, border-color 0.3s ease-in-out',
  };

  const logoStyle = {
    maxWidth: '80px',
    marginBottom: '10px',
  };

  const nameStyle = {
    fontSize: '1rem',
    color: '#555',
    textTransform: 'uppercase',
    letterSpacing: '1px',
  };

  return (
    <div style={containerStyle}>
      <h2 style={titleStyle}>Our Trusted Partners</h2>
      <div style={gridStyle}>
        {partners.map((partner, index) => (
          <motion.div
            key={index}
            style={cardStyle}
            whileHover={{ scale: 1.1, borderColor: '#ff0000' }}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.2 }}
          >
            <img src={partner.logo} alt={partner.name} style={logoStyle} />
            <p style={nameStyle}>{partner.name}</p>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default CompanyPartners;
