import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Box, 
  Text, 
  Button, 
  Image,
  IconButton, 
  Popover, 
  PopoverTrigger, 
  PopoverContent, 
  PopoverHeader, 
  PopoverBody, 
  PopoverCloseButton, 
  PopoverArrow, 
  useDisclosure ,
  Center,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import { FaFacebook, FaTimes, FaWindowMinimize, FaWindowMaximize, FaToggleOn, FaToggleOff } from 'react-icons/fa';
import Sidebar from '../Sidebar/Sidebar';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import img1 from '../image/cmimg.jpg';
import img2 from '../image/Add Vector.png';
import img4 from '../image/dummyBoyImage.png';
import CampaignCard from './CampaignCard ';
import { SiGmail } from "react-icons/si";
import { PiWhatsappLogoBold } from "react-icons/pi";
import { GrTest } from "react-icons/gr";
import { GrInstagram } from "react-icons/gr";
import { FaXTwitter, FaLinkedinIn } from "react-icons/fa6";
import Style from './Campaign.module.css'
import endpoint from '../../Services/endpoint'
import {CallAPI} from '../../Services/service'
import { Shimmer } from 'react-shimmer'
import { ToastContainer, toast } from 'react-toastify';

export default function AllCampaigns() {
  const userImage = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAALIAAACUCAMAAAAAoYNxAAAAMFBMVEXk5ueutLeqsLTN0dPn6erV2Nra3d6zuLvq7O3IzM7h4+Te4OK4vcDBxci9wsTFycsk986QAAAECklEQVR4nO2c25qjIAyAUcJBQH3/tx2YOtNOaxUIJbjLf7V790++yCEkZazT6XQ6nU6n0+l0Op3/AdCaSeGRlmkN1DrnGLGq8Y6bBLXREQBWLF5zeMT/d+YWmgw2gAzxHV4ZB7XKBp3BLDu2d2bWmDSwdS++fxKENyUNQp0YB2kn2nGG9dT3xqqpVTfAnYd4C/RC7XrDqkjh7+QwDSSHTDBuw9kkGQdn8m0l0djjiI1dsnHYVQiBKcN4GDlhasgcY+9s6JRj1+MXqMKs52zlicYZRLbxoIgOo8enzUPGiUIYRL6xd7YUzkt+XnjlmSAzJMbYO9c3hrNryJly/UUDcMZ+0aiujFjhNmpfq/SMNa5/q0o/dD6zVD5poBblG5V3QOB45crJHF0GOGDkNY3ZSTUrTrnu7cTmXKCeqXoJhLRKwBtUVeUCC4an5ipXSLnmAbSM8ii7clf+R5Sv9/nVXeSut5VccMO+4rEI8Peo2ofPvMLyE5WP+Ne7SF3xuqrxS0btqhymuLxR/UUbXeAaKgujXh2+GQme4LHFWoJXKYMqiQ8LQUkc+fBQ86x8J7oNY8d4JnmtvOIjms7P5pXsFTtbmUo4+/WB5tFvI69mS/SCfcPknI6I27hMurGiTIuATTam76NMrA9QrciPQFK3jrP0xizkc3QDZQONfTeiSwRzK22q4RkwphlYUTabvQD29Lwxrm2k8R2Qx2fRRr67P4AW7s16Nw5LQw3ijwCTkxuehx68L5fk3bRvAWYkn4eHURjlfQ39fncCaDBScM6FNHCFgSMGAX0j/JPa5wgIIbVS+ABPGz7SYbgLgjy13hNh+MzyyTm1s2YopdzMbTtJ4r+4n0/ucCMJU2mrsOSfYpg9m5b9Ya5d78GtXNJZg5bT4qJPcb/ayq2SJEeA8dxCvv8jVfUDB5gwi5hp/BNsbutlCJjJ4YRv1mFwsYq0NqtKTeC30ouscOg3yOr9i/WHx3HBTgUy4kl6EZ/LaWBTxH0pg/lTy4eWJdoD9vlIlQ4Yclk7ZBzKV2SiBmpR0lPpEkeBTtozZ1cy0MA+HOINUWyRLtT8dM64ljIu0REe6bwUSWhdosEl2rnEMC6U3aBPKVB/Rs4V1XfGTkJlOaPyWU/1jXHTU8ApjDFtitXW4xey+xRTfpKhMJn1fsC1taDIa+Oh+fR+yQhz2nNeeXIO/QXafTGk7yhU69sv6T8LYxC9ToWcE1+EyIM8JG+Cmtp3SA1zC0FODDPQ7XuPJISZ7nDxl4RuuhLzDCVw8V1IRQZdChD/0zut5EVKkaCJ9SLgYg90haveCKLPoKm/BPY5xtgSv20lyPE/VYIcDCjJEldYbGO33ohT1uvYDpFRDh0rrRBlzLbGlTbYsfsCcLs58obaI50AAAAASUVORK5CYII="
  const navigate = useNavigate();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [isMinimized, setIsMinimized] = useState(false);
  const [campaigns, setCampaigns] = useState([])
  const [loadingData, setDataLoading] = useState(false)
  const [isWhatsappCampaignData, setIsWhatsappCampaignData] = useState(false)
  const [isLinkedinCampaignData, setIsLinkedinCampaignData] = useState(false)
  const [isFacebookCampaignData, setIsFacebookCampaignData] = useState(false)
  const [isInstagramCampaignData, setIsInstagramCampaignData] = useState(false)
  const [isTwitterCampaignData, setIsTwitterCampaignData] = useState(false)
  const [isEmailCampaignData, setIsEmailCampaignData] = useState(false)


  const fetchAllCampaigns=(id)=>{
    setDataLoading(true)
    try {
      CallAPI(endpoint.allCampaigns,{
        userId:id
      }).then((res)=>{
        setCampaigns(res?.finalData)
        setDataLoading(false)
      })
    } catch (error) {
      console.log(error);
    }
  };

  const setAlphaTesting=(id, type)=>{
    try {
      CallAPI(endpoint.setAlphaTesting,{
        type:type, 
        campaign_id:id
      }).then((res)=>{
        if(res?.code===200){
          toast.success(res?.message)
          campaigns.filter((campaign)=> {
            if(campaign.id===id){
              campaign.testing_type="alpha"
              setCampaigns([...campaigns])
            }
          })
        }
      })
    } catch (error) {
      console.log("Error Occured>>",error)
    }
  }

  const setBetaTesting=(id, type)=>{
    try {
      CallAPI(endpoint.setBetaTesting,{
        campaign_id:id,
        type:type
      }).then((res)=>{
        if(res?.code===200){
          campaigns.filter((campaign)=> {
            if(campaign.id===id){
              campaign.testing_type="beta"
              setCampaigns([...campaigns])
            }
          })
          toast.success(res?.message)
        }
      })
    } catch (error) {
      console.log("Error Occured>>",error)
    }
  }

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("userData"));
    fetchAllCampaigns(user.id);
  }, []);

  useEffect(()=>{
    campaigns.filter((campaign)=>{
      if(campaign.type==="whatsapp"){
        setIsWhatsappCampaignData(true)
      }
      if(campaign.type==="linkedin"){
        setIsLinkedinCampaignData(true)
      }
      if(campaign.type==="email"){
        setIsEmailCampaignData(true)
      }
      if(campaign.type==="facebook"){
        setIsFacebookCampaignData(true)
      }
      if(campaign.type==="twitter"){
        setIsTwitterCampaignData(true)
      }
      if(campaign.type==="instagram"){
        setIsInstagramCampaignData(true)
      }

    })
  },[campaigns])




  // const campaigns = [
  //   { id: 1, platform: "Whatsapp", imgSrc: img4, title: "Health & Wellness Hub", description: "Campaign dedicated to promoting a healthier lifestyle.", name: "Jaane Cooper", leads: 20, deliver: 10, seen: 8, clicked: 6 },
  //   { id: 2, platform: "Facebook", imgSrc: img4, title: "Fitness Frenzy", description: "Campaign promoting daily fitness routines.", name: "John Doe", leads: 25, deliver: 15, seen: 12, clicked: 9 },
  //   { id: 3, platform: "Whatsapp", imgSrc: img4, title: "Nutritional Nuggets", description: "Campaign sharing healthy eating tips.", name: "Emily Smith", leads: 30, deliver: 20, seen: 18, clicked: 15 },
  // ];

  return (
    <>
      <Box width={"100%"} height={"100%"} display={"flex"}>
        <Box width={"100%"} height={"auto"} p={"0px 10px"}>
          <Box display={"flex"} width={"100%"}  justifyContent={"space-between"} flexDir={{ base: "column", sm: "column", md: "column", lg: "row" }}>
            <Text fontSize={"24px"} color={"#000000"} fontWeight={"700"}>Campaign</Text>
            <Box width={{ base: "100%", sm: "100%", md: "100%", lg: "70%" }} bgImage={"https://www.shutterstock.com/shutterstock/photos/2281284523/display_1500/stock-vector-abstract-white-background-minimal-geometric-white-light-background-abstract-design-2281284523.jpg"} bgPosition={"center"} bgSize={"cover"} borderRadius={"6px"} height={"100px"} display={"flex"} alignItems={"center"} justifyContent={"space-between"} p={"20px"}>
              <Text className={Style?.textBottomMargin} color={"red.500"} fontWeight={"700"} fontSize={"16px"} width={"18%"} display={{ base: "none", sm: "none", md: "block", lg: "block" }}>Boost your business The sky's the limit!</Text>
              <Button bg={"red.500"} border={"2px solid white"} color={"white"} borderRadius={"6px"} _hover={{ bg: "red.500" }} onClick={onOpen}>Create Campaign <Image src={img2} width={"15px"} height={"15px"} ml={"10px"} /></Button>
              <Popover isOpen={isOpen} onClose={onClose}>
              <PopoverContent
                w={isMinimized ? "300px" : "600px"}
                bg={"white"}
                h={isMinimized ? "auto" : "auto"}
                overflowY="auto"
                ml={{base:"50%",md:"55%",lg:"70%",xl:"90%"}}
                mt={"210px"}
              >
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader display="flex" justifyContent="space-between">
                  <Text fontSize={"20px"} color={"#000000"} fontWeight={"600"}>
                    Campaign Options
                  </Text>
                  <Box>
                    <IconButton icon={<FaTimes />} onClick={onClose} aria-label="Close" />
                  </Box>
                </PopoverHeader>
                {!isMinimized && (
                  <PopoverBody>
                    <Box
                      display={"flex"}
                      flexDir="row"
                      flexWrap={"wrap"}
                      justifyContent={"space-between"}
                    >
                      <CampaignOption
                        title="Email"
                        icon={<SiGmail size={"50"} color="#D1202A" />}
                        onClick={() => navigate("/EmailCampaign")}
                      />
                      <CampaignOption
                        title="WhatsApp"
                        icon={<PiWhatsappLogoBold size={"50"} color="#D1202A" />}
                        onClick={() => navigate("/WhatsappCampaign")}
                      />
                      <CampaignOption
                        title="Facebook"
                        icon={<FaFacebook size={"50"} color="#D1202A" />}
                        onClick={() => navigate("/FacebookCampaign")}
                      />
                      <CampaignOption
                        title="Linkedin"
                        icon={<FaLinkedinIn size={"50"} color="#D1202A" />}
                        onClick={() => navigate("/LinkedinCampaign")}
                      />
                      <CampaignOption
                        title="Instagram"
                        icon={<GrInstagram size={"50"} color="#D1202A" />}
                        onClick={() => navigate("/InstagramCampaign")}
                      />
                      <CampaignOption
                        title="Twitter"
                        icon={<FaXTwitter size={"50"} color="#D1202A" />}
                        onClick={() => navigate("/TwitterCampaign")}
                      />
                    </Box>
                  </PopoverBody>
                )}
              </PopoverContent>
            </Popover>
            </Box>
          </Box>
          <Box width={"100%"}>
            {(isWhatsappCampaignData) && <Text className={Style?.textBottomMargin} fontSize={"18px"} color={"#000000"} fontWeight={"500"} p={"20px 0px"}>WhatsApp</Text>}
            <Box width={"100%"} height={"auto"} display={"flex"} flexWrap={"wrap"}>
              { 
                campaigns?.filter((wcampaign)=> wcampaign?.type==="whatsapp")?.map((campaign)=> {
                return(
                  <>
                    <CampaignCard
                      id={campaign.id}
                      imgSrc={userImage}
                      campaign_name={campaign?.campaign_name}
                      campaign_type={campaign?.type}
                      Campaign_objective={campaign?.campaign_objective}
                      username={"John Doe"}
                      setAlphaTesting = {setAlphaTesting}
                      setBetaTesting = {setBetaTesting}
                      testing_type= {campaign?.testing_type}
                    />
                  </>
                )
              })
              }
              {
                (loadingData===true) && 
                <div>
                  <Image
                    src='https://source.unsplash.com/random/800x600'
                    fallback={<Shimmer width={1250} height={300} />}
                  />
                </div>
              }
            </Box>
            {(isFacebookCampaignData) && <Text className={Style?.textBottomMargin} fontSize={"18px"} color={"#000000"} fontWeight={"500"} p={"20px 0px"}>Facebook</Text>}
            <Box width={"100%"} height={"auto"} display={"flex"} flexWrap={"wrap"} >
              {campaigns?.filter(fcampaign => fcampaign?.type === "facebook")?.map(campaign => (
                <CampaignCard
                  id={campaign.id}
                  imgSrc={img4}
                  campaign_name={campaign?.campaign_name}
                  campaign_type={campaign?.type}
                  Campaign_objective={campaign?.campaign_objective}
                  username={"John Doe"}
                  setAlphaTesting = {setAlphaTesting}
                  setBetaTesting = {setBetaTesting}
                  testing_type= {campaign?.testing_type}
                />
              ))}
              {
                (loadingData===true) && 
                <div>
                  <Image
                    src='https://source.unsplash.com/random/800x600'
                    fallback={<Shimmer width={1250} height={300} />}
                  />
                </div>
              }
            </Box>
            {(isLinkedinCampaignData) && <Text className={Style?.textBottomMargin} fontSize={"18px"} color={"#000000"} fontWeight={"500"} p={"20px 0px"}>Linkedin</Text>}
            <Box width={"100%"} height={"auto"} display={"flex"} flexWrap={"wrap"} >
              {campaigns?.filter(lcampaign => lcampaign?.type === "linkedin")?.map(campaign => (
                <CampaignCard
                  id={campaign.id}
                  imgSrc={img4}
                  campaign_name={campaign?.campaign_name}
                  campaign_type={campaign?.type}
                  Campaign_objective={campaign?.campaign_objective}
                  username={"John Doe"}
                  setAlphaTesting = {setAlphaTesting}
                  setBetaTesting = {setBetaTesting}
                  testing_type= {campaign?.testing_type}

                />
              ))}
              {
                (loadingData===true) && 
                <div>
                  <Image
                    src='https://source.unsplash.com/random/800x600'
                    fallback={<Shimmer width={1250} height={300} />}
                  />
                </div>
              }
            </Box>
            {(isInstagramCampaignData) && <Text className={Style?.textBottomMargin} fontSize={"18px"} color={"#000000"} fontWeight={"500"} p={"20px 0px"}>Instagram</Text>}
            <Box width={"100%"} height={"auto"} display={"flex"} flexWrap={"wrap"} >
              {campaigns?.filter(icampaign => icampaign?.type === "instagram")?.map(campaign => (
                <CampaignCard
                  id={campaign.id}
                  imgSrc={img4}
                  campaign_name={campaign?.campaign_name}
                  campaign_type={campaign?.type}
                  Campaign_objective={campaign?.campaign_objective}
                  username={"John Doe"}
                  setAlphaTesting = {setAlphaTesting}
                  setBetaTesting = {setBetaTesting}
                  testing_type= {campaign?.testing_type}

                />
              ))}
              {
                (loadingData===true) && 
                <div>
                  <Image
                    src='https://source.unsplash.com/random/800x600'
                    fallback={<Shimmer width={1250} height={300} />}
                  />
                </div>
              }
            </Box>
            {(isTwitterCampaignData) && <Text className={Style?.textBottomMargin} fontSize={"18px"} color={"#000000"} fontWeight={"500"} p={"20px 0px"}>Twitter</Text>}
            <Box width={"100%"} height={"auto"} display={"flex"} flexWrap={"wrap"} >
              {campaigns?.filter(tcampaign => tcampaign?.type === "twitter")?.map(campaign => (
                <CampaignCard
                  id={campaign.id}
                  imgSrc={img4}
                  campaign_name={campaign?.campaign_name}
                  campaign_type={campaign?.type}
                  Campaign_objective={campaign?.campaign_objective}
                  username={"John Doe"}
                  setAlphaTesting = {setAlphaTesting}
                  setBetaTesting = {setBetaTesting}
                  testing_type= {campaign?.testing_type}

                />
              ))}
              {
                (loadingData===true) && 
                <div>
                  <Image
                    src='https://source.unsplash.com/random/800x600'
                    fallback={<Shimmer width={1250} height={300} />}
                  />
                </div>
              }
            </Box>
            {(isEmailCampaignData) && <Text className={Style?.textBottomMargin} fontSize={"18px"} color={"#000000"} fontWeight={"500"} p={"20px 0px"}>Email</Text>}
            <Box width={"100%"} height={"auto"} display={"flex"} flexWrap={"wrap"} >
              {campaigns?.filter(ecampaign => ecampaign?.type === "email")?.map(campaign => (
                <CampaignCard
                  id={campaign.id}
                  imgSrc={img4}
                  campaign_name={campaign?.campaign_name}
                  campaign_type={campaign?.type}
                  Campaign_objective={campaign?.campaign_objective}
                  username={"John Doe"}
                  setAlphaTesting = {setAlphaTesting}
                  setBetaTesting = {setBetaTesting}
                  testing_type= {campaign?.testing_type}

                />
              ))}
              {
                (loadingData===true) && 
                <div>
                  <Image
                    src='https://source.unsplash.com/random/800x600'
                    fallback={<Shimmer width={1250} height={300} />}
                    m={"20px"}
                  />
                </div>
              }
            </Box>
          </Box>
          { (campaigns.length<=0) && (loadingData===false)   &&    
            <>            
              <CampaignCard
                id={0}
                imgSrc={userImage}
                campaign_name={"Whatsapp Campaign"}
                campaign_type={"whatsapp"}
                Campaign_objective={"To Create a whatsapp campaign"}
                username={"Harrix Jox"}
                notFunctional = {false}
              />
              <CampaignCard
                id={0}
                imgSrc={userImage}
                campaign_name={"Facebook Campaign"}
                campaign_type={"facebook"}
                Campaign_objective={"To Create a Facebook campaign"}
                username={"Kamron Zen"}
                notFunctional={false}
              />
              <CampaignCard
                id={0}
                imgSrc={userImage}
                campaign_name={"My Linkedin Campaign"}
                campaign_type={"linked"}
                Campaign_objective={"To Create a linkedin campaign"}
                username={"Brox joe"}
                notFunctional = {false}
              />
            </>
          }
        </Box>
      </Box>
      <ToastContainer/>
    </>
  );
};

function CampaignOption({ title, icon, description, onClick }) {
  return (
    <Box
      cursor={"pointer"}
      onClick={onClick}
      width={{ base: "100%", sm: "100%", md: "45%", lg: "28%" }}
      bg={"#F5F5F5"}
      borderRadius={"6px"}
      boxShadow={"1px 2px 10px lightgray"}
      p={"10px"}
      m={"10px"}
      display={"flex"}
      flexDir={"column"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      {icon}
      <Text fontSize={"20px"} color={"#000000"} fontWeight={"700"}>{title}</Text>
      <Text fontSize={"14px"} color={"gray.600"}>{description}</Text>
    </Box>
  );
}
