import axios from 'axios';
import { toast } from 'react-toastify';

const Backend_URL = 'https://apialpha.marcomusa.com';
// const Backend_URL = 'http://localhost:5223';

const axiosInstance = axios.create({
  baseURL: Backend_URL,
  headers: { 'x-access-token': localStorage.getItem('userToken') },
});

export const post = (url, data) => {
  return axiosInstance.post(url, data);
};

export const CallAPI = async (url, data) => {
  try {
    const response = await axiosInstance.post(url, data);
    return response.data;
  } catch (error) {
    console.error('API Call Error:', error.response || error.message);
    // console.error(error.response.data.message);
    toast.error(error.response.data.message);
  }
};

export const SERVER_URL = Backend_URL;
