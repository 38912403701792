import React from 'react';
import SearchInput from './SearchInput';
import Conversations from './Conversations';
import {
  Box,
  Input,
  VStack,
  Text,
  Avatar,
  HStack,
  Badge,
  Flex,
} from '@chakra-ui/react';
const Chats = () => {
  const customScrollbarStyles = {
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#f0f0f0',
    },
    scrollbarWidth: 'thin',
    scrollbarColor: '#888 #f0f0f0',
  };
  return (
    <Box
      w="30%"
      border="1px"
      borderLeft={'0px'}
      borderBottom={'0px'}
      borderTop={'0px'}
      borderColor="#CCCBCB"
      height="95vh"
      bg="#FFFFFF"
      p={4}
      overflowY={'scroll'}
      sx={customScrollbarStyles}
    >
      <Flex
        flexDirection="column"
        gap={30}
        justifyContent="center"
        alignItems="center"
      >
        <SearchInput />
        <Conversations />
      </Flex>
    </Box>
  );
};

export default Chats;
