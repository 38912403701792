import { useState, useEffect } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  Stack,
  Checkbox,
  InputGroup,
  InputLeftElement,

} from "@chakra-ui/react";
import { motion } from "framer-motion";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const countryOptions = [
  { value: "+1", label: "🇺🇸(+1)" },
  { value: "+44", label: "🇬🇧(+44)" },
  { value: "+91", label: "🇮🇳(+91)" },
  { value: "+61", label: "🇦🇺(+61)" },
  { value: "+81", label: "🇯🇵(+81)" },
];

export default function FormCard() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    phone: "",
    countryCode: { value: "+91", label: "Ind (+91)" },
    agree: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSelectChange = (selectedOption) => {
    setFormData((prevData) => ({
      ...prevData,
      countryCode: selectedOption,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    localStorage.setItem("formData", JSON.stringify(formData));
    toast.success("Form submitted successfully!");
    setFormData({
      name: "",
      email: "",
      password: "",
      phone: "",
      countryCode: { value: "+1", label: "🇺🇸 United States (+1)" },
      agree: false,
    });
  };

  useEffect(() => {
    const savedFormData = localStorage.getItem("formData");
    if (savedFormData) {
      setFormData(JSON.parse(savedFormData));
    }
  }, []);

  const formVariants = {
    hidden: { y: "-100vh", opacity: 0 },
    visible: { y: 0, opacity: 1 },
  };

  return (
    <Box>
      <ToastContainer />
      <motion.div
        initial="hidden"
        animate="visible"
        variants={formVariants}
        transition={{ type: "spring", stiffness: 50 }}
      >
        <Box
          bg="#CEB8F5"
          p={8}
          rounded="lg"
          boxShadow="lg"
          padding="50px"
          borderRadius="8px"
        >
          <form onSubmit={handleSubmit}>
            <Stack spacing={4}>
              <FormControl id="name" isRequired>
                <FormLabel>Name</FormLabel>
                <Input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Enter your name"
                  bg="white"
                />
              </FormControl>

              <FormControl id="email" isRequired>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Enter your email"
                  bg="white"
                />
              </FormControl>

              <FormControl id="password" isRequired>
                <FormLabel>Password</FormLabel>
                <Input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  placeholder="Enter your password"
                  bg="white"
                />
              </FormControl>

              <FormControl id="phone" isRequired>
                <FormLabel>Phone Number</FormLabel>
                <InputGroup>
                  <InputLeftElement width="150px">
                    <Select
                      options={countryOptions}
                      value={formData.countryCode}
                      onChange={handleSelectChange}
                      styles={{
                        control: (base) => ({
                          ...base,
                          minWidth: 150,
                          backgroundColor: 'white',
                          border: 'none',
                          boxShadow: 'none',
                          height: '40px',
                        }),
                        singleValue: (base) => ({
                          ...base,
                          display: 'flex',
                          alignItems: 'center',
                        }),
                        menu: (base) => ({
                          ...base,
                          backgroundColor: 'white',
                          zIndex: 9999
                        }),
                      }}
                      isSearchable={false}
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                    />
                  </InputLeftElement>
                  <Input
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    placeholder="Enter your phone number"
                    bg="white"
                    pl="160px"
                  />
                </InputGroup>
              </FormControl>

              <FormControl id="agree" isRequired>
                <Checkbox
                  name="agree"
                  isChecked={formData.agree}
                  onChange={handleChange}
                >
                  I agree to the Privacy Policy and Terms
                </Checkbox>
              </FormControl>

              <Button type="submit" colorScheme="red" size="lg" mt={4}>
                Get Started
              </Button>
            </Stack>
          </form>
        </Box>
      </motion.div>
    </Box>
  );
}
