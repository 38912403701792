const endpoints = {
  signup: '/api/user/register',
  signin: '/api/user/login',
  googleSignIn: '/api/auth/google-signin',
  emailverify: '/api/user/emailverify',
  sendotp: '/api/user/send-otp',
  resetpassword: '/api/user/change-password',
  linkedinCampaign: '/api/user/linkedin-campaign',
  emailCampaign: '/api/user/email-campaign',
  whatsapp_campaign: '/api/user/whatsapp-campaign',
  allCampaigns: '/api/user/all-campaigns',
  twitterCampaign: '/api/user/twitter-campaign',
  instagram_campaign: '/api/user/instagram-campaign',
  facebook_campaign: '/api/user/facebook-campaign',
  updateUser: '/api/user/update-user-profile',
  newsletterSubscriber: '/api/user/newsletter-subscribers',
  findUser: '/api/user/find-user',
  createLead:"/api/user/create-lead",
  setAlphaTesting:'/api/user/set-alpha-testing-type',
  setBetaTesting:'/api/user/set-beta-testing-type',
  campaignDetails:'/api/user/campaign-detail',
  findLeads:'/api/user/find-leads'
};

export default endpoints;
