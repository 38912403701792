import React, { useState } from 'react';
import {
  Stack,
  FormControl,
  Input,
  Button,
  useColorModeValue,
  Heading,
  Text,
  Container,
  Flex,
  Box,
  Icon,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import { FaEnvelope } from 'react-icons/fa';
import endpoint from '../../Services/endpoint';
import { CallAPI } from '../../Services/service';
import { ToastContainer, toast } from 'react-toastify';

export default function Newsletter() {
  const [email, setEmail] = useState('');
  const [state, setState] = useState('initial');
  const [error, setError] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setError(false);
    setState('submitting');

    setTimeout(() => {
      if (email === 'Marcomusa@gmail.com') {
        setError(true);
        setState('initial');
        return;
      }

      CallAPI(endpoint.newsletterSubscriber, {
        email_id: email,
      }).then((res) => {
        if (res?.code === 200) {
          toast.success(res?.message);
        }
      });

      setState('success');
    }, 1000);
  };

  return (
    <>
      <Box
        minH={'60vh'}
        w={'100%'}
        bgImage="url('https://via.placeholder.com/1920x600?text=Marketing+Background')"
        bgPosition="center"
        bgSize="cover"
        bgRepeat="no-repeat"
        display="flex"
        alignItems="center"
        justifyContent="center"
        p={6}
        color={'white'}
        bg={useColorModeValue('gray.100', 'gray.700')}
      >
        <Container
          maxW={'80%'}
          bgGradient="linear(to-r, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9))"
          boxShadow={'1xl'}
          rounded={'xl'}
          p={10}
          align={'center'}
          transition="transform 0.3s ease-in-out, background-color 0.3s ease-in-out"
          _hover={{
            transform: 'scale(1.05)',
            bgGradient: 'linear(to-r, rgba(255, 240, 240, 0.95), rgba(255, 240, 240, 0.95))',
            borderColor:"red"
          }}
        >
          <Flex align={'center'} justify={'center'} mb={4}>
            <Icon as={FaEnvelope} w={12} h={12} color={'red.500'}/>
          </Flex>
          <Heading
            as={'h2'}
            fontSize={{ base: '2xl', sm: '3xl'}}
            textAlign={'center'}
            mb={6}
            color={useColorModeValue('gray.800', 'gray.100')}
          >
            Join Our Community
          </Heading>
          <Text mb={6} color={useColorModeValue('gray.600', 'gray.300')}>
            Get the latest updates right in your inbox. No spam, we promise!
          </Text>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            as={'form'}
            spacing={'12px'}
            onSubmit={handleSubmit}
          >
            <FormControl>
              <Input
                variant={'solid'}
                borderWidth={2}
                color={'gray.800'}
                _placeholder={{
                  color: 'gray.400',
                }}
                borderColor={useColorModeValue('gray.400', 'gray.600')}
                id={'email'}
                type={'email'}
                required
                placeholder={'Your Email'}
                aria-label={'Your Email'}
                value={email}
                disabled={state !== 'initial'}
                onChange={(e) => setEmail(e.target.value)}
                bg={useColorModeValue('white', 'gray.700')}
              />
            </FormControl>
            <FormControl w={{ base: '100%', md: '30%' }}>
              <Button
                colorScheme={'red'}
                isLoading={state === 'submitting'}
                w="100%"
                type={state === 'success' ? 'button' : 'submit'}
                _hover={{
                  bg: 'red.500',
                }}
              >
                {state === 'success' ? <CheckIcon /> : 'Join Now'}
              </Button>
            </FormControl>
          </Stack>
          <Text
            mt={4}
            textAlign={'center'}
            color={error ? 'red.500' : 'gray.500'}
            fontSize={'sm'}
          >
            {error
              ? 'Oh no, an error occurred! 😢 Please try again later.'
              : "You won't receive any spam! ✌️"}
          </Text>
        </Container>
      </Box>
      <ToastContainer />
    </>
  );
}
