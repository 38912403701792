import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import HeaderChat from './HeaderChat';
import Messages from './Messages';
const MessageContainer = () => {
  return (
    <Box
      w="50%"
      border="1px"
      borderTop={'0px'}
      p={0}
      m={0}
      borderColor="#CCCBCB"
      height="95vh"
      borderBottom={'0px'}
      bg="#FFFFFF"
    >
      <Flex w="100%" flexDirection="column">
        <HeaderChat />
        <Messages />
      </Flex>
    </Box>
  );
};

export default MessageContainer;
