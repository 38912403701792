import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { SiGmail } from "react-icons/si";
import { PiWhatsappLogoBold } from "react-icons/pi";
import { GrTest } from "react-icons/gr";
import { FaFacebook, FaTimes, FaWindowMinimize, FaWindowMaximize } from "react-icons/fa";
import { GrInstagram } from "react-icons/gr";
import { FaXTwitter, FaLinkedinIn } from "react-icons/fa6";
import { 
  Box, 
  Text, 
  IconButton, 
  Popover, 
  PopoverTrigger, 
  PopoverContent, 
  PopoverHeader, 
  PopoverBody, 
  PopoverCloseButton, 
  PopoverArrow, 
  Button, 
  useDisclosure 
} from '@chakra-ui/react';

export default function CampaignType() {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const navigate = useNavigate();
  const [isMinimized, setIsMinimized] = useState(false);

  return (
    <>
      <Box w={"100%"} h={"auto"} p={"25px"}>
        <Text fontSize={"30px"} color={"#000000"} fontWeight={"500"}>Campaign Type</Text>
        
        <Popover isOpen={isOpen} onClose={onClose} placement="right">
          <PopoverTrigger>
            <Button onClick={onOpen}>Open Campaign Options</Button>
          </PopoverTrigger>
          <PopoverContent w={isMinimized ? "300px" : "1000px"} h={isMinimized ? "60px" : "600px"} overflowY="auto">
            <PopoverArrow />
            <PopoverCloseButton />

            <PopoverHeader display="flex" justifyContent="space-between">
              <Text fontSize={"20px"}>Campaign Options</Text>
              <Box>
                <IconButton 
                  icon={isMinimized ? <FaWindowMaximize /> : <FaWindowMinimize />} 
                  onClick={() => setIsMinimized(!isMinimized)} 
                  aria-label="Minimize/Maximize" 
                  mr={2}
                />
                <IconButton icon={<FaTimes />} onClick={onClose} aria-label="Close" />
              </Box>
            </PopoverHeader>

            {!isMinimized && (
              <PopoverBody>
                <Box
                  display={"flex"}
                  flexDir={{ base: "column", sm: "column", md: "row", lg: "row" }}
                  flexWrap={"wrap"}
                  justifyContent={"space-between"}
                >
                  <CampaignOption 
                    title="Email" 
                    icon={<SiGmail size={"50"} color="#ECECEC" />} 
                    description="Create a regular email campaign to your target audience."
                    onClick={() => navigate("/EmailCampaign")}
                  />
                  <CampaignOption 
                    title="A|B Testing" 
                    icon={<GrTest size={"50"} color="#ECECEC" />} 
                    description="Send two different versions of your email campaign to see which one performs best."
                    onClick={() => navigate("/ABtesting")}
                  />
                  <CampaignOption 
                    title="WhatsApp" 
                    icon={<PiWhatsappLogoBold size={"50"} color='#ECECEC' />}
                    description="Create a regular WhatsApp campaign to your target audience."
                    onClick={() => navigate("/WhatsappCampaign")}
                  />
                  <CampaignOption 
                    title="Facebook" 
                    icon={<FaFacebook size={"50"} color='#ECECEC' />}
                    description="Create a regular Facebook campaign to your target audience."
                    onClick={() => navigate("/FacebookCampaign")}
                  />
                  <CampaignOption 
                    title="Linkedin" 
                    icon={<FaLinkedinIn size={"50"} color='#ECECEC' />}
                    description="Create a regular LinkedIn campaign to your target audience."
                    onClick={() => navigate("/LinkedinCampaign")}
                  />
                  <CampaignOption 
                    title="Instagram" 
                    icon={<GrInstagram size={"50"} color='#ECECEC' />}
                    description="Create a regular Instagram campaign to your target audience."
                  />
                  <CampaignOption 
                    title="Twitter" 
                    icon={<FaXTwitter size={"50"} color='#ECECEC' />}
                    description="Create a regular Twitter campaign to your target audience."
                  />
                </Box>
              </PopoverBody>
            )}
          </PopoverContent>
        </Popover>
      </Box>
    </>
  );
}

function CampaignOption({ title, icon, description, onClick }) {
  return (
    <Box
      cursor={"pointer"}
      mt={"20px"}
      w={{ base: "100%", sm: "100%", md: "50%", lg: "30%" }}
      height={"270px"}
      bg={"white"}
      borderRadius={"6px"}
      boxShadow={"1px 2px 10px lightgray"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      flexDir={"column"}
      _hover={{ border: "1px solid red", marginTop: "15px", transition: "0.2s" }}
      onClick={onClick}
    >
      <Text fontSize={"30px"} color={"red.400"} fontWeight={"700"}>{title}</Text>
      {icon}
      <Text w={"70%"} textAlign={"center"} fontSize={"16px"} color={"#000000"} fontWeight={"500"} mt={"10px"}>{description}</Text>
    </Box>
  );
}
