import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import Img1 from '../LeadManagementPage/Logos/draft-line 1.png';
import Img2 from '../LeadManagementPage/Logos/exchange-2-line 1.png';
import Img3 from '../LeadManagementPage/Logos/eye-line 1.png';
import Style from '../LeadManagementPage/LeadManagement.module.css';
import { CallAPI } from '../../Services/service';
import endpoint from '../../Services/endpoint';
import PreviewTemplate from './PreviewTemplate';
import { ToastContainer, toast } from 'react-toastify';

import {
  Box,
  Text,
  Button,
  Image,
  Input,
  Select,
  Flex,
  useBreakpointValue,
  FormErrorMessage,
  useDisclosure,
  IconButton,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import Sidebar from "../Sidebar/Sidebar";

export default function EmailCampaign() {

  const [showPreview, setShowPreview] = useState(false);
  const [allFiledsFilled, setAllFieldsFilled] = useState(false);
  const [isSavedCampaign, setIsSavedCampaign] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpenDraftDrawer, onOpenDraftDrawer, onCloseDraftDrawer } =
    useDisclosure();
  const isDesktop = useBreakpointValue({ base: false, md: true });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [date, setDate] = useState(new Date());
  const HandleDateOnChange = (value) => {
    setDate(value);
    console.log(value);
  };
  const userDetails = JSON.parse(localStorage.getItem("userData"))

  // const toast = useToast();
  let uid = new Date().getTime().toString(36);

  const AddNewCampaignForm = () => {
    setFormData([
      ...formData,
      {
        userId:userDetails.id,
        campaign_name: '',
        campaign_objective: '',
        sender_name: '',
        sender_email: '',
        subject_line: '',
        headline: '',
        body_text: '',
        start_day: 'DD',
        start_month: 'MM',
        start_Year: 'YY',
        end_day: 'DD',
        end_month: 'MM',
        end_year: 'YY',
        hour: '00',
        minute: '00',
        timeday: 'AM',
        budget_type: '',
        price: '',
        campaignType: 'Email',
      },
    ]);
    toast({
      title: 'Added',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  };

  const [formData, setFormData] = useState([
    {

      userId:userDetails.id,
      campaign_name: '',
      campaign_objective: '',
      sender_name: '',
      sender_email: '',
      subject_line: '',
      headline: '',
      body_text: '',
      start_day: 'DD',
      start_month: 'MM',
      start_year: 'YY',
      end_day: 'DD',
      end_month: 'MM',
      end_year: 'YY',
      hour: '00',
      minute: '00',
      timeday: 'AM',
      budget_type: '',
      price: '',
    },
  ]);

  const DeleteCampaign = (formIndex) => {
    if (formData.length > 1) {
      const newForms = formData.filter((item, index) => index != formIndex);
      setFormData(newForms);
      toast({
        title: 'Deleted',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } else {
      toast({
        title: 'Default Form can not be deleted',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const HandleOnChange = (e, i) => {
    const { name, value } = e.target;
    const onChangeValue = [...formData];
    onChangeValue[i][name] = value;
    setFormData(onChangeValue);
  };

  // const SaveCampaignData = (e) => {
  //   e.preventDefault();
  //   formData.forEach((obj) => {
  //     for (const [key, value] of Object.entries(obj)) {
  //       console.log(`${key}: ${value}`);
  //       if (value === "") {
  //         setAllFieldsFilled(true);
  //         setIsSavedCampaign(false);
  //         break;
  //       }
  //       if (value !== "") {
  //         setAllFieldsFilled(false);
  //         setIsSavedCampaign(true);
  //       }
  //     }
  //     saveInDB();
  //   });
  // };

  const SaveCampaignData = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setAllFieldsFilled(false);
      setIsSavedCampaign(true);
      console.log(isSavedCampaign);
    } else {
      setAllFieldsFilled(true);
      setIsSavedCampaign(false);
      toast.error('Please fill in all required fields.');
    }
  };

  const validateForm = () => {
    const newErrors = {};
    console.log(formData);
    formData.forEach((item, index) => {
      Object.keys(item).forEach((key) => {
        if (!item[key] && key !== 'id') {
          // Skip 'id' field
          newErrors[`error_${index}_${key}`] = `${key} is required.`;
        }
      });
    });

    setErrors(newErrors);
    console.log(Object.keys(newErrors).length === 0);
    return Object.keys(newErrors).length === 0;
  };
  useEffect(() => {
    console.log('isSavedCampaign', isSavedCampaign);
    if (isSavedCampaign) {
      saveInDB();
    }
  }, [isSavedCampaign]);
  const saveInDB = () => {
    console.log(formData);

    try {
      CallAPI(endpoint.emailCampaign, formData)
        .then((res) => {
          console.log(res);
          if (res?.status?.code === 200) {
            toast.success(res?.status?.message);
          }
        })
        .catch((error) => {
          toast.error('something went wrong');
          console.log(error);
        });
    } catch (error) {
      console.log('Error>>', error);
    }
  };

  const SaveDraft = (e) => {
    e.preventDefault();
    const finalData = JSON.stringify(formData);
    localStorage.setItem('DraftItems', finalData);
    toast({
      title: 'Draft Saved Successfully',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <>
      <Box display={"flex"}>
      <Box w={"100%"} h={"auto"}>
        {/* *******************************TOP BUTTONS***************************** */}

        {/* <Box
          width={{ base: '70%', md: '93%' }}
          display={'flex'}
          alignItems={'center'}
        >
          {isDesktop ? (
            <Box w={'100%'} display={'flex'} p={'25px'}>
              <Button bg={'#EBEBEB'} borderRadius="10px" onClick={SaveDraft}>
                <Image src={Img1} height="16px" mr={'10px'} />
                <span color={'#000000'} fontSize={'16px'}>
                  Save Draft
                </span>
              </Button>
              <Button bg={'#EBEBEB'} m={'0px 20px'} borderRadius="10px">
                <Image src={Img2} height="16px" mr={'10px'} />
                <span fontSize={'16px'} color={'#000000'}>
                  Change Template
                </span>
              </Button>
              <Button
                bg={'#EBEBEB'}
                borderRadius="10px"
                onClick={() => setShowPreview(true)}
              >
                <Image src={Img3} height="16px" mr={'10px'} />
                <span fontSize={'16px'} color={'#000000'}>
                  Email Preview
                </span>
              </Button>
              <Button
                bg={'#EBEBEB'}
                borderRadius="10px"
                ml={'20px'}
                onClick={() => navigate('/LeadManagement')}
              >
                <span fontSize={'16px'} color={'#000000'}>
                  All Campaigns
                </span>
              </Button>
              <Button
                bg={'#EBEBEB'}
                borderRadius="10px"
                ml={'20px'}
                onClick={() => navigate('/CampaignDraft')}
              >
                <span fontSize={'16px'} color={'#000000'}>
                  All Drafts
                </span>
              </Button>
            </Box>
          ) : (
            <IconButton
              icon={<HamburgerIcon />}
              aria-label="Menu"
              variant="outline"
              onClick={onOpen}
              ml={'25px'}
              mt={'5px'}
            />
          )}
        </Box>

        {!isDesktop && (
          <Drawer placement="right" isOpen={isOpen} onClose={onClose}>
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerHeader>Options</DrawerHeader>
              <DrawerBody>
                <Button mb={'5px'} w={'100%'}>
                  <Image src={Img1} height="16px" mr={'10px'} />
                  <span color={'#000000'} fontSize={'16px'}>
                    Save Draft
                  </span>
                </Button>
                <Button mb={'5px'} w={'100%'}>
                  <Image src={Img2} height="16px" mr={'10px'} />
                  <span fontSize={'16px'} color={'#000000'}>
                    Change Template
                  </span>
                </Button>
                <Button
                  mb={'5px'}
                  w={'100%'}
                  onClick={() => setShowPreview(true)}
                >
                  <Image src={Img3} height="16px" mr={'10px'} width={'auto'} />
                  <span fontSize={'16px'} color={'#000000'}>
                    Email Preview
                  </span>
                </Button>
                <Button
                  mb={'5px'}
                  w={'100%'}
                  onClick={() => navigate('/LeadManagement')}
                >
                  <span fontSize={'16px'} color={'#000000'}>
                    All Campaigns
                  </span>
                </Button>
                <Button
                  mb={'5px'}
                  w={'100%'}
                  onClick={() => navigate('/CampaignDraft')}
                >
                  <span fontSize={'16px'} color={'#000000'}>
                    All Drafts
                  </span>
                </Button>
              </DrawerBody>
              <DrawerFooter>
                <Button variant="outline" mr={3} onClick={onClose}>
                  Close
                </Button>
              </DrawerFooter>
            </DrawerContent>
          </Drawer>
        )} */}

        {/* **********************************FORM SECTION*************************** */}
        <Text className={Style?.textBottomMargin} pl={"10px"} color="#000000" fontSize={"24px"} fontWeight={"700"} >Email Campaign Form</Text>

        {formData?.map((items, i) => {
          return (
            <>
              <Box
                className={Style?.mainDiv}
                w={'100%'}
                display={'flex'}
                flexDirection={{
                  base: 'column',
                  sm: 'column',
                  md: 'column',
                  lg: 'row',
                }}
                justifyContent={'space-between'}
                p={'0px 10px'}
                pb={'25px'}
                key={items.uniqueID}
              >
                <Box
                  className={Style?.formDiv}
                  width={{ base: '100%', sm: '100%', md: '100%', lg: '40%' }}
                >
                  <Box>
                    <Text
                      fontSize={'24px'}
                      color={'#000000'}
                      fontWeight={'500'}
                    >
                      Campaign Details
                    </Text>
                    <Text
                      fontSize={'18px'}
                      color={'#252B42'}
                      fontWeight={'500'}
                    >
                      Campaign Name *
                    </Text>
                    <Input
                      required
                      type="text"
                      border={'1px solid #E6E6E6'}
                      bg={'#F9F9F9'}
                      borderRadius={'5px'}
                      placeholder="Campaign Name *"
                      name="campaign_name"
                      value={formData.campaign_name}
                      onChange={(e) => HandleOnChange(e, i)}
                      aria-required="true"
                    />
                    {allFiledsFilled && formData[i]?.campaign_name == '' ? (
                      <Text color={'red'}>Campaign name is required.</Text>
                    ) : (
                      ''
                    )}
                    <Text
                      fontSize={'18px'}
                      color={'#252B42'}
                      fontWeight={'500'}
                      pt={'10px'}
                    >
                      Objective *
                    </Text>
                    <Input
                      required
                      type="text"
                      border={'1px solid #E6E6E6'}
                      bg={'#F9F9F9'}
                      borderRadius={'5px'}
                      placeholder="Objective *"
                      name="campaign_objective"
                      value={formData.campaign_objective}
                      onChange={(e) => HandleOnChange(e, i)}
                    />
                    {allFiledsFilled &&
                    formData[i]?.campaign_objective == '' ? (
                      <Text color={'red'}>Campaign objective is required.</Text>
                    ) : (
                      ''
                    )}
                  </Box>
                  <Box mt={'30px'}>
                    <Text
                      fontSize={'24px'}
                      color={'#000000'}
                      fontWeight={'500'}
                    >
                      Email Details
                    </Text>
                    <Text
                      fontSize={'18px'}
                      color={'#252B42'}
                      fontWeight={'500'}
                    >
                      Sender Name *
                    </Text>
                    <Input
                      required
                      type="text"
                      border={'1px solid #E6E6E6'}
                      bg={'#F9F9F9'}
                      borderRadius={'5px'}
                      placeholder="Sender Name *"
                      name="sender_name"
                      value={formData.sender_name}
                      onChange={(e) => HandleOnChange(e, i)}
                    />
                    {allFiledsFilled && formData[i]?.sender_name == '' ? (
                      <Text color={'red'}>Sender name is required</Text>
                    ) : (
                      ''
                    )}
                    <Text
                      fontSize={'18px'}
                      color={'#252B42'}
                      fontWeight={'500'}
                      pt={'10px'}
                    >
                      Sender Email *
                    </Text>
                    <Input
                      required
                      type="email"
                      border={'1px solid #E6E6E6'}
                      bg={'#F9F9F9'}
                      borderRadius={'5px'}
                      placeholder="Sender Email *"
                      name="sender_email"
                      value={formData.sender_email}
                      onChange={(e) => HandleOnChange(e, i)}
                    />
                    {allFiledsFilled && formData[i]?.sender_email == '' ? (
                      <Text color={'red'}>Sender email is required</Text>
                    ) : (
                      ''
                    )}
                    <Text
                      fontSize={'18px'}
                      color={'#252B42'}
                      fontWeight={'500'}
                      pt={'10px'}
                    >
                      Subject Line *
                    </Text>
                    <Input
                      required
                      type="email"
                      border={'1px solid #E6E6E6'}
                      bg={'#F9F9F9'}
                      borderRadius={'5px'}
                      placeholder="Subject Line *"
                      name="subject_line"
                      value={formData.subject_line}
                      onChange={(e) => HandleOnChange(e, i)}
                    />
                    {allFiledsFilled && formData[i]?.subject_line == '' ? (
                      <Text color={'red'}>Sender subject is required</Text>
                    ) : (
                      ''
                    )}
                  </Box>
                  <Box mt={'30px'}>
                    <Text
                      fontSize={'24px'}
                      color={'#000000'}
                      fontWeight={'500'}
                    >
                      Email Content
                    </Text>
                    <Text
                      fontSize={'18px'}
                      color={'#252B42'}
                      fontWeight={'500'}
                    >
                      Headline *
                    </Text>
                    <Input
                      required
                      type="text"
                      border={'1px solid #E6E6E6'}
                      bg={'#F9F9F9'}
                      borderRadius={'5px'}
                      placeholder="Headline *"
                      name="headline"
                      value={formData.headline}
                      onChange={(e) => HandleOnChange(e, i)}
                    />
                    {allFiledsFilled && formData[i]?.headline == '' ? (
                      <Text color={'red'}>Headline is required</Text>
                    ) : (
                      ''
                    )}
                    <Text
                      fontSize={'18px'}
                      color={'#252B42'}
                      fontWeight={'500'}
                      pt={'10px'}
                    >
                      Body Text *
                    </Text>
                    <Input
                      required
                      type="email"
                      border={'1px solid #E6E6E6'}
                      bg={'#F9F9F9'}
                      borderRadius={'5px'}
                      placeholder="Body Text *"
                      name="body_text"
                      value={formData.body_text}
                      onChange={(e) => HandleOnChange(e, i)}
                    />
                    {allFiledsFilled && formData[i]?.body_text == '' ? (
                      <Text color={'red'}>Bodytext is required</Text>
                    ) : (
                      ''
                    )}
                  </Box>
                </Box>

                <Box
                  className={Style?.timerDiv}
                  width={{ base: '100%', sm: '100%', md: '100%', lg: '55%' }}
                >
                  <Text fontSize={'24px'} color={'#000000'} fontWeight={'500'}>
                    Schedule
                  </Text>
                  <Box
                    display={'flex'}
                    width={{ base: '100%', sm: '100%', md: '100%', lg: '90%' }}
                    flexDir={{
                      base: 'column',
                      sm: 'column',
                      md: 'column',
                      lg: 'row',
                    }}
                    height={'auto'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    <Box
                      width={{
                        base: '100%',
                        sm: '100%',
                        md: '100%',
                        lg: '45%',
                      }}
                    >
                      <Text
                        fontSize={'16px'}
                        color={'#000000'}
                        fontWeight={'500'}
                      >
                        Start Date
                      </Text>
                      <Flex justifyContent={'space-between'}>
                        <Select
                          className={Style?.selectOptions}
                          width={'26%'}
                          color={'#8740FB'}
                          fontSize={'16px'}
                          fontWeight={'700'}
                          defaultValue="01"
                          bg={'#F2E3FD'}
                          borderRadius={'6px'}
                          name="start_day"
                          onChange={(e) => HandleOnChange(e, i)}
                        >
                          <option>01</option>
                          <option>02</option>
                          <option>03</option>
                          <option>04</option>
                          <option>05</option>
                          <option>06</option>
                          <option>07</option>
                          <option>08</option>
                          <option>09</option>
                          <option>10</option>
                          <option>11</option>
                          <option>12</option>
                          <option>13</option>
                          <option>14</option>
                          <option>15</option>
                          <option>16</option>
                          <option>17</option>
                          <option>18</option>
                          <option>19</option>
                          <option>20</option>
                          <option>21</option>
                          <option>22</option>
                          <option>23</option>
                          <option>24</option>
                          <option>25</option>
                          <option>26</option>
                          <option>27</option>
                          <option>28</option>
                          <option>29</option>
                          <option>30</option>
                          <option>31</option>
                        </Select>
                        <Select
                          width={'40%'}
                          color={'#8740FB'}
                          fontSize={'16px'}
                          fontWeight={'700'}
                          bg={'#F2E3FD'}
                          borderRadius={'6px'}
                          defaultValue="January"
                          name="start_month"
                          onChange={(e) => HandleOnChange(e, i)}
                        >
                          <option>January</option>
                          <option>February</option>
                          <option>March</option>
                          <option>April</option>
                          <option>May</option>
                          <option>June</option>
                          <option>July</option>
                          <option>August</option>
                          <option>September</option>
                          <option>October</option>
                          <option>November</option>
                          <option>December</option>
                        </Select>
                        <Select
                          width={'30%'}
                          color={'#8740FB'}
                          fontSize={'16px'}
                          fontWeight={'700'}
                          bg={'#F2E3FD'}
                          borderRadius={'6px'}
                          name="start_Year"
                          onChange={(e) => HandleOnChange(e, i)}
                        >
                          <option>2020</option>
                          <option>2021</option>
                          <option>2022</option>
                          <option>2023</option>
                          <option>2024</option>
                          <option>2025</option>
                          <option>2026</option>
                          <option>2027</option>
                          <option>2028</option>
                        </Select>
                      </Flex>
                      <Text
                        fontSize={'16px'}
                        color={'#000000'}
                        fontWeight={'500'}
                        mt={'20px'}
                      >
                        End Date
                      </Text>
                      <Flex justifyContent={'space-between'}>
                        <Select
                          width={'26%'}
                          color={'#8740FB'}
                          fontSize={'16px'}
                          fontWeight={'700'}
                          bg={'#F2E3FD'}
                          borderRadius={'6px'}
                          name="end_day"
                          onChange={(e) => HandleOnChange(e, i)}
                        >
                          <option>01</option>
                          <option>02</option>
                          <option>03</option>
                          <option>04</option>
                          <option>05</option>
                          <option>06</option>
                          <option>07</option>
                          <option>08</option>
                          <option>09</option>
                          <option>10</option>
                          <option>11</option>
                          <option>12</option>
                          <option>13</option>
                          <option>14</option>
                          <option>15</option>
                          <option>16</option>
                          <option>17</option>
                          <option>18</option>
                          <option>19</option>
                          <option>20</option>
                          <option>21</option>
                          <option>22</option>
                          <option>23</option>
                          <option>24</option>
                          <option>25</option>
                          <option>26</option>
                          <option>27</option>
                          <option>28</option>
                          <option>29</option>
                          <option>30</option>
                          <option>31</option>
                        </Select>
                        <Select
                          width={'40%'}
                          color={'#8740FB'}
                          fontSize={'16px'}
                          fontWeight={'700'}
                          bg={'#F2E3FD'}
                          borderRadius={'6px'}
                          name="end_month"
                          onChange={(e) => HandleOnChange(e, i)}
                        >
                          <option>January</option>
                          <option>February</option>
                          <option>March</option>
                          <option>April</option>
                          <option>May</option>
                          <option>June</option>
                          <option>July</option>
                          <option>August</option>
                          <option>September</option>
                          <option>October</option>
                          <option>November</option>
                          <option>December</option>
                        </Select>
                        <Select
                          width={'30%'}
                          color={'#8740FB'}
                          fontSize={'16px'}
                          fontWeight={'700'}
                          bg={'#F2E3FD'}
                          borderRadius={'6px'}
                          name="end_year"
                          onChange={(e) => HandleOnChange(e, i)}
                        >
                          <option>2024</option>
                          <option>2020</option>
                          <option>2021</option>
                          <option>2022</option>
                          <option>2023</option>
                          <option>2024</option>
                          <option>2025</option>
                          <option>2026</option>
                          <option>2027</option>
                          <option>2028</option>
                        </Select>
                      </Flex>

                      <Text
                        fontSize={'16px'}
                        color={'#000000'}
                        fontWeight={'500'}
                        mt={'20px'}
                      >
                        Time
                      </Text>
                      <Box>
                        <Select
                          width={'26%'}
                          color={'#8740FB'}
                          fontSize={'16px'}
                          fontWeight={'700'}
                          bg={'#F2E3FD'}
                          borderRadius={'6px'}
                        >
                          <option>IST</option>
                          <option>IST</option>
                        </Select>
                      </Box>
                      <Flex justifyContent={'space-between'} mt={'5px'}>
                        <Select
                          width={'26%'}
                          color={'#8740FB'}
                          fontSize={'16px'}
                          fontWeight={'700'}
                          bg={'#F2E3FD'}
                          borderRadius={'6px'}
                          name="hour"
                          onChange={(e) => HandleOnChange(e, i)}
                        >
                          <option>01</option>
                          <option>02</option>
                          <option>03</option>
                          <option>04</option>
                          <option>05</option>
                          <option>06</option>
                          <option>07</option>
                          <option>08</option>
                          <option>09</option>
                          <option>10</option>
                          <option>11</option>
                          <option>12</option>
                        </Select>
                        <Select
                          width={'40%'}
                          color={'#8740FB'}
                          fontSize={'16px'}
                          fontWeight={'700'}
                          bg={'#F2E3FD'}
                          borderRadius={'6px'}
                          name="minute"
                          onChange={(e) => HandleOnChange(e, i)}
                        >
                          <option>00</option>
                          <option>01</option>
                          <option>02</option>
                          <option>03</option>
                          <option>04</option>
                          <option>05</option>
                          <option>06</option>
                          <option>07</option>
                          <option>08</option>
                          <option>09</option>
                          <option>10</option>
                          <option>11</option>
                          <option>12</option>
                          <option>13</option>
                          <option>14</option>
                          <option>15</option>
                          <option>16</option>
                          <option>17</option>
                          <option>18</option>
                          <option>19</option>
                          <option>20</option>
                          <option>21</option>
                          <option>22</option>
                          <option>23</option>
                          <option>24</option>
                          <option>25</option>
                          <option>26</option>
                          <option>27</option>
                          <option>28</option>
                          <option>29</option>
                          <option>30</option>
                          <option>31</option>
                          <option>32</option>
                          <option>33</option>
                          <option>34</option>
                          <option>35</option>
                          <option>36</option>
                          <option>37</option>
                          <option>38</option>
                          <option>39</option>
                          <option>40</option>
                          <option>41</option>
                          <option>42</option>
                          <option>43</option>
                          <option>44</option>
                          <option>45</option>
                          <option>46</option>
                          <option>47</option>
                          <option>48</option>
                          <option>49</option>
                          <option>50</option>
                          <option>51</option>
                          <option>52</option>
                          <option>53</option>
                          <option>54</option>
                          <option>55</option>
                          <option>56</option>
                          <option>57</option>
                          <option>58</option>
                          <option>59</option>
                          <option>60</option>
                        </Select>
                        <Select
                          width={'30%'}
                          color={'#8740FB'}
                          fontSize={'16px'}
                          fontWeight={'700'}
                          bg={'#F2E3FD'}
                          borderRadius={'6px'}
                          name="timeday"
                          onChange={(e) => HandleOnChange(e, i)}
                        >
                          <option>AM</option>
                          <option>PM</option>
                        </Select>
                      </Flex>
                    </Box>
                    <Box
                      mt={{ base: '20px', sm: '20px', md: '20px', lg: '0px' }}
                      width={{ base: '', sm: 'auto', md: 'auto', lg: '45%' }}
                      height={'100%'}
                      justifyContent={'flex-end'}
                      boxShadow={'2px 3px 10px 3px lightgray'}
                    >
                      <Calendar
                        onChange={HandleDateOnChange}
                        value={date}
                        height="100%"
                      />
                    </Box>
                  </Box>
                  <Text
                    fontSize={'24px'}
                    color={'#000000'}
                    fontWeight={'500'}
                    mt={'25px'}
                  >
                    Campaign Budget
                  </Text>
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    width={'100%'}
                    justifyContent={'space-between'}
                    flexDirection={{
                      base: 'column',
                      sm: 'column',
                      md: 'column',
                      lg: 'row',
                    }}
                  >
                    <Box
                      width={{
                        base: '100%',
                        sm: '100%',
                        md: '100%',
                        lg: '49%',
                      }}
                      flexDir={'column'}
                    >
                      <Text
                        fontSize={'16px'}
                        color={'#000000'}
                        fontWeight={'500'}
                      >
                        Budget Type *
                      </Text>
                      <Select
                        width={'100%'}
                        border={'1px solid #E6E6E6'}
                        color={'#737373'}
                        fontSize={'16px'}
                        fontWeight={'400'}
                        bg={'#F9F9F9'}
                        borderRadius={'6px'}
                        name="budget_type"
                        onChange={(e) => HandleOnChange(e, i)}
                      >
                        <option>Daily</option>
                        <option>Monthly</option>
                        <option>Yearly</option>
                      </Select>
                      {allFiledsFilled && formData[i]?.budget_type == '' ? (
                        <Text color={'red'}>Budget duration is required</Text>
                      ) : (
                        ''
                      )}
                    </Box>
                    <Box
                      width={{
                        base: '100%',
                        sm: '100%',
                        md: '100%',
                        lg: '49%',
                      }}
                      flexDir={'column'}
                    >
                      <Text
                        fontSize={'16px'}
                        color={'#000000'}
                        fontWeight={'500'}
                      >
                        Price *
                      </Text>
                      <Input
                        width={'100%'}
                        required
                        type="text"
                        border={'1px solid #E6E6E6'}
                        bg={'#F9F9F9'}
                        borderRadius={'5px'}
                        placeholder="$Price*"
                        name="price"
                        value={formData.price}
                        onChange={(e) => HandleOnChange(e, i)}
                      />
                      {allFiledsFilled && formData[i]?.price == '' ? (
                        <Text color={'red'}>Price is required</Text>
                      ) : (
                        ''
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box
                width={'100%'}
                p={'0px 25px'}
                display={'flex'}
                flexDir={{
                  base: 'column',
                  sm: 'column',
                  md: 'column',
                  lg: 'row',
                }}
                justifyContent={'space-between'}
              >
                <Button
                  width={{ base: '100%', sm: '100%', md: '100%', lg: '49%' }}
                  onClick={() => DeleteCampaign(i)}
                >
                  Delete Campaign
                </Button>
                <Button
                  width={{ base: '100%', sm: '100%', md: '100%', lg: '49%' }}
                  mt={{ base: '10px', sm: '10px', md: '10px', lg: '0px' }}
                  onClick={AddNewCampaignForm}
                >
                  Add New Campaign
                </Button>
              </Box>
            </>
          );
        })}
        <Box
          width={'100%'}
          p={'10px 25px'}
          display={'flex'}
          justifyContent={'center'}
        >
          <Button
            type="submit"
            width={{ base: '100%', sm: '100%', md: '100%', lg: '32%' }}
            bg={'red.500'}
            color={'white'}
            _hover={{ bg: 'red.300', color: 'white' }}
            onClick={SaveCampaignData}
          >
            Save Campaign
          </Button>
        </Box>
      </Box>
      </Box>
      {showPreview && (
        <PreviewTemplate
          showPreview={showPreview}
          setShowPreview={setShowPreview}
          campaignType={'Email'}
          formData={formData}
        />
      )}
      <ToastContainer />
    </>
  );
}
