import React from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import Image1 from '../image/img1.png'
import Image2 from '../image/img2.png'
import { useNavigate } from 'react-router-dom'
import {
    Box , 
    Text,
    Image,
    Button,
    Divider,
} from '@chakra-ui/react'

export default function ABTestingCampaign() {
    const navigate = useNavigate()
  return (
    <>
        <Header/>
        <Box width={"100%"} p={"25px"}>
            <Text fontSize={"30px"} color={"#000000"} fontWeight={"500"}>A|B Testing</Text>
            <Box width={"100%"} display={"flex"} flexDir={{base:"column", sm:"column", md:"row", lg:"row"}} justifyContent={"space-between"} mt={"20px"}>
                <Box cursor={"pointer"}  w={{base:"100%",sm:"100%",md:"47%",lg:"47%"}} height={"480px"} bg={"white"} borderRadius={"6px"} boxShadow={"1px 2px 10px lightgray"} display={"flex"} justifyContent={"center"} alignItems={"center"} flexDir={"column"} _hover={{border:"1px solid red"}}>
                    <Image src={Image1} width={"40%"} height={"30%"}/>
                    <Text width={"80%"} fontSize={"16px"} color={"#000000"} fontWeight={"500"} textAlign={"justify"} mt={"10%"}>Select an existing campaign that you'd like to run an A/B test on this could be an email marketing campaign, a social media ad campaign, or any other type of campaign</Text>
                    <Button  mt={"10%"} bg={"#FED600"} _hover={{bg:"#FED600"}} onClick={()=>navigate("/MyCampaigns")}>Existing Campaign</Button>
                </Box> 
                <Box cursor={"pointer"}  w={{base:"100%",sm:"100%",md:"47%",lg:"47%"}} height={"480px"} bg={"white"} borderRadius={"6px"} boxShadow={"1px 2px 10px lightgray"} display={"flex"} justifyContent={"center"} alignItems={"center"} flexDir={"column"} _hover={{border:"1px solid red"}}>
                    <Image src={Image2} width={"40%"} height={"30%"}/>
                    <Text width={"80%"} fontSize={"16px"} color={"#000000"} fontWeight={"500"} textAlign={"justify"} mt={"10%"}>Start from scratch and create a brand new campaign specifically designed for A/B testing</Text>
                    <Button  mt={"10%"} bg={"red.400"} color={"white"} _hover={{bg:"red.300"}} onClick={()=>navigate("/CampaignType")}>Create New Campaign</Button>
                </Box>               
            </Box>            
        </Box>
        <Footer/>
    </>
  )
}
