import React, { useState } from 'react';
import { Box, Text } from '@chakra-ui/react';

const AccountProfileQuestions = () => {
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  const questions = [
    {
      question: 'How can I upload a profile picture?',
      answer:
        'Every marcom account includes a profile page where you can share information about yourself. This article outlines how learners/students/mentors/University can create and edit their marcom profile.',
    },
    {
      question: 'How can I update my email?',
      answer:
        'To update your email, go to your account settings and select "Edit Profile."',
    },
  ];

  return (
    <Box>
      <Text fontSize="lg" fontWeight="medium" color="#000000">
        Account & Profile
      </Text>
      {questions.map((q, index) => (
        <Box key={index} mb={4}>
          <Text
            fontSize="16px"
            fontWeight="bold"
            cursor="pointer"
            onClick={() => setSelectedQuestion(index)}
            color={selectedQuestion === index ? '#B83D9B' : '#6B46C1'}
          >
            {q.question}
          </Text>
          {selectedQuestion === index && (
            <Text fontSize="16px" fontWeight={500}>
              {q.answer}
            </Text>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default AccountProfileQuestions;
