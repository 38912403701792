import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Home from "./Home";
import FormPage from "./FormPage";
// import SocialIcon from "./SocialIcon";
import CardPage from "./CardPage";
// import LandingPage from "./LandingPage";
import Reviews from "../Reviews/Reviews";
import Newsletter from "../NewsLetter/NewsLetter";
import CompanyPartners from "./CompanyPartners ";

const HomePage = () => {
  return (
    <div>
      <Header/>
      <Home/>
      {/* <LandingPage/> */}
      <CardPage/>
      <CompanyPartners/>
      <Reviews/>
      <Newsletter/>
      <FormPage/>
      <Footer/>
    </div>
  );
};

export default HomePage;
