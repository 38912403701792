import React, { useState } from "react";
import {
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import moment from "moment";

const Notifications = () => {
  const [notifications, setNotifications] = useState([
    {
      id: 1,
      category: "achievement",
      title: "You unlocked a new badge!",
      createdAt: "2024-08-10T12:00:00Z",
      notificationStatus: { is_read: "0" },
    },
    {
      id: 2,
      category: "update",
      title: "New features available in the latest update.",
      createdAt: "2024-08-09T08:30:00Z",
      notificationStatus: { is_read: "1" },
    },
  ]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleMarkAsReadUnRead = (notificationId) => {
    setNotifications((prevNotifications) =>
      prevNotifications.map((notification) =>
        notification.id === notificationId
          ? {
              ...notification,
              notificationStatus: {
                ...notification.notificationStatus,
                is_read: notification.notificationStatus.is_read === "0" ? "1" : "0",
              },
            }
          : notification
      )
    );
  };

  const handleNavigateToLink = (notification) => {
    if (notification.notificationStatus.is_read === "0") {
      handleMarkAsReadUnRead(notification.id);
    }
  };

  const handleViewAll = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const notificationLength = notifications.filter(
    (item) => item.notificationStatus.is_read === "0"
  ).length;

  const renderNotifications = (filteredNotifications) => {
    return filteredNotifications.length > 0 ? (
      filteredNotifications.map((notification) => {
        const formattedDate = moment(notification.createdAt).fromNow();
        return (
          <li
            key={notification.id}
            style={{
              padding: "12px",
              borderBottom: "1px solid #ddd",
              marginBottom: "8px",
              backgroundColor:
                notification.notificationStatus.is_read === "0" ? "#f9f9f9" : "white",
            }}
          >
            <p style={{ color: "red", fontWeight: "bold" }}>{notification.category}</p>
            <div dangerouslySetInnerHTML={{ __html: notification.title }}></div>
            {!!notification.external_url_title && (
              <p>
                <a
                  href={notification.external_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "red", textDecoration: "underline" }}
                  onClick={() => handleNavigateToLink(notification)}
                >
                  {notification.external_url_title}
                </a>
              </p>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "8px",
              }}
            >
              <p style={{ color: "#888" }}>{formattedDate}</p>
              <p
                style={{ color: "red", cursor: "pointer" }}
                onClick={() => handleMarkAsReadUnRead(notification.id)}
              >
                {`Mark as ${
                  notification.notificationStatus.is_read === "0" ? "read" : "unread"
                }`}
              </p>
            </div>
          </li>
        );
      })
    ) : (
      <p>No notifications yet!</p>
    );
  };

  return (
    <div style={{ boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", padding: "16px" }}>
      <div
        style={{
          padding: "12px",
          borderBottom: "1px solid #ddd",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h5>Notifications</h5>
        {!!notificationLength && (
          <div
            style={{
              backgroundColor: "red",
              color: "white",
              borderRadius: "50%",
              padding: "4px 8px",
            }}
          >
            {notificationLength}
          </div>
        )}
      </div>
      <div style={{ padding: "12px" }}>
        <Tabs>
          <TabList>
            <Tab _selected={{ color: "red", borderBottom: "2px solid red" }}>All</Tab>
            <Tab _selected={{ color: "red", borderBottom: "2px solid red" }}>Message</Tab>
            <Tab _selected={{ color: "red", borderBottom: "2px solid red" }}>Request</Tab>
            <Tab _selected={{ color: "red", borderBottom: "2px solid red" }}>Updates</Tab>
            <Tab _selected={{ color: "red", borderBottom: "2px solid red" }}>
              Achievements
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel>{renderNotifications(notifications)}</TabPanel>
            <TabPanel>{renderNotifications(notifications.filter((item) => item.category === "message"))}</TabPanel>
            <TabPanel>{renderNotifications(notifications.filter((item) => item.category === "request"))}</TabPanel>
            <TabPanel>{renderNotifications(notifications.filter((item) => item.category === "update"))}</TabPanel>
            <TabPanel>{renderNotifications(notifications.filter((item) => item.category === "achievement"))}</TabPanel>
          </TabPanels>
        </Tabs>
        {notifications.length > 0 && (
          <Button onClick={handleViewAll} colorScheme="red" mt={4}>
            View All
          </Button>
        )}
      </div>

      <Modal isOpen={isModalOpen} onClose={handleCloseModal} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>All Notifications</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Tabs variant="enclosed" isLazy>
              <TabList>
                <Tab>All</Tab>
                <Tab>Message</Tab>
                <Tab>Request</Tab>
                <Tab>Updates</Tab>
                <Tab>Achievements</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>{renderNotifications(notifications)}</TabPanel>
                <TabPanel>{renderNotifications(notifications.filter((item) => item.category === "message"))}</TabPanel>
                <TabPanel>{renderNotifications(notifications.filter((item) => item.category === "request"))}</TabPanel>
                <TabPanel>{renderNotifications(notifications.filter((item) => item.category === "update"))}</TabPanel>
                <TabPanel>{renderNotifications(notifications.filter((item) => item.category === "achievement"))}</TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default Notifications;
