import React from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  Container,
  Image,
} from '@chakra-ui/react';
import {
  SettingsIcon,
  InfoOutlineIcon,
  LockIcon,
  SearchIcon,
  AtSignIcon,
  QuestionIcon,
} from '@chakra-ui/icons';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Styles from './Supports.module.css';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Sidebar from '../Sidebar/Sidebar';
import MARCOMFULL from '../image/MarcomLogo.jpg';
import spimg from '../image/SupportPageBackgroundImage.png';

const HelpCenter = () => {
  const navigate = useNavigate();

  return (
    <>
      <Flex p={"5"}>
        <Box flex="1">
          <Container
            className={Styles?.containerBgImage}
            paddingLeft={30}
            maxWidth="100%"
            mb={70}
            // bg={"#F5F5F5"}
          >
            <Flex>
              <Box flex="1" p="10">
                <Container
                  className={Styles.leftContainer}
                  maxWidth="100%"
                  marginTop={145}
                >
                  <Heading
                    as="h1"
                    size="3xl"
                    textAlign={'left'}
                    fontSize={50}
                    color="red.500"
                  >
                    Help Center
                  </Heading>
                  <InputGroup marginTop={10}>
                    <InputLeftElement
                      children={<Icon as={SearchIcon} color="black" />}
                    />
                    <Input
                      type="text"
                      placeholder="Search..."
                      width={'80%'}
                      border={'2px solid red'}
                    />
                  </InputGroup>
                </Container>
              </Box>
              <Box
                flex="1"
                p="0"
                position={'relative'}
                height={570}
                overflow={'hidden'}
              >
                <Container
                  className={Styles.rightContainer}
                  alignItems={'center'}
                  maxWidth="100%"
                >
                  <Box className={Styles.roundCircle} />
                  <Button
                    className={Styles.supportButton}
                    color={'#ffffff'}
                    bgColor={'#6c31ec'}
                  >
                    <Icon as={QuestionIcon} /> Chat Support
                  </Button>
                </Container>
              </Box>
            </Flex>
          </Container>

          {/* Body Section */}
          <Container
            display={'flex'}
            justifyContent={'space-around'}
            alignItems={'start'}
            maxWidth="100%"
            className="bg-grey"
            mt={70}
          >
            <Flex
              flexDirection={'column'}
              justifyContent={'center'}
              alignItems={'center'}
              gap={18}
            >
              {' '}
              <Link to="/getting-started">
                <Box
                  flex="1"
                  p="4"
                  cursor={'pointer'}
                  className={Styles.featureWidget}
                >
                  <Icon as={SettingsIcon} className={Styles.gridIcon} />
                  <Heading
                    as="h5"
                    margin={0}
                    marginTop={5}
                    fontSize={22}
                    color="red.500"
                    textAlign="center"
                  >
                    Settings
                  </Heading>
                  <Text
                    fontSize="small"
                    margin={0}
                    textAlign="center"
                    color="red.500"
                  >
                    Edit and manage your settings
                  </Text>
                </Box>
              </Link>
              <Link to="/security">
                <Box
                  flex="1"
                  p="4"
                  cursor={'pointer'}
                  className={Styles.featureWidget}
                >
                  <Icon as={LockIcon} className={Styles.gridIcon} />
                  <Heading
                    as="h5"
                    margin={0}
                    marginTop={5}
                    fontSize={22}
                    color="red.500"
                    textAlign="center"
                  >
                    Security
                  </Heading>
                  <Text
                    fontSize="small"
                    textAlign="center"
                    margin={0}
                    color="red.500"
                  >
                    Trust and safety - Manage Privacy
                  </Text>
                </Box>
              </Link>
            </Flex>
            <Flex
              flexDirection={'column'}
              justifyContent={'center'}
              alignItems={'center'}
              gap={18}
            >
              <Link to="/account-profile">
                <Box
                  flex="1"
                  p="4"
                  cursor={'pointer'}
                  className={Styles.featureWidget}
                >
                  <Icon as={AtSignIcon} className={Styles.gridIcon} />
                  <Heading
                    as="h5"
                    margin={0}
                    marginTop={5}
                    fontSize={22}
                    color="red.500"
                    textAlign="center"
                  >
                    Account and profile
                  </Heading>
                  <Text
                    fontSize="small"
                    textAlign="center"
                    margin={0}
                    color="red.500"
                  >
                    Modify and manage your account
                  </Text>
                </Box>
              </Link>
              <Link to="/purchase-refund">
                <Box
                  flex="1"
                  p="4"
                  cursor={'pointer'}
                  className={Styles.featureWidget}
                >
                  <Icon as={LockIcon} className={Styles.gridIcon} />
                  <Heading
                    as="h5"
                    margin={0}
                    marginTop={5}
                    fontSize={22}
                    color="red.500"
                    textAlign="center"
                  >
                    Purchase and Refunds
                  </Heading>
                  <Text
                    fontSize="small"
                    textAlign="center"
                    margin={0}
                    color="red.500"
                  >
                    Learn more about transfers
                  </Text>
                </Box>
              </Link>
            </Flex>
            <Flex
              flexDirection={'column'}
              justifyContent={'center'}
              alignItems={'center'}
              gap={18}
            >
              <Link to="/learning-support">
                <Box
                  flex="1"
                  p="4"
                  cursor={'pointer'}
                  className={Styles.featureWidget}
                >
                  <Icon as={InfoOutlineIcon} className={Styles.gridIcon} />
                  <Heading
                    as="h5"
                    margin={0}
                    marginTop={5}
                    fontSize={22}
                    color="red.500"
                    textAlign="center"
                  >
                    Learning Support
                  </Heading>
                  <Text
                    fontSize="small"
                    textAlign="center"
                    margin={0}
                    color="red.500"
                  >
                    Get back to us for any learning support
                  </Text>
                </Box>
              </Link>
              <Link to="/support">
                <Box
                  flex="1"
                  p="4"
                  cursor={'pointer'}
                  className={Styles.featureWidget}
                >
                  <Icon as={InfoOutlineIcon} className={Styles.gridIcon} />
                  <Heading
                    as="h5"
                    margin={0}
                    marginTop={5}
                    fontSize={22}
                    color="red.500"
                    textAlign="center"
                  >
                    Others
                  </Heading>
                  <Text
                    fontSize="small"
                    textAlign="center"
                    margin={0}
                    color="red.500"
                  >
                    Write to us for others
                  </Text>
                </Box>
              </Link>
            </Flex>
          </Container>
        </Box>
      </Flex>
    </>
  );
};

export default HelpCenter;
