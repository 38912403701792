import React, { useState } from "react";
import { Menu, MenuButton, MenuList, MenuItem, Select } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Text,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Checkbox,
  useBreakpointValue,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  IconButton,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  InputRightElement,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useToast,
  InputGroup,
  Flex,
} from "@chakra-ui/react";
import { Avatar } from "@chakra-ui/react";
import {} from "@chakra-ui/react";
import { SearchIcon, CloseIcon } from "@chakra-ui/icons";
import { GoScreenFull } from "react-icons/go";
import { FaPlus } from "react-icons/fa";
import { RiExportFill } from "react-icons/ri";
import { IoFilter } from "react-icons/io5";
import { GrGroup } from "react-icons/gr";
import { FaLink } from "react-icons/fa6";
import { CgBox } from "react-icons/cg";
import endpoint from "../../Services/endpoint";
import { CallAPI } from "../../Services/service";
import { ToastContainer, toast } from "react-toastify";
<IoFilter />;
const dummyLeads = [
  {
    id: 1,
    name: "Ajay Kumar",
    phone: "+228149523842",
    email: "ajaykumar@gmail.com",
    date: "12/06/2024",
    status: "Todo",
    leads: "220",
    priority: "Medium",
  },
  {
    id: 2,
    name: "Birendra Kumar",
    phone: "+922291926822",
    email: "birendrakumar@gmail.com",
    date: "02/08/2023",
    leads: "120",

    status: "Backlog",
    priority: "High",
  },
  {
    id: 3,
    name: "Rishabh Singh",
    phone: "+911001842821",
    email: "rishabhsingh@gmail.com",
    date: "22/03/2023",
    leads: "290",

    status: "In Done",
    priority: "Low",
  },
  {
    id: 4,
    name: "Shivendra Singh",
    phone: "+915527311091",
    email: "shivendrasingh@gmail.com",
    status: "In progress",
    leads: "20",

    date: "29/02/2023",
    priority: "Medium",
  },
];

export default function Lead() {
  const [selectedItems, setSelectedItems] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isDesktop = useBreakpointValue({ base: false, md: true });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [leads, setLeads] = useState(dummyLeads);
  const [showLinkForm, setShowLinkForm] = useState(false);
  const [currentLeadId, setCurrentLeadId] = useState(null);
  const [newLink, setNewLink] = useState("");
  const [createLead, setCreateLead] = useState({
    name: "",
    email: "",
    phone: "",
    priority: "",
  });

  const HandleChange = (e) => {
    setCreateLead({ ...createLead, [e.target.name]: e.target.value });
  };

  const [filters, setFilters] = useState({
    name: "",
    phone: "",
    email: "",
    date: "",
    priority: "",
  });
  const [showAddForm, setShowAddForm] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [newLead, setNewLead] = useState({
    name: "",
    phone: "",
    email: "",
    date: "",
    priority: "",
  });

  const toast = useToast();
  const [formErrors, setFormErrors] = useState({
    name: "",
    email: "",
  });
  const validateForm = () => {
    let isValid = true;
    const errors = {};

    if (!newLead.name) {
      errors.name = "Name is required";
      isValid = false;
    }

    if (!newLead.email) {
      errors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(newLead.email)) {
      errors.email = "Email is invalid";
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleCheckboxChange = (id) => {
    const updatedLeads = leads.map((lead) =>
      lead.id === id ? { ...lead, isSelected: !lead.isSelected } : lead
    );
    setLeads(updatedLeads);
    setSelectedItems(updatedLeads.filter((lead) => lead.isSelected).length);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const importedLeads = JSON.parse(e.target.result);
        console.log("Imported Leads:", importedLeads);

        toast({
          title: "Import Data",
          description: "Do you want to add imported data to the leads list?",
          status: "info",
          duration: 9000,
          isClosable: true,
          position: "top",
          onCloseComplete: () => {
            const updatedLeads = [...leads, ...importedLeads];
            setLeads(updatedLeads);
            toast({
              title: "Leads imported successfully.",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
          },
        });
      } catch (error) {
        console.error("Error parsing JSON file:", error);
        toast({
          title: "Error",
          description: "Failed to parse JSON file.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    };
    reader.readAsText(file);
  };
  const handleExportLeads = () => {
    const selectedLeads = leads.filter((lead) => lead.isSelected);

    if (selectedLeads.length === 0) {
      toast({
        title: "No leads selected for export.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    const csvContent =
      "data:text/csv;charset=utf-8," +
      selectedLeads.map((lead) => Object.values(lead).join(",")).join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "selected_leads.csv");
    document.body.appendChild(link);
    link.click();

    toast({
      title: "Leads exported successfully.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredLeads = leads.filter((lead) => {
    return (
      (!searchTerm ||
        lead.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        lead.email.toLowerCase().includes(searchTerm.toLowerCase())) &&
      (!filters.name ||
        lead.name.toLowerCase().includes(filters.name.toLowerCase())) &&
      (!filters.phone || lead.phone.includes(filters.phone)) &&
      (!filters.email ||
        lead.email.toLowerCase().includes(filters.email.toLowerCase())) &&
      (!filters.date || lead.date === filters.date) &&
      (!filters.priority || lead.priority === filters.priority)
    );
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredLeads.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const navigate = useNavigate();

  const handleCreateCampaign = () => {
    navigate("/AllCampaigns");
  };

  const handleAddLead = () => {
    setNewLead({
      id: null,
      name: "",
      phone: "",
      email: "",
      date: "",
      priority: "",
    });
    setIsEditing(false);
    setShowAddForm(true);
  };
  const handleSubmit = () => {
    if (validateForm()) {
      if (isEditing) {
        const updatedLeads = leads.map((lead) =>
          lead.id === newLead.id ? newLead : lead
        );
        setLeads(updatedLeads);
        toast({
          title: "Lead updated successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        const id = leads.length + 1;
        const updatedLeads = [...leads, { ...newLead, id }];
        setLeads(updatedLeads);
        toast({
          title: "Lead added successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }

      setNewLead({
        id: null,
        name: "",
        phone: "",
        email: "",
        date: "",
        priority: "",
      });
      setShowAddForm(false);
    } else {
      toast({
        title: "Please fill out all required fields correctly.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleEditLead = (lead) => {
    setNewLead(lead);
    setIsEditing(true);
    setShowAddForm(true);
  };

  const handleDeleteLead = (id) => {
    const updatedLeads = leads.filter((lead) => lead.id !== id);
    setLeads(updatedLeads);
    setSelectedItems(0);
    toast({
      title: "Lead deleted successfully.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  const handleDeleteAllLeads = () => {
    onClose();
    if (
      window.confirm("Are you sure you want to delete all leads permanently?")
    ) {
      setLeads([]);
      setSelectedItems(0);
      toast({
        title: "All leads deleted successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleAddLink = (id) => {
    const generatedLink = `https://example.com/leads/${id}`;
    setNewLink(generatedLink);
    setCurrentLeadId(id);
    setShowLinkForm(true);
  };

  const handleLinkSubmit = () => {
    const updatedLeads = leads.map((lead) => {
      if (lead.id === currentLeadId) {
        return { ...lead, link: newLink };
      }
      return lead;
    });
    setLeads(updatedLeads);
    setNewLink("");
    setShowLinkForm(false);
    toast({
      title: "Link added successfully.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  const handleSelectAll = () => {
    const allSelected = selectedItems === leads.length;
    const updatedLeads = leads.map((lead) => ({
      ...lead,
      isSelected: !allSelected,
    }));
    setLeads(updatedLeads);
    setSelectedItems(allSelected ? 0 : updatedLeads.length);
  };

  const handleUnselectAll = () => {
    const updatedLeads = leads.map((lead) => ({ ...lead, isSelected: false }));
    setLeads(updatedLeads);
    setSelectedItems(0);
  };

  const today = new Date();
  const formattedDate = today.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  const [showCard, setShowCard] = useState(null);

  const toggleCard = (id) => {
    if (showCard === id) {
      setShowCard(null);
    } else {
      setShowCard(id);
    }
  };

  const CreateLead = () => {
    try {
      CallAPI(endpoint.createLead, createLead).then((res) => {
        if (res?.code === 200) {
          toast.success("lead added successfully");
        }
      });
    } catch (error) {
      console.log("Error>>", error);
    }
  };
  return (
    <>
      <Flex height="100%" direction="column" ml={"10px"}>
        <Box
          w={"100%"}
          p={4}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          borderRadius="10px"
        >
          <Text
            fontSize="24px"
            color={"black"}
            fontWeight="700"
            ml={-3}
            lineHeight="24px"
          >
            Lead management
          </Text>
          <Box>
            <Button
              onClick={handleAddLead}
              mr={2}
              bg={"white"}
              color="#D1202A"
              borderColor="#D1202A"
              borderWidth="1px"
              _hover={{
                backgroundColor: "#E03741",
                color: "white",
              }}
              leftIcon={<FaPlus />}
            >
              Add New
            </Button>
            <Button
              _hover={{ borderColor: "#E03741", color: "white" }}
              bg={"#D1202A"}
              mr={-4}
              leftIcon={<RiExportFill />}
              colorScheme="blue"
              onClick={handleExportLeads}
            >
              Exports
            </Button>
          </Box>
        </Box>
        <Box
          w={"70%"}
          p={1}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          borderRadius="10px"
        >
          <Box display="flex" alignItems="center" gap={5}>
            <Avatar
              name="Mohit Sharma"
              src="https://th.bing.com/th/id/OIP.0CZd1ESLnyWIHdO38nyJDAHaGF?rs=1&pid=ImgDetMain"
            />
            <Box display="flex" flexDirection="column" mt={5}>
              <Text fontWeight="bold">Mohit Sharma</Text>
              <Text fontSize="sm" color="gray.500" mt="-20px">
                Owner
              </Text>
            </Box>
          </Box>

          <Box display="flex" gap={2}>
            <Box display={"flex"}>
              <Box onClick={() => handleAddLink(leads.id)}>
                <Button
                  borderColor={"E4E4E4"}
                  borderWidth={"1px"}
                  borderStyle={"solid"}
                  leftIcon={<FaLink />}
                  bg={"white"}
                >
                  Create Link
                </Button>
              </Box>
            </Box>

            <Box display={"flex"}>
              <Box>
                <Button
                  bg={"white"}
                  leftIcon={<FaPlus />}
                  borderColor={"E4E4E4"}
                  borderWidth={"1px"}
                  borderStyle={"solid"}
                  onClick={handleAddLead}
                >
                  Add New Leads
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
        <Flex
          flex="1"
          borderRadius={"10px"}
          direction={{ base: "column", md: "row" }}
        >
          <Box border="1px solid #ccc" borderRadius={"10px"} width={"70%"}>
            <Box>
              <Flex
                justify="space-between"
                align="center"
                pl={2}
                pr={2}
                pt={2}
                borderRadius="lg"
                mb={1}
              >
                <Text
                  fontSize="16px"
                  fontWeight="500"
                  lineHeight="19.2px"
                  color="#000000"
                >
                  Cloud Foundation : Webinar Facebook leads
                </Text>

                <Box display="flex" alignItems="center">
                  <Text
                    fontSize="md"
                    color="gray.500"
                    mr={5}
                    whiteSpace="nowrap"
                  >
                    {formattedDate}
                  </Text>
                  <GoScreenFull
                    style={{
                      width: "24px",
                      height: "24px",
                      marginTop: "-16px",
                    }}
                  />
                </Box>
              </Flex>

              <Box
                w={"100%"}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                pl={2}
                pr={2}
                pb={0}
              >
                {/* Search Box */}
                <InputGroup maxW="200px">
                  <Input
                    value={searchTerm}
                    placeholder="Search for leads "
                    value={searchTerm}
                    onChange={handleSearchInputChange}
                    bg={"#CCCBCB"}
                    borderColor="gray.300"
                    _hover={{ borderColor: "gray.500" }}
                    focusBorderColor="blue.500"
                  />
                  <InputRightElement>
                    {searchTerm ? (
                      <IconButton
                        icon={<CloseIcon />}
                        size="sm"
                        aria-label="Clear search"
                      />
                    ) : (
                      <SearchIcon color="gray.500" />
                    )}
                  </InputRightElement>
                </InputGroup>

                <Box display={"flex"}>
                  <Box>
                    <Text
                      fontSize={"16px"}
                      fontWeight={"400"}
                      color={"black"}
                      bg={"white"}
                      pl={"8px"}
                      pt={"15px"}
                    >
                      <Button
                        borderColor={"E4E4E4"} // Specify the border color
                        borderWidth={"1px"} // Specify the border width
                        borderStyle={"solid"}
                        bg={"white"}
                        leftIcon={<IoFilter />}
                      >
                        Filter
                      </Button>
                    </Text>
                  </Box>
                </Box>
                <Box display={"flex"}>
                  <Box>
                    <Text
                      fontSize={"16px"}
                      fontWeight={"400"}
                      color={"black"}
                      bg={"white"}
                      pl={"8px"}
                      pt={"15px"}
                    >
                      <Button
                        borderColor={"E4E4E4"} // Specify the border color
                        borderWidth={"1px"} // Specify the border width
                        borderStyle={"solid"}
                        bg={"white"}
                        leftIcon={<GrGroup />}
                      >
                        Grouping
                      </Button>
                    </Text>
                  </Box>
                </Box>
                <Box display={"flex"}>
                  <Box>
                    <Text
                      fontSize={"16px"}
                      fontWeight={"400"}
                      color={"black"}
                      bg={"white"}
                      pl={"8px"}
                      pt={"15px"}
                    >
                      <Button
                        borderColor={"E4E4E4"} // Specify the border color
                        borderWidth={"1px"} // Specify the border width
                        borderStyle={"solid"}
                        bg={"white"}
                      >
                        2 Campaign
                      </Button>
                    </Text>
                  </Box>
                </Box>
                <Box display={"flex"}>
                  <Box>
                    <Text
                      fontSize={"16px"}
                      fontWeight={"400"}
                      color={"black"}
                      bg={"white"}
                      pl={"8px"}
                      pt={"15px"}
                    >
                      <Button
                        borderColor="E4E4E4"
                        borderWidth="1px"
                        borderStyle="solid"
                        bg="white"
                        leftIcon={<CgBox />}
                        onClick={handleCreateCampaign}
                      >
                        Create Campaign
                      </Button>
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box borderWidth="1px" mt={0}>
              <TableContainer>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>
                        <Checkbox
                          isChecked={selectedItems === leads.length}
                          onChange={handleSelectAll}
                        />
                      </Th>
                      <Th>Task</Th>
                      <Th>Mail id</Th>
                      <Th>Phone No</Th>
                      <Th>Status</Th>
                      {/* <Th>...</Th> */}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {currentItems.map((lead) => (
                      <Tr key={lead.id}>
                        <Td>
                          <Checkbox
                            isChecked={lead.isSelected}
                            onChange={() => handleCheckboxChange(lead.id)}
                          />
                        </Td>
                        <Td>{lead.name}</Td>
                        <Td>{lead.email}</Td>
                        <Td>{lead.phone}</Td>
                        <Td>{lead.status}</Td>

                        <Td>
                          <Menu>
                            <MenuButton bg={"white"} as={Button}>
                              ...
                            </MenuButton>
                            <MenuList>
                              <MenuItem onClick={() => handleEditLead(lead)}>
                                Edit
                              </MenuItem>
                              <MenuItem
                                onClick={() => handleDeleteLead(lead.id)}
                              >
                                Delete
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>

              <Box mt={4} display="flex" justifyContent="center">
                {Array.from({
                  length: Math.ceil(filteredLeads.length / itemsPerPage),
                }).map((_, idx) => (
                  <Button
                    key={idx}
                    variant="outline"
                    colorScheme="blue"
                    onClick={() => paginate(idx + 1)}
                    mx={1}
                    size="sm"
                    borderRadius="20px"
                  >
                    {idx + 1}
                  </Button>
                ))}
              </Box>
            </Box>

            {!isDesktop && (
              <Drawer placement="right" isOpen={isOpen} onClose={onClose}>
                <DrawerOverlay />
                <DrawerContent>
                  <DrawerCloseButton />
                  <DrawerHeader>Options</DrawerHeader>
                  <DrawerBody>
                    <Button mb={2} w="100%">
                      {filteredLeads.length} Total Leads
                    </Button>
                    <Button onClick={handleFileUpload} mb={2} w="100%">
                      Import Leads
                    </Button>
                    <Button onClick={handleExportLeads} mb={2} w="100%">
                      Export Selected Leads
                    </Button>
                    <Button mb={2} w="100%" onClick={handleDeleteAllLeads}>
                      Delete All Leads
                    </Button>
                    <Button mb={2} w="100%" onClick={handleAddLead}>
                      Add Leads
                    </Button>
                    <Button mb={2} w="100%">
                      Create Campaigns
                    </Button>
                    <Button
                      mb={2}
                      w="100%"
                      onClick={() => handleAddLink(leads.id)}
                    >
                      Generate Link
                    </Button>
                    <Button mb={2} w="100%">
                      {selectedItems} Leads selected{" "}
                    </Button>
                  </DrawerBody>
                  <DrawerFooter>
                    <Button variant="outline" mr={3} onClick={onClose}>
                      Close
                    </Button>
                  </DrawerFooter>
                </DrawerContent>
              </Drawer>
            )}

            {showAddForm && (
              <Modal isOpen={showAddForm} onClose={() => setShowAddForm(false)}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>
                    {isEditing ? "Edit Lead" : "Add Lead"}
                  </ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <FormControl isInvalid={formErrors.name}>
                      <FormLabel>Name</FormLabel>
                      <Input
                        value={newLead.name}
                        onChange={(e) =>
                          setNewLead({ ...newLead, name: e.target.value })
                        }
                      />
                      {formErrors.name && (
                        <FormErrorMessage>{formErrors.name}</FormErrorMessage>
                      )}
                    </FormControl>
                    <FormControl>
                      <FormLabel>Phone</FormLabel>
                      <Input
                        value={newLead.phone}
                        onChange={(e) =>
                          setNewLead({ ...newLead, phone: e.target.value })
                        }
                      />
                    </FormControl>
                    <FormControl isInvalid={formErrors.email}>
                      <FormLabel>Email</FormLabel>
                      <Input
                        value={newLead.email}
                        onChange={(e) =>
                          setNewLead({ ...newLead, email: e.target.value })
                        }
                      />
                      {formErrors.email && (
                        <FormErrorMessage>{formErrors.email}</FormErrorMessage>
                      )}
                    </FormControl>
                    {/* <FormControl>
                    <FormLabel>Date</FormLabel>
                    <Input
                      type="date"
                      value={newLead.date}
                      onChange={(e) =>
                        setNewLead({ ...newLead, date: e.target.value })
                      }
                    />
                  </FormControl> */}
                    <FormControl>
                      <FormLabel>Priority</FormLabel>
                      <Input
                        value={newLead.priority}
                        onChange={(e) =>
                          setNewLead({ ...newLead, priority: e.target.value })
                        }
                      />
                    </FormControl>
                  </ModalBody>
                  <ModalFooter>
                    <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
                      {isEditing ? "Update" : "Add"}
                    </Button>
                    <Button
                      variant="ghost"
                      onClick={() => setShowAddForm(false)}
                    >
                      Cancel
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            )}
          </Box>

          <Box
            border="1px solid #ccc"
            ml={2}
            mr={-2}
            borderRadius={"10px"}
            width={"30%"}
          >
            <Box>
              <Flex
                justify="space-between"
                align="center"
                pl={2}
                pr={2}
                pt={3}
                borderRadius="lg"
                mb={1}
              >
                <Text
                  fontSize="16px"
                  fontWeight="500"
                  lineHeight="19.2px"
                  color="#000000"
                >
                  Lead Lists
                </Text>
              </Flex>

              <Box
                w={"100%"}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                p={4}
                pt={-1}
              >
                <InputGroup maxW="200px">
                  <Input
                    placeholder="Search for List"
                    bg={"#CCCBCB"}
                    value={searchTerm}
                    borderColor="gray.300"
                    _hover={{ borderColor: "gray.500" }}
                    focusBorderColor="blue.500"
                  />
                  <InputRightElement>
                    {searchTerm ? (
                      <IconButton
                        icon={<CloseIcon />}
                        size="sm"
                        aria-label="Clear search"
                      />
                    ) : (
                      <SearchIcon color="gray.500" />
                    )}
                  </InputRightElement>
                </InputGroup>

                <Box>
                  <Box>
                    <Text>
                      <Button
                        mt={{ base: 0, md: 4 }}
                        leftIcon={<IoFilter />}
                        borderColor={"E4E4E4"} // Specify the border color
                        borderWidth={"1px"} // Specify the border width
                        borderStyle={"solid"}
                        bg={"white"}
                        onClick={handleAddLead}
                      >
                        Filter
                      </Button>
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box
              border="1px solid #ccc"
              borderRadius={6}
              m={4}
              borderWidth="1px"
              mt={-4}
            >
              <TableContainer>
                <div style={{ overflowX: "auto", width: "100%" }}>
                  <Table
                    variant="simple"
                    style={{ tableLayout: "fixed", minWidth: "100%" }}
                  >
                    <Thead>
                      <Tr>
                        <Th style={{ width: "120px" }}>Name</Th>
                        <Th style={{ width: "80px" }}>Leads</Th>
                        <Th style={{ width: "120px" }}>Date</Th>
                        <Th style={{ width: "80px" }}>Actions</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {currentItems.map((lead) => (
                        <Tr key={lead.id}>
                          <Td style={{ width: "120px" }}>{lead.name}</Td>
                          <Td style={{ width: "80px" }}>{lead.leads}</Td>
                          <Td style={{ width: "120px" }}>{lead.date}</Td>
                          <Td style={{ width: "80px" }}>
                            <Menu>
                              <MenuButton bg={"white"} as={Button}>
                                ...
                              </MenuButton>
                              <MenuList>
                                <MenuItem onClick={() => handleEditLead(lead)}>
                                  Edit
                                </MenuItem>
                                <MenuItem
                                  onClick={() => handleDeleteLead(lead.id)}
                                >
                                  Delete
                                </MenuItem>
                              </MenuList>
                            </Menu>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </div>
              </TableContainer>

              <Box mt={4} display="flex" justifyContent="center">
                {Array.from({
                  length: Math.ceil(filteredLeads.length / itemsPerPage),
                }).map((_, idx) => (
                  <Button
                    key={idx}
                    variant="outline"
                    colorScheme="blue"
                    onClick={() => paginate(idx + 1)}
                    mx={1}
                    size="sm"
                    borderRadius="20px"
                  >
                    {idx + 1}
                  </Button>
                ))}
              </Box>
            </Box>

            {!isDesktop && (
              <Drawer placement="right" isOpen={isOpen} onClose={onClose}>
                <DrawerOverlay />
                <DrawerContent>
                  <DrawerCloseButton />
                  <DrawerHeader>Options</DrawerHeader>
                  <DrawerBody>
                    <Button mb={2} w="100%">
                      {filteredLeads.length} Total Leads
                    </Button>
                    <Button onClick={handleFileUpload} mb={2} w="100%">
                      Import Leads
                    </Button>
                    <Button onClick={handleExportLeads} mb={2} w="100%">
                      Export Selected Leads
                    </Button>
                    <Button mb={2} w="100%" onClick={handleDeleteAllLeads}>
                      Delete All Leads
                    </Button>
                    <Button mb={2} w="100%" onClick={handleAddLead}>
                      Add Leads
                    </Button>
                    <Button mb={2} w="100%">
                      Create Campaigns
                    </Button>
                    <Button
                      mb={2}
                      w="100%"
                      onClick={() => handleAddLink(leads.id)}
                    >
                      Generate Link
                    </Button>
                    <Button mb={2} w="100%">
                      {selectedItems} Leads selected{" "}
                    </Button>
                  </DrawerBody>
                  <DrawerFooter>
                    <Button variant="outline" mr={3} onClick={onClose}>
                      Close
                    </Button>
                  </DrawerFooter>
                </DrawerContent>
              </Drawer>
            )}

            {showAddForm && (
              <Modal isOpen={showAddForm} onClose={() => setShowAddForm(false)}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>
                    {isEditing ? "Edit Lead" : "Add Lead"}
                  </ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <FormControl isInvalid={formErrors.name}>
                      <FormLabel>Name</FormLabel>
                      <Input
                        value={createLead.name}
                        name="name"
                        onChange={HandleChange}
                      />
                      {formErrors.name && (
                        <FormErrorMessage>{formErrors.name}</FormErrorMessage>
                      )}
                    </FormControl>
                    <FormControl>
                      <FormLabel>Phone</FormLabel>
                      <Input
                        value={createLead.phone}
                        name="phone"
                        onChange={HandleChange}
                      />
                    </FormControl>
                    <FormControl isInvalid={formErrors.email}>
                      <FormLabel>Email</FormLabel>
                      <Input
                        value={createLead.email}
                        name="email"
                        onChange={HandleChange}
                      />
                      {formErrors.email && (
                        <FormErrorMessage>{formErrors.email}</FormErrorMessage>
                      )}
                    </FormControl>
                    <FormControl>
                      <FormLabel>Priority</FormLabel>
                      <Select name="priority" onChange={HandleChange}>
                        <option>Low</option>
                        <option>High</option>
                      </Select>
                    </FormControl>
                  </ModalBody>
                  <ModalFooter>
                    <Button colorScheme="blue" mr={3} onClick={CreateLead}>
                      {isEditing ? "Update" : "Add"}
                    </Button>
                    <Button
                      variant="ghost"
                      onClick={() => setShowAddForm(false)}
                    >
                      Cancel
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            )}
          </Box>

          {showLinkForm && (
            <Modal isOpen={showLinkForm} onClose={() => setShowLinkForm(false)}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Add Link</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <FormControl>
                    <FormLabel>Link</FormLabel>
                    <Input value={newLink} isReadOnly />
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button colorScheme="blue" onClick={handleLinkSubmit}>
                    Save Link
                  </Button>
                  <Button onClick={() => setShowLinkForm(false)}>Cancel</Button>
                  <Button
                    onClick={() => navigator.clipboard.writeText(newLink)}
                  >
                    Copy Link
                  </Button>
                  <Button
                    onClick={() => {
                      if (navigator.share) {
                        navigator.share({
                          title: "Lead Link",
                          url: newLink,
                        });
                      } else {
                        alert("Sharing is not supported in this browser");
                      }
                    }}
                  >
                    Share Link
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          )}
        </Flex>
      </Flex>
    </>
  );
}
