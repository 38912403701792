import React from 'react'

import {
  Box,
  Text,
  Container,
  Flex,
  useColorModeValue,
  Stack, 
  Heading,
  Button
} from '@chakra-ui/react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

const Card = ({ heading, description, icon, href }) => {
  return (
    <Box
    bg={"white"}
      maxW={{ base: 'full', md: '275px' }}
      w={'full'}
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      p={5}
      transition="all 0.3s ease-in-out"
      _hover={{
        transform: 'translateY(-5px)',
        borderRadius: 'xl',
        borderColor: useColorModeValue('red.400', 'red.600'), 
      }}
    >
      <Stack align={'start'} spacing={2}>
        <Flex
          w={16}
          h={16}
          align={'center'}
          justify={'center'}
          color={'white'}
          rounded={'full'}
          bg={useColorModeValue('gray.100', 'gray.700')}
        >
          {icon}
        </Flex>
        <Box mt={2}>
          <Heading size="md">{heading}</Heading>
          <Text mt={1} fontSize={'sm'}>
            {description}
          </Text>
        </Box>
        <Button variant={'link'} colorScheme={'red'} size={'sm'} href={href}>
          Learn more
        </Button>
      </Stack>
    </Box>
  )
}


export default function Customer() {
  return (
   <>
    <Box display={"flex"} flexDir={"column"} justifyContent={"center"} alignItems={"center"} mt={"50px"}>
      <Text  color={'red.400'} fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }} fontWeight={600} textAlign={"center"}> Our Users 10 Million+ Globally</Text>
      <Text  color={'gray.500'} maxW={'3xl'} > Best Leading Company In the Global Market</Text>

      <Box maxW={'5xl'} mt={12} mb={12}>
        <Flex flexWrap="wrap" gridGap={6} justify="center">
          <Card
            heading={'IKEA'}
            // icon={<Icon as={FcAssistant} w={10} h={10}/>}
            description={'Enhance your online presence with our strategic digital marketing solutions.'}
            href={'#'}
          />
          <Card
            heading={'USHA'}
            // icon={<Icon as={FcCollaboration} w={10} h={10} />}
            description={'Join forces with us to create impactful collaborative marketing campaigns.'}
            href={'#'}
          />
          <Card
            heading={'PUMA'}
            // icon={<Icon as={FcConferenceCall} w={10} h={10} />}
            description={'Engage with your audience and grow your brand through social media marketing.'}
            href={'#'}
          />
          <Card
            heading={'Ford'}
            // icon={<Icon as={FcManager} w={10} h={10} />}
            description={'Gain insights and strategic advice from our expert management consultants.'}
            href={'#'}
          />
          <Card
            heading={'McAfee'}
            // icon={<Icon as={FcAbout} w={10} h={10} />}
            description={'Learn more about our company and how we can help you achieve marketing success.'}
            href={'#'}
          />
          <Card
            heading={'Suzuki'}
            // icon={<Icon as={FcAbout} w={10} h={10} />}
            description={'Learn more about our company and how we can help you achieve marketing success.'}
            href={'#'}
          />
          <Card
            heading={'Hyundai'}
            // icon={<Icon as={FcAbout} w={10} h={10} />}
            description={'Learn more about our company and how we can help you achieve marketing success.'}
            href={'#'}
          />
          <Card
            heading={'TATA'}
            // icon={<Icon as={FcAbout} w={10} h={10} />}
            description={'Learn more about our company and how we can help you achieve marketing success.'}
            href={'#'}
          />
        </Flex>
      </Box>
    </Box>
    <Footer/>
   </>
  )
}
