import React from "react";
import {
  Box,
  Stack,
  Heading,
  Text,
  Container,
  SimpleGrid,
} from "@chakra-ui/react";
import FormCard from "./FormCard";

const FormPage = () => {
  return (
    <Box position="relative" minHeight="100vh" width="100%" bg="white.100">
      <Container
        maxW="80%"
        bgGradient="linear(to-r, red.500, orange.500)"
        p={16}
        borderRadius="lg"
        boxShadow="2xl"
        textAlign="center"
        display="flex"
        flexDir="column"
        alignItems="center"
        mt={10}
        transition="all 0.3s ease"
        _hover={{ transform: "scale(1.02)", boxShadow: "xl" }}
      >
        <Heading
          as="h1"
          size="2xl"
          color="white"
          pb={6}
          fontWeight="bold"
          textTransform="uppercase"
          letterSpacing="widest"
        >
          MARCOM
        </Heading>
        <Text
          fontSize={{ base: "md", md: "lg", lg: "xl" }}
          mt={0}
          color="whiteAlpha.900"
          width={{ base: "90%", sm: "90%", md: "70%", lg: "60%" }}
          lineHeight="taller"
          letterSpacing="wide"
        >
          At Marcom, we empower businesses with our intuitive CRM solution.
          Designed to streamline sales, marketing, and customer service, our
          platform helps you manage relationships and boost growth effortlessly.
        </Text>
      </Container>

      <Container
        as={SimpleGrid}
        maxW="7xl"
        columns={{ base: 1, md: 2 }}
        spacing={{ base: 10, lg: 32 }}
        py={{ base: 10, sm: 20, lg: 32 }}
      >
        <Stack spacing={{ base: 8, md: 14 }}>
          <Heading
            lineHeight={1.2}
            fontSize={{ base: "2xl", sm: "3xl", md: "4xl", lg: "5xl" }}
          >
            Elevate Your Business
            <Text
              as="span"
              bgGradient="linear(to-r, red.400,pink.400)"
              bgClip="text"
            >
              & Build Stronger Connections
            </Text>
          </Heading>
          <Text fontSize={{ base: "md", lg: "lg" }} color="gray.500" mb={4}>
            Experience our CRM with a risk-free 15-day trial. No credit card
            needed.
          </Text>
          <Stack
            spacing={2}
            fontSize={{ base: "sm", md: "md" }}
            color="gray.600"
          >
            <Text>• Increase your sales with our advanced tools</Text>
            <Text>• Improve customer relationships</Text>
            <Text>• Automate your marketing efforts</Text>
            <Text>• Get detailed analytics and reports</Text>
          </Stack>
        </Stack>
        <Stack boxShadow="xl">
          <FormCard />
        </Stack>
      </Container>
    </Box>
  );
};

export default FormPage;
