import React from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import Style from './Campaign.module.css'
import {
    Box , 
    Text,
    Image,
    Select,
    Input,
    Radio
} from '@chakra-ui/react'
import Image1 from '../image/Vector.png'
import Image2 from '../image/whatsapp vector.png'
import Image3 from '../image/Inbox Vector.png'
import Image4 from '../image/threedot vector.png'
import Image5 from '../image/dummyBoyImage.png'
export default function MyCampaigns() {
  return (
    <>
        <Box width={"100%"} p={"25px"}>
            <Text fontSize={"30px"} color={"#000000"} fontWeight={"500"}>My Campaigns</Text>
            <Box cursor={"pointer"}  w={{base:"100%",sm:"100%",md:"100%",lg:"35%"}} height={"50px"} bg={"white"} borderRadius={"6px"} boxShadow={"1px 2px 10px lightgray"} display={"flex"} justifyContent={"space-evenly"} alignItems={"center"}>
                <Image src={Image1} width={"20px"} height={"20px"}/>
                <Input className={Style.SearchInputBox} type='text' width={"90%"} border={"none"} placeholder='Search by campaign title or subject'/>
            </Box> 
            <Box width={"100%"} display={"flex"} flexDir={{base:"column", sm:"column", md:"column", lg:"row"}} justifyContent={"space-between"} mt={"30px"}> 
                {/* ***********************************************BOX CONTAINER*******************************************************************************                */}
                <Box  width={{base:"100%",sm:"100%",md:"100%",lg:"32%"}} mt={"10px"} height={"auto"} boxShadow={"1px 2px 10px lightgray"} borderRadius={"6px"} padding={"15px"} display={"flex"} flexDir={"column"} >
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                        <Image src={Image2} width={"20px"} height={"20px"}/>
                        <Text fontSize={"20px"} fontWeight={'500'} pt={"10px"} color={"#000000"}>Health & Wellness Hub</Text>
                        <Image src={Image4} width={"20px"} height={"3px"} cursor={"pointer"}/>
                    </Box>
                    <Text fontSize={"16px"} fontWeight={"500"} color={"#000000"} textAlign={"justify"}>Health & Wellness Hub is a WhatsApp campaign dedicated to promoting a healthier lifestyle. Each message in this campaign is...</Text>
                    <Box w={"100%"} height={"auto"} border={"2px solid #F5F5F5"} p={"10px"}>
                        <Text display={"flex"} className={Style?.textBottomMargin}>
                            <Image src={Image5} width={"35px"} height={"35px"} borderRadius={"50%"}/>
                            <Text fontSize={"20px"} ml={"15px"} fontWeight={"600"} color={"#000000"}>Jaane Cooper</Text>
                        </Text>
                        <Text className={Style.textBottomMargin} fontSize={"16px"}  fontWeight={"500"} color={"#000000"} textAlign={"justify"}>23 Jul, 12.00 am to 1.00 pm</Text>
                    </Box>
                    <Box width={"100%"} h={"auto"} display={"flex"} justifyContent={"space-between"} mt={"15px"}>
                        <Box width={"59%"} h={"auto"}>
                            <Text className={Style?.textBottomMargin} pt={"7px"} pl={"15px"} height={"40px"}  border={"2px solid #F5F5F5"} fontSize={"16px"} fontWeight={"500"} color={"#000000"} w={"100%"}>Number of Leads: 2 </Text>
                            <Select placeholder='Status' mt={"7px"} width={"100%"}  height={"40px"} className={Style?.selectBoxBorder} border={"2px solid #F5F5F5"} fontSize={"16px"} fontWeight={"500"} color={"#000000"}>
                                <option value='option1'>Option 1</option>
                                <option value='option2'>Option 2</option>
                                <option value='option3'>Option 3</option>
                            </Select>                            
                            <Select placeholder='Type' mt={"7px"}  width={"100%"} height={"40px"} className={Style?.selectBoxBorder} border={"2px solid #F5F5F5"} fontSize={"16px"} fontWeight={"500"} color={"#000000"}>
                                <option value='option1'>Option 1</option>
                                <option value='option2'>Option 2</option>
                                <option value='option3'>Option 3</option>
                            </Select>                            
                        </Box>
                        <Box width={"39%"} border={"2px solid #F5F5F5"} height={"auto"}>
                            <Text className={Style?.textBottomMargin} p={"7px"} fontSize={"16px"} fontWeight={"500"} color={"#000000"} w={"100%"}>CTA Count</Text>
                            <Text className={Style?.textBottomMargin} p={"7px"} fontSize={"16px"} fontWeight={"500"} color={"#000000"} w={"100%"}>Total CTA: 2</Text>
                            <Text className={Style?.textBottomMargin} p={"7px"} fontSize={"16px"} fontWeight={"500"} color={"#000000"} w={"100%"}>CTA 1:[Signup]</Text>
                            <Text className={Style?.textBottomMargin} p={"7px"} fontSize={"16px"} fontWeight={"500"} color={"#000000"} w={"100%"}>CTA 2:[Learn more]</Text>
                        </Box>
                    </Box>
                    <Box width={"100%"} display={"flex"} justifyContent={"space-between"} mt={"20px"}>
                        <Radio value='A'>Option A</Radio>
                        <Radio value='B'>Option B</Radio>
                        <Radio value='Both'>Both</Radio>
                    </Box>
                </Box>
                {/* ***********************************************BOX CONTAINER*******************************************************************************                */}
                <Box  width={{base:"100%",sm:"100%",md:"100%",lg:"32%"}} mt={"10px"} height={"auto"} borderRadius={"6px"} boxShadow={"1px 2px 10px lightgray"} padding={"15px"} display={"flex"} flexDir={"column"} >
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                        <Image src={Image3} width={"20px"} height={"20px"}/>
                        <Text fontSize={"20px"} fontWeight={'500'} pt={"10px"} color={"#000000"}>Inbox Inspiration</Text>
                        <Image src={Image4} width={"20px"} height={"3px"} cursor={"pointer"}/>
                    </Box>
                    <Text fontSize={"16px"} fontWeight={"500"} color={"#000000"} textAlign={"justify"}>Inbox Inspiration is a weekly Email campaign designed to brighten your inbox with motivational quotes, success stories, and.....</Text>
                    <Box w={"100%"} height={"auto"} border={"2px solid #F5F5F5"} p={"10px"}>
                        <Text display={"flex"} className={Style?.textBottomMargin}>
                            <Image src={Image5} width={"35px"} height={"35px"} borderRadius={"50%"}/>
                            <Text fontSize={"20px"} ml={"15px"} fontWeight={"600"} color={"#000000"}>Esther Howard</Text>
                        </Text>
                        <Text className={Style.textBottomMargin} fontSize={"16px"}  fontWeight={"500"} color={"#000000"} textAlign={"justify"}>23 Jul, 12.00 am to 1.00 pm</Text>
                    </Box>
                    <Box width={"100%"} h={"auto"} display={"flex"} justifyContent={"space-between"} mt={"15px"}>
                        <Box width={"59%"} h={"auto"}>
                            <Text className={Style?.textBottomMargin} pt={"7px"} pl={"15px"} height={"40px"}  border={"2px solid #F5F5F5"} fontSize={"16px"} fontWeight={"500"} color={"#000000"} w={"100%"}>Number of Leads: 2 </Text>
                            <Select placeholder='Status' mt={"7px"} width={"100%"}  height={"40px"} className={Style?.selectBoxBorder} border={"2px solid #F5F5F5"} fontSize={"16px"} fontWeight={"500"} color={"#000000"}>
                                <option value='option1'>Option 1</option>
                                <option value='option2'>Option 2</option>
                                <option value='option3'>Option 3</option>
                            </Select>                            
                            <Select placeholder='Type' mt={"7px"}  width={"100%"} height={"40px"} className={Style?.selectBoxBorder} border={"2px solid #F5F5F5"} fontSize={"16px"} fontWeight={"500"} color={"#000000"}>
                                <option value='option1'>Option 1</option>
                                <option value='option2'>Option 2</option>
                                <option value='option3'>Option 3</option>
                            </Select>                            
                        </Box>
                        <Box width={"39%"} border={"2px solid #F5F5F5"} height={"auto"}>
                            <Text className={Style?.textBottomMargin} p={"7px"} fontSize={"16px"} fontWeight={"500"} color={"#000000"} w={"100%"}>CTA Count</Text>
                            <Text className={Style?.textBottomMargin} p={"7px"} fontSize={"16px"} fontWeight={"500"} color={"#000000"} w={"100%"}>Total CTA: 2</Text>
                            <Text className={Style?.textBottomMargin} p={"7px"} fontSize={"16px"} fontWeight={"500"} color={"#000000"} w={"100%"}>CTA 1:[Signup]</Text>
                            <Text className={Style?.textBottomMargin} p={"7px"} fontSize={"16px"} fontWeight={"500"} color={"#000000"} w={"100%"}>CTA 2:[Learn more]</Text>
                        </Box>
                    </Box>
                    <Box width={"100%"} display={"flex"} justifyContent={"space-between"} mt={"20px"}>
                        <Radio value='A'>Option A</Radio>
                        <Radio value='B'>Option B</Radio>
                        <Radio value='Both'>Both</Radio>
                    </Box>
                </Box>
                {/* ***********************************************BOX CONTAINER*******************************************************************************                */}
                <Box  width={{base:"100%",sm:"100%",md:"100%",lg:"32%"}} mt={"10px"} height={"auto"} borderRadius={"6px"} boxShadow={"1px 2px 10px lightgray"} padding={"15px"} display={"flex"} flexDir={"column"} >
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                        <Image src={Image2} width={"20px"} height={"20px"}/>
                        <Text fontSize={"20px"} fontWeight={'500'} pt={"10px"} color={"#000000"}>Exclusive Deals Direct</Text>
                        <Image src={Image4} width={"20px"} height={"3px"} cursor={"pointer"}/>
                    </Box>
                    <Text fontSize={"16px"} fontWeight={"500"} color={"#000000"} textAlign={"justify"}>Exclusive Deals Direct is a WhatsApp campaign tailored for savvy shoppers looking for the best deals and discounts...</Text>
                    <Box w={"100%"} height={"auto"} border={"2px solid #F5F5F5"} p={"10px"}>
                        <Text display={"flex"} className={Style?.textBottomMargin}>
                            <Image src={Image5} width={"35px"} height={"35px"} borderRadius={"50%"}/>
                            <Text fontSize={"20px"} ml={"15px"} fontWeight={"600"} color={"#000000"}>Karlos Kaireena</Text>
                        </Text>
                        <Text className={Style.textBottomMargin} fontSize={"16px"}  fontWeight={"500"} color={"#000000"} textAlign={"justify"}>23 Jul, 12.00 am to 1.00 pm</Text>
                    </Box>
                    <Box width={"100%"} h={"auto"} display={"flex"} justifyContent={"space-between"} mt={"15px"}>
                        <Box width={"59%"} h={"auto"}>
                            <Text className={Style?.textBottomMargin} pt={"7px"} pl={"15px"} height={"40px"}  border={"2px solid #F5F5F5"} fontSize={"16px"} fontWeight={"500"} color={"#000000"} w={"100%"}>Number of Leads: 2 </Text>
                            <Select placeholder='Status' mt={"7px"} width={"100%"}  height={"40px"} className={Style?.selectBoxBorder} border={"2px solid #F5F5F5"} fontSize={"16px"} fontWeight={"500"} color={"#000000"}>
                                <option value='option1'>Option 1</option>
                                <option value='option2'>Option 2</option>
                                <option value='option3'>Option 3</option>
                            </Select>                            
                            <Select placeholder='Type' mt={"7px"}  width={"100%"} height={"40px"} className={Style?.selectBoxBorder} border={"2px solid #F5F5F5"} fontSize={"16px"} fontWeight={"500"} color={"#000000"}>
                                <option value='option1'>Option 1</option>
                                <option value='option2'>Option 2</option>
                                <option value='option3'>Option 3</option>
                            </Select>                            
                        </Box>
                        <Box width={"39%"} border={"2px solid #F5F5F5"} height={"auto"}>
                            <Text className={Style?.textBottomMargin} p={"7px"} fontSize={"16px"} fontWeight={"500"} color={"#000000"} w={"100%"}>CTA Count</Text>
                            <Text className={Style?.textBottomMargin} p={"7px"} fontSize={"16px"} fontWeight={"500"} color={"#000000"} w={"100%"}>Total CTA: 2</Text>
                            <Text className={Style?.textBottomMargin} p={"7px"} fontSize={"16px"} fontWeight={"500"} color={"#000000"} w={"100%"}>CTA 1:[Signup]</Text>
                            <Text className={Style?.textBottomMargin} p={"7px"} fontSize={"16px"} fontWeight={"500"} color={"#000000"} w={"100%"}>CTA 2:[Learn more]</Text>
                        </Box>
                    </Box>
                    <Box width={"100%"} display={"flex"} justifyContent={"space-between"} mt={"20px"}>
                        <Radio value='A'>Option A</Radio>
                        <Radio value='B'>Option B</Radio>
                        <Radio value='Both'>Both</Radio>
                    </Box>
                </Box>
            </Box>
        </Box>
        <Footer/>
    </>
  )
}
