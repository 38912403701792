import React, { useState } from 'react';
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from 'react-icons/fa';
import { useSpring, animated } from 'react-spring';
import './RaiseTicket.css';
import Header from '../Header/Header';
import Footer from '../Footer/Footer'

const RaiseTicket = () => {
  const [formData, setFormData] = useState({ name: '', email: '', issue: '' });
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    // Handle form submission logic here
  };

  const socialMediaStyle = useSpring({
    transform: submitted ? 'translateY(0)' : 'translateY(-100px)',
    opacity: submitted ? 1 : 0,
  });

  return (
    <>
    <div className="raise-ticket-container">
      <h1>Raise a Ticket</h1>
      {!submitted ? (
        <form onSubmit={handleSubmit} className="ticket-form">
          <label>
            Name: *
            <input type="text" name="name" value={formData.name} onChange={handleChange} required />
          </label>
          <label>
            Email: *
            <input type="email" name="email" value={formData.email} onChange={handleChange} required />
          </label>
          <label>
            Task Description: *
            <textarea name="issue" value={formData.issue} onChange={handleChange} required />
          </label>
          <button type="submit">Submit</button>
        </form>
      ) : (
        <div className="thank-you-message">
          <h2>Thank you for submitting the ticket!</h2>
        </div>
      )}

      {submitted && (
        <animated.div style={socialMediaStyle} className="social-media-share">
          <h3>Share on Social Media</h3>
          <div className="icons">
            <FaFacebook />
            <FaTwitter />
            <FaLinkedin />
            <FaInstagram />
          </div>
        </animated.div>
      )}
    </div>
    <Footer/>
    </>
  );
};

export default RaiseTicket;
