import React from 'react';
import Styles from './Sidebar.module.css'
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Text, Image, Flex, Divider, useBreakpointValue } from '@chakra-ui/react';
import Img1 from './Sidebar-Icons/home-2.png';
import Img2 from './Sidebar-Icons/clipboard-tick.png';
import Img3 from './Sidebar-Icons/health.png';
import Img4 from './Sidebar-Icons/profile.png';
import Img5 from './Sidebar-Icons/notification-bing.png';
import Img6 from './Sidebar-Icons/setting-2.png';
import Img7 from './Sidebar-Icons/message-question.png';
import Img8 from './Sidebar-Icons/export.png';
import Img9 from './Sidebar-Icons/wallet.png';
import Img10 from './Sidebar-Icons/gift.png';
import Img11 from './Sidebar-Icons/logout.png';
import { GoHome } from "react-icons/go";
import { RxDashboard } from "react-icons/rx";
import { MdOutlineCampaign } from "react-icons/md";
import { CgBox } from "react-icons/cg";
import { GrGroup } from "react-icons/gr";
import { BsGraphUpArrow } from "react-icons/bs";
import { BsChatSquare } from "react-icons/bs";
import { IoSettingsOutline } from "react-icons/io5";
import { CiSquareQuestion } from "react-icons/ci";
import { FcDownload } from "react-icons/fc";
import { TbBasketDollar } from "react-icons/tb";
import { IoGiftSharp } from "react-icons/io5";
import { IoMdLogOut } from "react-icons/io";



const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isDesktop = useBreakpointValue({ base: false, md: true });

  const handleLogout = () => {
    localStorage.removeItem('userToken');
    navigate('/signin');
  };

  const getActiveStyle = (path) => {
    return location.pathname === path
      ? { backgroundColor: '#F5F5F5', borderLeft: '4px solid red', fontWeight: '500' }
      : {};
  };

  return (
    <Box
      position="fixed"
      top="70"
      left="0"
      width={{ base: "7%", md: "12%" }}
      height="90vh"
      bg="white"
      boxShadow="1px 0px 10px lightgray"
      padding={{ base: "5px", md: "20px" }}
      overflowY="auto"
      zIndex="1000"
      className={Styles?.scrollbar}
    >
      {/* <Text mb="20px" fontWeight="bold" display={{ base: "none", md: "block" }}>
        Menu
      </Text> */}
        <Text className={Styles?.textBottomMargin} mt="5px" fontWeight="500" display={{ base: "none", md: "block" }}>
          Menu
        </Text>
      <Flex direction="column" as="nav">
        {menuItems.map(({ path, icon, label }) => (
          <Flex
            key={label}
            style={getActiveStyle(path)}
            p="10px"
            cursor="pointer"
            borderRadius="10px"
            alignItems="center"
            onClick={() => navigate(path)}
            _hover={{ backgroundColor: "#F5F5F5" }}
            mt={"3px"}
          >
            {/* <Image src={icon}></Image> */}
            <Box mr={"12px"}>{icon}</Box>
            <Text className={Styles.textBottomMargin} display={{ base: "none", md: "block" }} fontSize="16px">{label}</Text>
          </Flex>
        ))}
        <Text className={Styles?.textBottomMargin} mt="5px" fontWeight="500" display={{ base: "none", md: "block" }}>
          Groups
        </Text>
        {groupItems.map(({ path, icon, label }) => (
          <Flex
            key={label}
            style={getActiveStyle(path)}
            p="8px"
            cursor="pointer"
            borderRadius="10px"
            alignItems="center"
            onClick={() => navigate(path)}
            _hover={{ backgroundColor: "#F5F5F5" }}
            mt="3px"
          >
            <Box mr={"12px"}>{icon}</Box>
            <Text className={Styles.textBottomMargin}  display={{ base: "none", md: "block" }} fontSize="14px">{label}</Text>
          </Flex>
        ))}
        <Divider mt="2px" />
        <Flex
          mt="2px"
          p="8px"
          cursor="pointer"
          borderRadius="10px"
          alignItems="center"
          onClick={handleLogout}
          _hover={{ backgroundColor: "#F5F5F5" }}
        >
          <Box mr={"12px"}><IoMdLogOut size={20} color='red'/></Box>
          <Text className={Styles.textBottomMargin} display={{ base: "none", md: "block" }} fontSize="14px">Logout</Text>
        </Flex>
      </Flex>
    </Box>
  );
};

const menuItems = [
  { path: '/', icon: <GoHome size={20}/>, label: 'Home' },
  { path: '/dashboard', icon: <RxDashboard size={18}/>    , label: 'Dashboard' },
  { path: '/AllCampaigns', icon: <CgBox size={18}/>    , label: 'Campaign' },
  { path: '/leads', icon:<GrGroup size={18}/>, label: 'Leads' },
  { path: '/EmailAnalytics', icon: <BsGraphUpArrow size={18} />, label: 'Analytics' },
  { path: '/Communitygroup', icon: <BsChatSquare size={18}/>, label: 'Messages' },
  { path: '/SettingsPage', icon: <IoSettingsOutline size={20}/>, label: 'Settings' },
  { path: '/support', icon:<CiSquareQuestion size={22} />, label: 'Support' },
];

const groupItems = [
  { path: '/#', icon: <FcDownload size={18}/>, label: 'Downloads' },
  { path: '/#', icon: <TbBasketDollar size={20} color='#2E8B57'/>, label: 'Billings' },
  { path: '/#', icon: <IoGiftSharp size={20} color='#750080 '/>, label: 'Gifts' },
];

export default Sidebar;
